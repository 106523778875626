// @flow
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import styles from '../../../assets/jss/sar/completedStyle'

const useStyles = makeStyles(styles)

type Props = {
  id: number,
  openPhotoGallery: func,
  thumbnail: string,
}

export const Photo = (props: Props) => {
  const classes = useStyles()

  const { thumbnail, id, openPhotoGallery } = props

  return (
    <Grid item md={4} sm={6} xs={12} className={`${classes.imgBox}`}>
      <div className={`${classes.imgCrop} ${classes.imgRounded}`}>
        <a href={ev => ev.preventDefault()} onClick={() => openPhotoGallery(id)}>
          {/* <a href={url} data-attribute="SRL"> */}
          <img
            src={thumbnail} // zde pouze nahled
            alt="..."
            className={`${classes.imgRaised} ${classes.imgFluid} ${classes.imgZoom} `}
          />
        </a>
      </div>
    </Grid>
  )
}
