/* eslint-disable react/jsx-props-no-spreading */
// @flow
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { messages, intl } from '../../i18n'
import message from '../../i18n/messages'
import styles from '../../assets/jss/sar/completedStyle'

import Footer from '../../components/Footer/Footer'
import { SarHeaderImage } from '../../components/SarHeaderImage'

const useStyles = makeStyles(styles)

export const Contacts = (props: Props) => {
  const classes = useStyles()

  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <Grid container justify="center">
          <Grid item md={8} xs={11}>
            <Grid item md={12} className={classes.center}>
              <h1>
                <FormattedMessage {...message.contactsPage} />
              </h1>
              <img alt="klokan" src={`${process.env.PUBLIC_URL}/imgs/klokanBlack.png`} />
            </Grid>

            <Grid item md={12} className={classes.center}>
              <h2>
                <FormattedMessage {...message.sar} />
              </h2>
              {/* <img alt="klokan" src="http://studioar.cz/images/icons/klokanWhite.png" /> */}
              {/* <img alt="klokan" src={require('assets/imgs/klokanBlack.png')} /> */}
              <p>
                <h3>
                  <FormattedMessage {...message.vaclavMachac} />
                </h3>
                <br />
                <b>
                  <FormattedMessage {...message.address} />:
                </b>{' '}
                <FormattedMessage {...message.cernilov} />
                <br />
                <b>
                  <FormattedMessage {...message.phone} />:
                </b>{' '}
                <FormattedMessage {...message.phone1} />
                <br /> <FormattedMessage {...message.phone2} />
                <br />
                <br />
                <u>
                  <strong>
                    <a href={`mailto:${intl.formatMessage(messages.mail)}`}>
                      <span>
                        <FormattedMessage {...message.mail} />
                      </span>
                    </a>
                  </strong>
                </u>
                <br />
                <u>
                  <strong>
                    <a href={`mailto:${intl.formatMessage(messages.mailBK)}`}>
                      <span>
                        <FormattedMessage {...message.mailBK} />
                      </span>
                    </a>
                  </strong>
                </u>
                <br />
                <br />
                <a href={`http://${intl.formatMessage(messages.www)}`}>
                  <span>
                    <u>
                      <strong>
                        <FormattedMessage {...message.www} />
                      </strong>
                    </u>
                  </span>
                  &nbsp;
                </a>
                <br />
                <u>
                  <a href={`http://${intl.formatMessage(messages.wwwBK)}`}>
                    <span>
                      <strong>
                        <FormattedMessage {...message.wwwBK} />
                      </strong>
                      &nbsp;
                    </span>
                  </a>
                </u>
              </p>
              <br />
              <br />
              <br />
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </div>
  )
}
