// @flow
import React from 'react'
import { HashLink } from 'react-router-hash-link'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import styles from '../../assets/jss/sar/completedStyle'

import Footer from '../../components/Footer/Footer'
import { SarHeaderImage } from '../../components/SarHeaderImage'

const useStyles = makeStyles(styles)

export const FaQBike = (props: Props) => {
  const classes = useStyles()

  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <Grid container justify="center">
          <Grid item md={8} xs={11}>
            <Grid item md={12} className={classes.center}>
              <h1>Nejčastější dotazy</h1>
              <img alt="klokan" src={`${process.env.PUBLIC_URL}/imgs/klokanBlack.png`} />
              <h2>BIKE</h2>
            </Grid>

            <Grid item md={12}>
              <p>
                <HashLink to="#napln">1. Jaká je náplň tématu BIKE?</HashLink>
                <br />
                <HashLink to="#kolo">2. Mám mít vlastní kolo? A jaké?</HashLink>
                <br />
                <HashLink to="#vybava">3. Jakou mám mít cyklistickou výbavu?</HashLink>
                <br />
                <HashLink to="#dohled">
                  4. Jak je zajištěn dohled nad dětmi? Kdo jim pomůže v případě technických
                  problémů?
                </HashLink>
                <br /> <span id="napln"> </span>
                <HashLink to="#prilba">
                  5. Musí mít děti přilbu, případně další ochranné pomůcky?
                </HashLink>
                <br />
                <HashLink to="#trasy">6. Po jakých trasách se jezdí?</HashLink>
                <br />
                <HashLink to="#skupiny">7. Jak velké jsou cyklistické skupiny?</HashLink>
                <br />
                <HashLink to="#vek">8. Od kolika let se můžou děti hlásit na téma BIKE?</HashLink>
                <br />
              </p>

              <h3>1. Jaká je náplň tématu BIKE?</h3>
              <span id="kolo"> </span>

              <p>
                <strong>Základem programu BIKE je:</strong>
                <br />
                <strong>1.</strong>&nbsp;nácvik cyklistických dovedností - trénink rovnováhy,
                překonávání překážek, ...na i přes překážku, ...dolů, jízda po zadním (předním)
                kole, padání atd.
                <br />
                <strong>2.</strong>&nbsp;technické dovednosti - výměna duše, seřízení brzd atd.
                <br />
                <strong>3.</strong>&nbsp;soutěže
                <br />
                <strong>3.</strong>&nbsp;jízda po kratších trasách
                <br />
                &quot;Bikerskou&quot; část tvoří pouze část táborového programu. Ve zbývajícím čase
                se &quot;bikeři&quot; zapojují do programu dalších skupin a běžného táborového
                života.
              </p>

              <h3>2. Mám mít vlastní kolo? A jaké?</h3>
              <span id="vybava"> </span>

              <p>
                Ano, každý účastník programu BIKE musí mít vlastní horské nebo trekové kolo.
                <br />
                <strong>Pozn.:</strong> Kolo musí být seřízené, vybavené a bez závad.
                <br />
              </p>

              <h3>3. Jakou mám mít cyklistickou výbavu?</h3>
              <span id="dohled"> </span>

              <p>
                Základní vybavení kola řeší &quot;příloha č.13 k vyhlášce č.341/2002 Sb.&quot; Mezi
                další vybavení, které by měl mít účastník programu BIKE patří: ochranné pomůcky (viz
                otázka Přilba a ochranné pomůcky), náhradní duši, lepení na duše, zámek, láhev na
                pití, batůžek na záda, pohotovostní sadu nářadí, hustilku.
                <br />
                <strong>Pozn.:</strong>&nbsp;
                <a href="http://studioar.cz/dokumenty/files/Vybaveni%20kola.pdf">
                  Zde najdete přílohu č.13 k vyhlášce č.341/2002 Sb.
                </a>
                <br />
              </p>

              <h3>
                4. Jak je zajištěn dohled nad dětmi? Kdo jim pomůže v případě technických problémů?
              </h3>
              <span id="prilba"> </span>

              <p>
                Dohled nad dětmi vykonává zkušený &quot;cyklistický&quot; vedoucí a
                &quot;cyklistický&quot; praktikant, se kterými děti absolvují jednotlivé části
                programu (viz otázka BIKE - náplň). Technické problémy (spadlý řetěz, píchlá
                pneumatika...) se řeší společně s vedoucím a jsou součástí nácviku technických
                dovedností.
                <br />
                <strong>Pozn.:</strong>&nbsp;Technické závady jsou běžnou součástí cyklistiky.
                Skupina &quot;bikerů&quot; tvoří team, který bere &quot;technické závady&quot; s
                úsměvem a pomáhá při jejich odstraňování.
              </p>

              <h3>5. Musí mít děti přilbu, případně další ochranné pomůcky?</h3>
              <span id="trasy"> </span>

              <p>
                Ano, děti musí mít přilbu. Dále doporučujeme rukavice a brýle. Rukavice chrání ruce
                při pádech během nácviku bikerských dovedností. Brýle potom před hmyzem a prachem,
                který může být za jízdy, pokud se octne v očích, nebezpečným elementem. Dalšími
                pomůckami mohou být chrániče kolen a loktů (většinou se používají chrániče určené
                pro skateboarding a in-line bruslení).
                <br />
                <strong>Pozn.:</strong>&nbsp;Přilba musí mít odpovídající velikost a tvar. Musí
                &quot;sedět&quot;.
              </p>

              <h3>6. Po jakých trasách se jezdí?</h3>
              <span id="skupiny"> </span>

              <p>
                Trasy a prostor pro nácvik &quot;bikerských&quot; dovedností jsou vybírány s ohledem
                na bezpečnost účastníků. Vzhledem k tomu, že základní filozofií je cyklistika v
                přírodě, pohybujeme se na značených trasách, lesních a polních cestách a většinou se
                vyhýbáme jízdě po silnicích a jiných frekventovanějších komunikacích.
              </p>

              <h3>7. Jak velké jsou cyklistické skupiny?</h3>
              <span id="vek"> </span>

              <p>
                Ve většině případů je cyklistická skupina tvořena max. 15 dětmi s doprovodem 2
                instruktorů. Velikost skupiny je při konkrétních činnostech ovlivněna trasou a
                prostorem, ve kterém se činnost odvíjí, s ohledem na bezpečnost účastníků.
              </p>

              <h3>8. Od kolika let se můžou děti hlásit na téma BIKE?</h3>

              <p>
                Vzhledem k somatickému a motorickému vývoji dítěte je nejnižší věková hranice
                stanovena na 10 let.
              </p>
              <br />
              <br />
              <br />
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </div>
  )
}
