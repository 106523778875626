// @flow
import { createAction } from 'redux-actions'
import { copyKeysToValues } from '../../utils/object'

import Endpoints from '../../constants/endpoints'

export const participantActionTypes = {
  DATA_FETCH: '',
  DATA_SEND: '',
  FETCH_PARTICIPANTS_SUCCESS: '',
  SET_PARTICIPANTS_LOADING_STATUS: '',
  UPDATE_PARTICIPANT_SUCCESS: '',
  UPDATE_PARTICIPANT_LOADING_STATUS: '',
}
copyKeysToValues(participantActionTypes)

type ActionTypesT = Keys<typeof participantActionTypes>

export const fetchParticipants = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  participantActionTypes.DATA_FETCH,
  null,
  () => ({
    url: Endpoints.getParticipants(),
    actionSuccess: fetchParticipantsSuccess,
    actionSetLoadingStatus: setLoadingStatusParticipants,
  })
)

export const fetchParticipantsSuccess = createAction<ActionTypesT, empty>(
  participantActionTypes.FETCH_PARTICIPANTS_SUCCESS
)
export const setLoadingStatusParticipants = createAction<ActionTypesT, empty, LoadingStatusT>(
  participantActionTypes.SET_PARTICIPANTS_LOADING_STATUS
)

export const updateParticipant = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  participantActionTypes.DATA_SEND,
  null,
  (id: string, data) => ({
    url: Endpoints.updateParticipant(id),
    actionSuccess: updateParticipantSuccess,
    actionSuccessMessage: 'Účastník aktualizován',
    actionSetLoadingStatus: setLoadingStatusUpdateParticipant,
    params: data,
  })
)

export const updateParticipantSuccess = createAction<ActionTypesT, empty>(
  participantActionTypes.UPDATE_PARTICIPANT_SUCCESS
)
export const setLoadingStatusUpdateParticipant = createAction<ActionTypesT, empty, LoadingStatusT>(
  participantActionTypes.UPDATE_PARTICIPANT_LOADING_STATUS
)
