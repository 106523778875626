// @flow
import { copyKeysToValues } from '../utils/object'

const forms = {
  registrationForm: '',
  participantsForm: '',
  newsForm: '',
  albumForm: '',
}

copyKeysToValues(forms)

export default forms
