// @flow
import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { ROUTES } from '../../../constants'
import styles from '../../../assets/jss/sar/completedStyle'

const useStyles = makeStyles(styles)

type Props = {
  image: string,
  id: number,
  title: string,
  subtitle: string,
}

function NewsItem(props: Props) {
  const { image, id, title, subtitle } = props

  const classes = useStyles()

  return (
    <Grid container className={classes.newsItem} justify="center">
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <img src={image} alt="icon" className={`${classes.imgRounded} ${classes.imgFluid}`} />
      </Grid>
      <Grid item xs={11} sm={7} md={8} lg={9} className={`${classes.margin10Left}`}>
        <h3>
          <Link to={ROUTES.NEWS_DETAIL + id}>
            <button type="button" className={classes.linkButton}>
              {title}
            </button>
          </Link>
        </h3>
        <div>
          <div className={`${classes.defaultFontStyle} ${classes.mutedText}`}>{subtitle}</div>
        </div>
      </Grid>
    </Grid>
  )
}

export default NewsItem
