// @flow
import { error } from 'react-toastify-redux'

import {
  cancel,
  fork,
  put,
  call,
  take,
  takeLatest,
  takeEvery,
  all,
  // select,
} from 'redux-saga/effects'

import { toastsMessages } from '../i18n'
import { setEnvironment } from '../store/config/actions'

import { Api } from './rest/index'
import { signInSaga, signOutSaga, refreshTokenSaga } from './auth/sagas'

// import {
//   // createRegistrationSaga,
//   // fetchActionsSaga,
//   fetchProgramsSaga,
//   // fetchPlacesSaga,
// } from './registration/sagas'

import { GeneralSagasActionTypes, fetchDataSaga, sendDataSaga } from './general'

// action type which cancels a running rootSaga in cancellableSaga
const CANCEL_ROOT_SAGA = 'CANCEL_ROOT_SAGA'

// business logic saga entry point
export function* rootSaga(): Generator<Object, void, Object> {
  // try to fetch `config.json` from server
  // const localConfig: ?JsonConfig = yield call(downloadConfigSaga)

  const localConfig: ?JsonConfig = {
    apiUrl: 'https://api.studioar.cz',
    // apiUrl: 'https://api-test.studioar.cz',
    apiUrlLocal: 'http://localhost',
    environment: 'development',
    timeout: 120000,
    tokenExpirationTolerance: 60000,
    automaticLogoutTimeout: 300,
    logoutModalConfirmationTimeout: 600,
    applicationListResetInterval: 30,
    allowSigningInPostCheckingPhase: true,
  }

  // if config exists -> initialize main application
  if (localConfig) {
    yield call(initializeApplication, localConfig)
  } else {
    // config not found -> show error notification and exit
    yield put(error(toastsMessages.noEnvConfig))
  }
}

function* initializeApplication(config: JsonConfig): Generator<Object, void, Object> {
  yield all([
    takeLatest('SIGN_IN', signInSaga),
    takeLatest('SIGN_OUT', signOutSaga),

    // lists sagas
    takeEvery(GeneralSagasActionTypes.DATA_FETCH, fetchDataSaga),
    takeEvery(GeneralSagasActionTypes.DATA_SEND, sendDataSaga),
  ])

  // run refresh token saga daemon
  yield fork(refreshTokenSaga)

  yield call(Api.createApi, config)

  // store json config in store
  yield put(setEnvironment({ config }))
}

// function* downloadConfigSaga(): Generator<Object, ?JsonConfig, Object> {
//   let result = null

//   try {
//     const response = yield call(downloadConfig)
//     result = response.data ? response.data : null
//   } catch (err) {
//     console.log('chyba')
//   }

//   return result
// }

// this saga is to be run by sagaMiddleware in order for HMR to work
// note that when saga HMR is enabled, changes in src/redux will also trigger the cancellation
export function* cancellableSaga(): Generator<Object, void, Object> {
  // start the root saga
  const task = yield fork(rootSaga)

  // cancelling mechanism
  yield take(CANCEL_ROOT_SAGA)
  yield cancel(task)
}

// default export, you should start and cancel the rootSaga via resulting sagaManager exclusively
// DON'T REMOVE DEFAULT EXPORT!
export default function createSagaManager(store: Object) {
  return {
    cancel: () => store.dispatch({ type: CANCEL_ROOT_SAGA }),
    start: () => store.sagaMiddleware.run(cancellableSaga),
  }
}
