// @flow
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import styles from '../../assets/jss/sar/completedStyle'
import { messages, intl } from '../../i18n'
import message from '../../i18n/messages'

import Footer from '../../components/Footer/Footer'
import { SarHeaderImage } from '../../components/SarHeaderImage'
import { SarTitle } from '../../components/SarTitle'

import { getRegistrationSuccessData } from '../../store/registration'

// const selector = formValueSelector("registrationForm");
const useStyles = makeStyles(styles)

const RegistrationSuccess = (props: Props) => {
  const classes = useStyles()

  const registrationSuccessData = useSelector(getRegistrationSuccessData)

  // const registrationSuccessData = {
  //   registrationType: 'Rezervace',
  //   akce: 'Ski & SNB \u0161kola 2021',
  //   program: 'Ly\u017ee',
  //   odjezd: 'Pardubice',
  //   prijezd: 'Hradec Kr\u00e1lov\u00e9',
  //   jmeno: 'Josef B\u00edna',
  //   prijmeni: 'B\u00cdNA',
  //   rodneCislo: 'ded',
  //   ulice: 'Trusnov, 73',
  //   cp: '53401',
  //   mesto: 'd',
  //   psc: '53401',
  //   email: 'mail@josefbina.cz',
  //   telefon1: '602648837',
  //   zastupceJmeno: 'JO B\u00e1',
  //   telefon2: '+420602648837',
  //   poznamka: 'text',
  //   souhlas: true,
  //   id: 18,
  // }

  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <Grid container justify="center">
          <Grid item md={8} xs={11}>
            <Grid container justify="center">
              <Grid item md={12} className={classes.center}>
                <h1>
                  <FormattedMessage {...message.registrationSended} />{' '}
                  {registrationSuccessData.registrationType === 'Rezervace'
                    ? intl.formatMessage(messages.registrationReservation)
                    : intl.formatMessage(messages.registrationApplication)}
                </h1>
                <h3>
                  <FormattedMessage {...message.registrationToAction} />{' '}
                  {registrationSuccessData.akce}
                </h3>
              </Grid>
              <Grid item md={4} xs={11}>
                <Grid container>
                  <SarTitle
                    title={intl.formatMessage(messages.registrationParticipant)}
                    type="h3"
                  />
                </Grid>
                <Grid container>
                  <div>
                    {registrationSuccessData.jmeno} {registrationSuccessData.prijmeni}
                    {' ('}
                    {registrationSuccessData.rodneCislo}
                    {')'}
                    <br />
                    {registrationSuccessData.ulice} {registrationSuccessData.cp} <br />
                    {registrationSuccessData.mesto} {registrationSuccessData.psc}
                  </div>
                </Grid>
              </Grid>

              <Grid item md={4} xs={11}>
                <Grid container>
                  <SarTitle
                    title={intl.formatMessage(messages.registrationRepresentative)}
                    type="h3"
                  />
                </Grid>
                <Grid container>
                  <div>
                    {registrationSuccessData.zastupceJmeno}
                    <br />
                    {registrationSuccessData.email}
                    <br />
                    {registrationSuccessData.telefon1}
                    <br />
                    {registrationSuccessData.telefon2}
                  </div>
                </Grid>
              </Grid>
              <Grid item md={4} xs={11}>
                <Grid container>
                  <SarTitle title={intl.formatMessage(messages.registrationTransport)} type="h3" />
                </Grid>
                <Grid container>
                  <div>
                    <strong>
                      <FormattedMessage {...message.registrationDeparture} />:{' '}
                    </strong>
                    {registrationSuccessData.odjezd} <br />
                    <strong>
                      <FormattedMessage {...message.registrationArrival} />:{' '}
                    </strong>
                    {registrationSuccessData.prijezd} <br />
                    <strong>
                      <FormattedMessage {...message.registrationProgram} />:{' '}
                    </strong>
                    {registrationSuccessData.program}
                  </div>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid container>
                  <SarTitle title={intl.formatMessage(messages.registrationInfo)} type="h3" />
                </Grid>
                <Grid container>
                  <div>{registrationSuccessData.poznamka} </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <br />
        {/* --------------------------- */}
      </div>
      <Footer />
    </div>
  )
}

export default RegistrationSuccess
