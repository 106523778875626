/* eslint-disable react/jsx-props-no-spreading */
// @flow
import * as React from 'react'

import { ROUTES } from '../../../constants'
import messages from '../../../i18n/messages'
import { Header } from '../../Header'

type Props = {
  children?: React.Node,
}

export default function AdminLayout(props: Props) {
  const { children } = props

  const menuLinks = [
    {
      id: 5,
      link: ROUTES.ADMIN_NEWS,
      message: { ...messages.adminNews },
      editIcon: true,
    },
    {
      id: 6,
      link: ROUTES.ADMIN_PARTICIPANTS,
      message: { ...messages.adminParticipants },
      editIcon: true,
    },
    {
      id: 7,
      link: ROUTES.ADMIN_PHOTOGALLERY,
      message: { ...messages.adminPhotogallery },
      editIcon: true,
    },
    {
      id: 8,
      link: ROUTES.ADMIN_ACTIONS,
      message: { ...messages.adminActions },
      editIcon: true,
    },
  ]

  return (
    <div>
      <Header
        fixed
        menuLinks={menuLinks}
        signingOut
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
      />
      {children}
    </div>
  )
}
