// @flow

const intersection = (all, b) => {
  //   return a.length > 0
  //     ? a.filter(value => {
  //         return b.some(element => element.id_action === value.id)
  //       })
  //     : []
  return all.filter(value => {
    return b.some(element => element.id_doprava === value.id)
  })
}
const minus = (all, b) => {
  return all.filter(value => {
    return !b.find(element => element.id_doprava === value.id)
  })
}

const programIntersection = (all, b) => {
  return all.filter(value => {
    return b.some(element => element.id_program === value.id)
  })
}
const programMinus = (all, b) => {
  return all.filter(value => {
    return !b.find(element => element.id_program === value.id)
  })
}

// const even = (element) => element === 3;

export const getActions = state => state.actions.actions

export const getActiveActions = (state: StateT) => getActions(state).filter(item => item.active)

export const getInactiveActions = (state: StateT) => getActions(state).filter(item => !item.active)

export const getTransports = state => state.actions.transports

export const getActiveTransports = (state: StateT) =>
  getTransports(state).filter(item => item.active)

export const getInactiveTransports = (state: StateT) =>
  getTransports(state).filter(item => !item.active)

export const getActionTransports = (state: StateT) => state.actions.actionTransports

export const getActiveActionTransports = (state: StateT) =>
  intersection(getActiveTransports(state), getActionTransports(state))

export const getInactiveActionTransports = (state: StateT) =>
  minus(getActiveTransports(state), getActionTransports(state))

export const getPrograms = state => state.actions.programs

export const getActivePrograms = (state: StateT) => getPrograms(state).filter(item => item.active)

export const getInactivePrograms = (state: StateT) =>
  getPrograms(state).filter(item => !item.active)

export const getActionPrograms = (state: StateT) => state.actions.actionPrograms

export const getActiveActionPrograms = (state: StateT) =>
  programIntersection(getActivePrograms(state), getActionPrograms(state))

export const getInactiveActionPrograms = (state: StateT) =>
  programMinus(getActivePrograms(state), getActionPrograms(state))
