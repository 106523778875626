// @flow
import { createActions } from 'redux-actions'
import { copyKeysToValues } from '../../utils/object'

export const ActionTypes = {
  SET_ENVIRONMENT: '',
}

copyKeysToValues(ActionTypes)

const actions = createActions(...(Object.keys(ActionTypes): Array<string>))

export const { setEnvironment } = actions
