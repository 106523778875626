// @flow
import { createHashHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
// $FlowFixMe
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import createSagaManager from './sagas'
import { configureStore } from './store'

/* eslint-disable */
// Import css directly (globally) without using CSS modules
import '!style-loader!css-loader!../node_modules/react-toastify/dist/ReactToastify.min.css'
import "assets/scss/material-kit-react.scss?v=1.8.0";

////////////////////////////////

const element = document.getElementById('root')
const [store, persistor] = configureStore()
export default createHashHistory()

createSagaManager(store).start();

if (element) {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={createHashHistory()}>
            <App />
        </Router>
      </PersistGate>
    </Provider>,
    element
  )
}

