import React from 'react'

import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'

import { makeStyles } from '@material-ui/core/styles'

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1)
}

function union(a, b) {
  return [...a, ...not(b, a)]
}
const useStyles = makeStyles(theme => ({
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    // width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
}))

export default function SarMultiList(props) {
  const { title, items, checkedList, handleSelect, setCheckedList } = props

  const classes = useStyles()

  const numberOfChecked = (items, checkedList) => intersection(checkedList, items).length

  const handleToggleAll = (items, checkedList, setCheckedList) => () => {
    if (numberOfChecked(checkedList, items) === items.length) {
      // setCheckedTransport(not(checkedTransport, items))
      setCheckedList(not(checkedList, items))
    } else {
      // setCheckedTransport(union(checkedTransport, items))
      setCheckedList(union(checkedList, items))
    }
  }

  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items, checkedList, setCheckedList)}
            checked={numberOfChecked(checkedList, items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(checkedList, items) !== items.length &&
              numberOfChecked(checkedList, items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(checkedList, items)}/${items.length} vybraných`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map(value => {
          const labelId = `transfer-list-all-item-${value.id}-label`

          return (
            <ListItem key={value.id} role="listitem" button onClick={handleSelect(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checkedList.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.value}`} />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Card>
  )
}
