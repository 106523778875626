// @flow
import { success, error } from 'react-toastify-redux'

import { call, put } from 'redux-saga/effects'

import { messages, intl } from '../../i18n'

import { loadingStatuses } from '../../constants'
import type { Response } from '../../utils'
import { getSaga, postSaga } from '../rest'
import type { FetchMetaDataT, GeneratorState } from '../types'

// export function* fetchDataSaga(action: { meta: FetchMetaDataT }): GeneratorState {
//   try {
//     if (action.meta.actionSetLoadingStatus) {
//       yield put(action.meta.actionSetLoadingStatus(loadingStatuses.FETCH_DATA_BEGIN))
//     }

//     const response: Response<*> = yield call(getSaga, action.meta.url)
//     yield put(action.meta.actionSuccess(response))

//     if (action.meta.actionSetLoadingStatus) {
//       yield put(action.meta.actionSetLoadingStatus(loadingStatuses.FETCH_DATA_SUCCESS))
//     }
//   } catch (error) {
//     if (action.meta.actionSetLoadingStatus) {
//       yield put(action.meta.actionSetLoadingStatus(loadingStatuses.FETCH_DATA_FAILURE))
//     }
//   }
// }

// export function* postDataSaga(action: { meta: FetchMetaDataT }): GeneratorState {
//   try {
//     if (action.meta.actionSetLoadingStatus) {
//       yield put(action.meta.actionSetLoadingStatus(loadingStatuses.FETCH_DATA_BEGIN))
//     }

//     const response: Response<*> = yield call(postSaga, action.meta.url, action.meta.data)
//     yield put(action.meta.actionSuccess(response))

//     if (action.meta.actionSetLoadingStatus) {
//       yield put(action.meta.actionSetLoadingStatus(loadingStatuses.FETCH_DATA_SUCCESS))
//     }
//   } catch (error) {
//     if (action.meta.actionSetLoadingStatus) {
//       yield put(action.meta.actionSetLoadingStatus(loadingStatuses.FETCH_DATA_FAILURE))
//     }
//   }
// }

export function* fetchDataSaga(action: { meta: FetchMetaDataT }): GeneratorState {
  yield call(callApiSaga, action, getSaga)
}

export function* sendDataSaga(action: { meta: FetchMetaDataT }): GeneratorState {
  // console.log(action)
  yield call(callApiSaga, action, postSaga)
}

export function* callApiSaga(action: { meta: FetchMetaDataT }, saga: () => void): GeneratorState {
  try {
    if (action.meta.actionSetLoadingStatus) {
      yield put(action.meta.actionSetLoadingStatus(loadingStatuses.FETCH_DATA_BEGIN))
    }

    const response: Response<*> = yield call(saga, action.meta.url, action.meta.params)

    // console.log(response)

    // pokud se v response vrátí chyba která není "no" tak ji vypiš toastem
    if (response.data.error && response.data.error !== 'no') {
      yield put(error(response.data.error))
    } else if (action.meta.actionSuccess) {
      yield put(action.meta.actionSuccess(response))

      // toast - pokud je v akci actionSuccessMessage zobrazi se success toast
      if (action.meta.actionSuccessMessage) {
        yield put(success(action.meta.actionSuccessMessage))
      }
    }

    if (action.meta.actionSetLoadingStatus) {
      yield put(action.meta.actionSetLoadingStatus(loadingStatuses.FETCH_DATA_SUCCESS))
    }
  } catch (err) {
    // console.log(err)

    if (err.errorFlag) {
      yield put(error(err.errorMsg))
    } else {
      // yield put(error('ERR'))
      // console.log(messages.serverError)
      // console.log(intl.formatMessage(messages.serverError))

      yield put(error(intl.formatMessage(messages.serverError)))
    }
    if (action.meta.actionSetLoadingStatus) {
      yield put(action.meta.actionSetLoadingStatus(loadingStatuses.FETCH_DATA_FAILURE))
    }
  }
}
