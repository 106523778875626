/* eslint-disable react/jsx-props-no-spreading */
// @flow
import React, { useEffect, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from 'react-data-table-component'
// import styled from 'styled-components'
import ReactExport from 'react-export-excel'
import { FormattedMessage } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import { Forms } from '../../constants'
import styles from '../../assets/jss/sar/completedStyle'
import { messages, intl } from '../../i18n'
import message from '../../i18n/messages'

import Footer from '../../components/Footer/Footer'
import { SarHeaderImage } from '../../components/SarHeaderImage'
import { SarSelectForm } from '../../components/SarSelectForm'
import { SarTextField } from '../../components/SarTextField'

import { fetchParticipants, updateParticipant } from '../../store/participants/actions'
import { getActions } from '../../store/registration/index'
import { getParticipantsList } from '../../store/participants'
import { getActionsList } from '../../store/registration/selectors'

const { ExcelFile } = ReactExport
const { ExcelSheet } = ReactExport.ExcelFile
const { ExcelColumn } = ReactExport.ExcelFile

const useStyles = makeStyles(styles)

type PropsParticipantDetail = {
  data: Object,
}

const ParticipantDetail = ({ data }: PropsParticipantDetail) => {
  const classes = useStyles()

  return (
    <div className={`${classes.participantDetail}`}>
      <Grid container justify="center">
        <Grid item md={4} xs={6}>
          <h4>
            <FormattedMessage {...message.registrationRepresentative} />
          </h4>
          {data.representative} <br />
          {data.mail} <br />
          <b>
            <FormattedMessage {...message.registrationRepresentativePhone1} />:{' '}
          </b>
          {data.phone} <br />
          <b>
            <FormattedMessage {...message.registrationRepresentativePhone2} />:{' '}
          </b>{' '}
          {data.phone2}
        </Grid>
        <Grid item md={4} xs={6}>
          <h4>
            <FormattedMessage {...message.registrationAddress} />
          </h4>
          {data.street} {data.CP} <br />
          {data.town} <br />
          {data.PSC} <br />
        </Grid>
        <Grid item md={4} xs={6}>
          <h4>
            <FormattedMessage {...message.otherInfo} />
          </h4>
          <b>
            <FormattedMessage {...message.registrationDeparture} />:{' '}
          </b>
          {data.departure} <br />
          <b>
            <FormattedMessage {...message.registrationArrival} />:{' '}
          </b>
          {data.arrival} <br />
          <b>
            <FormattedMessage {...message.registrationProgram} />:{' '}
          </b>
          {data.program} <br />
          <b>
            <FormattedMessage {...message.registrationInfo} />:{' '}
          </b>
          {data.note}
        </Grid>
      </Grid>
    </div>
  )
}

type Props = {
  // data: Object,
}

let AdminParticipants = (props: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const participantsList = useSelector(getParticipantsList)
  const actionsList = useSelector(getActionsList)

  const [participants, setParticipants] = useState(participantsList)
  const [openAction, setOpenAction] = useState(false)
  // const [modalStyle] = useState(getModalStyle)
  const [modifyParticipant, setModifyParticipant] = useState({ id: 0 })

  const [filterParticipants, setfilterParticipants] = useState({
    name: '',
    action: '0',
    type: '0',
  })

  const paginationOptions = {
    rowsPerPageText: intl.formatMessage(messages.rowsPerPage),
    rangeSeparatorText: intl.formatMessage(messages.separator),
    selectAllRowsItem: true,
    selectAllRowsItemText: intl.formatMessage(messages.all),
  }

  useEffect(() => {
    dispatch(fetchParticipants())
    dispatch(getActions())
  }, [dispatch])

  useEffect(() => {
    setParticipants(participantsList)
  }, [participantsList])

  useEffect(() => {
    participantsList && participantsList.length > 0
      ? setParticipants(
          participantsList.filter(
            item =>
              (item.surname.toLowerCase().includes(filterParticipants.name.toLowerCase()) ||
                filterParticipants.name === '') &&
              (item.action_id === filterParticipants.action || filterParticipants.action === '0') &&
              (item.type_id === filterParticipants.type || filterParticipants.type === '0')
          )
        )
      : setParticipants([])
  }, [filterParticipants, participantsList])

  const onFilterChange = val => {
    setfilterParticipants({
      ...filterParticipants,
      [val.target.name]: val.target.value,
    })
  }

  const handleCloseAction = () => {
    setOpenAction(false)
  }

  const handleButtonClick = row => {
    setOpenAction(true)
    setModifyParticipant(row)
  }

  const handleChangeParticipantType = event => {
    setModifyParticipant({ ...modifyParticipant, type_id: event.target.value })

    if (event.target.value === 'r') {
      setModifyParticipant({
        ...modifyParticipant,
        type: intl.formatMessage(messages.registrationReservation),
        type_id: event.target.value,
      })
    }
    if (event.target.value === 'p') {
      setModifyParticipant({
        ...modifyParticipant,
        type: intl.formatMessage(messages.registrationApplication),
        type_id: event.target.value,
      })
    }
    if (event.target.value === 's') {
      setModifyParticipant({
        ...modifyParticipant,
        type: intl.formatMessage(messages.registrationStorno),
        type_id: event.target.value,
      })
    }
  }

  const handleChangeParticipant = event => {
    setModifyParticipant({ ...modifyParticipant, [event.target.id]: event.target.value })
  }
  const updateModifiedParticipant = () => {
    dispatch(updateParticipant(modifyParticipant.id, modifyParticipant))
    setOpenAction(false)
    setParticipants(
      participantsList.map(p => (p.id === modifyParticipant.id ? modifyParticipant : p))
    )
  }

  const columns = [
    {
      name: intl.formatMessage(messages.registrationName),
      selector: 'name',
      sortable: true,
    },
    {
      name: intl.formatMessage(messages.registrationSurname),
      selector: 'surname',
      sortable: true,
    },
    {
      name: intl.formatMessage(messages.registrationBirthnumber),
      selector: 'RC',
      sortable: true,
    },
    {
      name: intl.formatMessage(messages.registrationAction),
      selector: 'action',
      sortable: true,
    },
    {
      name: intl.formatMessage(messages.registrationState),
      selector: 'type',
      sortable: true,
    },
    {
      cell: row => (
        <Button variant="contained" color="primary" onClick={() => handleButtonClick(row)}>
          <FormattedMessage {...message.edit} />
        </Button>
      ),
      button: true,
    },
  ]

  const typeList = [
    { id: 'r', value: intl.formatMessage(messages.registrationReservation) },
    { id: 'p', value: intl.formatMessage(messages.registrationApplication) },
    { id: 's', value: intl.formatMessage(messages.registrationStorno) },
  ]

  const handleRowClicked = row => {
    console.log(`${row.name} was clicked!`)
  }

  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <Grid container justify="center">
          <Grid item md={10} xs={11}>
            <Grid item md={12} className={classes.center}>
              <h1>
                <FormattedMessage {...message.adminParticipants} />
              </h1>
            </Grid>
            <Grid container justify="center" spacing={2} className={classes.inputStyle}>
              <Grid item md={4} xs={12}>
                <Field
                  name="action"
                  component={SarSelectForm}
                  onChangeCombo={onFilterChange}
                  type="text"
                  label={intl.formatMessage(messages.registrationAction)}
                  data={actionsList}
                  value={filterParticipants.action}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Field
                  name="name"
                  component={SarTextField}
                  onChange={onFilterChange}
                  type="text"
                  label={intl.formatMessage(messages.registrationSurname)}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Field
                  name="type"
                  component={SarSelectForm}
                  onChangeCombo={onFilterChange}
                  type="text"
                  label={intl.formatMessage(messages.registrationState)}
                  data={typeList}
                />
              </Grid>
            </Grid>

            <ExcelFile
              element={
                <Button>
                  <FormattedMessage {...message.downloadExcel} />
                </Button>
              }>
              {/* stylování excelu - příklad: https://github.com/rdcalle/react-export-excel/blob/HEAD/examples/styled_excel_sheet.md  */}
              <ExcelSheet data={participants} name={intl.formatMessage(messages.adminParticipants)}>
                <ExcelColumn label={intl.formatMessage(messages.registrationName)} value="name" />
                <ExcelColumn
                  label={intl.formatMessage(messages.registrationSurname)}
                  value="surname"
                />
                <ExcelColumn
                  label={intl.formatMessage(messages.registrationBirthnumber)}
                  value="RC"
                />
                <ExcelColumn
                  label={intl.formatMessage(messages.registrationStreet)}
                  value="street"
                />
                <ExcelColumn
                  label={intl.formatMessage(messages.registrationHouseNumber)}
                  value="CP"
                />
                <ExcelColumn label={intl.formatMessage(messages.registrationTown)} value="town" />
                <ExcelColumn
                  label={intl.formatMessage(messages.registrationPostCode)}
                  value="PSC"
                />
                <ExcelColumn
                  label={intl.formatMessage(messages.registrationRepresentative)}
                  value="representative"
                />
                <ExcelColumn
                  label={intl.formatMessage(messages.registrationRepresentativeEmail)}
                  value="mail"
                />
                <ExcelColumn
                  label={intl.formatMessage(messages.registrationRepresentativePhone1)}
                  value="phone"
                />
                <ExcelColumn
                  label={intl.formatMessage(messages.registrationRepresentativePhone2)}
                  value="phone2"
                />
                <ExcelColumn
                  label={intl.formatMessage(messages.registrationAction)}
                  value="action"
                />
                <ExcelColumn
                  label={intl.formatMessage(messages.registrationProgram)}
                  value="program"
                />
                <ExcelColumn
                  label={intl.formatMessage(messages.registrationDeparture)}
                  value="departure"
                />
                <ExcelColumn
                  label={intl.formatMessage(messages.registrationArrival)}
                  value="arrival"
                />
                <ExcelColumn label={intl.formatMessage(messages.registrationInfo)} value="note" />
                <ExcelColumn label={intl.formatMessage(messages.registrationState)} value="type" />
              </ExcelSheet>
            </ExcelFile>

            <Grid container>
              <DataTable
                columns={columns}
                pagination
                theme="solarized"
                data={participants}
                expandableRows
                highlightOnHover
                defaultSortField="id"
                defaultSortAsc={false}
                expandableRowsComponent={<ParticipantDetail />}
                paginationComponentOptions={paginationOptions}
                onRowClicked={handleRowClicked}
                noDataComponent={intl.formatMessage(messages.notFound)}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openAction}
        onClose={handleCloseAction}>
        <div className={`${classes.modalParticipant} ${classes.modalStyle}`}>
          <h3 id="simple-modal-title">
            <FormattedMessage {...message.registrationParticipant} /> (ID: {modifyParticipant.id})
          </h3>

          <Grid container justify="center">
            <Grid item md={4} xs={11}>
              <TextField
                id="name"
                value={modifyParticipant.name}
                label={intl.formatMessage(messages.registrationName)}
                onChange={handleChangeParticipant}
                className={classes.marginRight}
              />
            </Grid>
            <Grid item md={4} xs={11}>
              <TextField
                id="surname"
                value={modifyParticipant.surname}
                label={intl.formatMessage(messages.registrationSurname)}
                onChange={handleChangeParticipant}
                className={classes.marginRight}
              />
            </Grid>
            <Grid item md={4} xs={11}>
              <TextField
                id="RC"
                value={modifyParticipant.RC}
                label={intl.formatMessage(messages.registrationBirthnumber)}
                onChange={handleChangeParticipant}
              />
            </Grid>
          </Grid>

          <h3 id="simple-modal-title">
            {intl.formatMessage(messages.registrationAction)}: {modifyParticipant.action}
          </h3>

          <Grid container justify="center">
            <Grid item md={6} xs={11}>
              <b>
                <FormattedMessage {...message.registrationState} />:
              </b>{' '}
              {modifyParticipant.type}
              <div>
                <b>
                  <FormattedMessage {...message.registrationProgram} />:
                </b>{' '}
                {modifyParticipant.program}
              </div>
            </Grid>
            <Grid item md={6} xs={11}>
              <Field
                name="participantType"
                id="type_id"
                component={SarSelectForm}
                onChangeCombo={handleChangeParticipantType}
                type="text"
                label={intl.formatMessage(messages.registrationChangeState)}
                data={typeList}
                value={modifyParticipant.type_id}
              />
            </Grid>

            <Grid item md={6} xs={11}>
              <div>
                <b>
                  <FormattedMessage {...message.registrationDeparture} />:
                </b>{' '}
                {modifyParticipant.departure}
              </div>
            </Grid>

            <Grid item md={6} xs={11}>
              <div>
                <b>
                  <FormattedMessage {...message.registrationArrival} />:
                </b>{' '}
                {modifyParticipant.arrival}
              </div>
            </Grid>
          </Grid>
          <h3 id="simple-modal-title">
            <FormattedMessage {...message.registrationAddress} />
          </h3>
          <Grid container justify="center">
            <Grid item md={6} xs={11}>
              <TextField
                id="street"
                value={modifyParticipant.street}
                label={intl.formatMessage(messages.registrationStreet)}
                onChange={handleChangeParticipant}
              />
            </Grid>
            <Grid item md={6} xs={11}>
              <TextField
                id="CP"
                value={modifyParticipant.CP}
                label={intl.formatMessage(messages.registrationHouseNumber)}
                onChange={handleChangeParticipant}
              />
            </Grid>
            <Grid item md={6} xs={11}>
              <TextField
                id="town"
                value={modifyParticipant.town}
                label={intl.formatMessage(messages.registrationTown)}
                onChange={handleChangeParticipant}
              />
            </Grid>
            <Grid item md={6} xs={11}>
              <TextField
                id="PSC"
                value={modifyParticipant.PSC}
                label={intl.formatMessage(messages.registrationPostCode)}
                onChange={handleChangeParticipant}
              />
            </Grid>
          </Grid>
          <h3 id="simple-modal-title">
            <FormattedMessage {...message.registrationRepresentative} />
          </h3>
          <Grid container justify="center">
            <Grid item md={6} xs={11}>
              <TextField
                id="representative"
                value={modifyParticipant.representative}
                label={intl.formatMessage(messages.registrationRepresentative)}
                onChange={handleChangeParticipant}
              />
            </Grid>
            <Grid item md={6} xs={11}>
              <TextField
                id="mail"
                value={modifyParticipant.mail}
                label={intl.formatMessage(messages.registrationRepresentativeEmail)}
                onChange={handleChangeParticipant}
              />
            </Grid>
            <Grid item md={6} xs={11}>
              <TextField
                id="phone"
                value={modifyParticipant.phone}
                label={intl.formatMessage(messages.registrationRepresentativePhone1)}
                onChange={handleChangeParticipant}
              />
            </Grid>
            <Grid item md={6} xs={11}>
              <TextField
                id="phone2"
                value={modifyParticipant.phone2}
                label={intl.formatMessage(messages.registrationRepresentativePhone2)}
                onChange={handleChangeParticipant}
              />
            </Grid>
            <Grid item md={12} xs={11}>
              <TextField
                id="note"
                value={modifyParticipant.note}
                label={intl.formatMessage(messages.registrationInfo)}
                onChange={handleChangeParticipant}
              />
            </Grid>
          </Grid>

          <Button
            variant="outlined"
            onClick={() => updateModifiedParticipant()}
            className={classes.right}>
            <FormattedMessage {...message.save} />
          </Button>
        </div>
      </Modal>

      <Footer />
    </div>
  )
}

export default AdminParticipants = reduxForm({
  form: Forms.participantsForm, // a unique identifier for this form
  enableReinitialize: true,
})(AdminParticipants)
