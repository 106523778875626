/* eslint-disable react/jsx-props-no-spreading */
// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { HashLink } from 'react-router-hash-link'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { ROUTES } from '../../constants'
import styles from '../../assets/jss/sar/completedStyle'
import message from '../../i18n/messages'

import Footer from '../../components/Footer/Footer'
import { SarHeaderImage } from '../../components/SarHeaderImage'

const useStyles = makeStyles(styles)

export const FaQSki = (props: Props) => {
  const classes = useStyles()

  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <Grid container justify="center">
          <Grid item md={8} xs={11}>
            <Grid item md={12} className={classes.center}>
              <h1>Nejčastější dotazy</h1>
              <img alt="klokan" src={`${process.env.PUBLIC_URL}/imgs/klokanBlack.png`} />
              <h2>Lyžařská a SNB škola</h2>
            </Grid>

            <Grid item md={12}>
              <p>
                <HashLink to="#snih">
                  1. Jak postupujete při nepříznivých sněhových podmínkách?
                </HashLink>
                <br />
                <HashLink to="#dohled">
                  2. Jak je zajištěn dohled nad dětmi v době mezi výukou?
                </HashLink>
                <br />
                <HashLink to="#hole">3. Musí mít děti při lyžování hole?</HashLink>
                <br />
                <HashLink to="#vlek">4. Moje dítě má strach z jízdy na vleku. Jak na to?</HashLink>
                <br /> <span id="snih"> </span>
                <HashLink to="#prilba">
                  5. Musí mít děti při lyžování a snowboardění přilbu?
                </HashLink>
                <br />
                <HashLink to="#druzstva">
                  6. Jakým způsobem jsou děti rozdělovány do družstev?
                </HashLink>
                <br />{' '}
                <HashLink to="#zacit">7. Kdy je vhodné začít s lyžováním a snowboarděním?</HashLink>
                <br />
              </p>
              <h3>1. Jak postupujete při nepříznivých sněhových podmínkách?</h3>
              <span id="dohled"> </span>
              <p>
                Při nepříznivých sněhových podmínkách se řídíme tímto postupem: <br />
                a) změna termínu Lyžařské a Snowboardové školy
                <br />
                b) změna místa absolvování &quot;Lyžařské a Snowboardové školy&quot; za místo výše
                položené
                <br />
                c) kombinace těchto možností
                <br />
                <br />
                Každá změna je rodičům oznámena a v případě potřeby individuálně konzultována. Pokud
                by v extrémním případě byla Lyžařská a Snowboardová škola zrušena, byla by platba v
                plné výši vrácena (viz bod 4.3. všeobecných podmínek:{' '}
                <Link to={ROUTES.CONDITIONS}>
                  <FormattedMessage {...message.conditionsPage} />
                </Link>
                )
              </p>
              <h3>2. Jak je zajištěn dohled nad dětmi v době mezi výukou?</h3>
              <span id="hole"> </span>
              <p>
                Po celou dobu zájezdu zajišťují dohled nad dětmi lyžařští a snowboardoví
                instruktoři.
                <br />
                <br />
                <strong>Pozn.:</strong>&nbsp;K naší výuce lyžování a snowboardění patří také čas,
                který není věnován přímo výuce. Jsou to plánované pauzy určené pro regeneraci
                organizmu a občerstvení, oběd a následná relaxace, doprava do místa výuky a zpět. Po
                celou tuto dobu se naši instruktoři věnují dětem.
              </p>
              <span id="vlek"> </span>
              <h3>3. Musí mít děti při lyžování hole?</h3>
              <p>V úvodních lekcích výuky nejsou u začínajících lyžařů hole nutností.</p>
              <h3>4. Moje dítě má strach z jízdy na vleku. Jak na to?</h3>
              <span id="prilba"> </span>
              <p>
                Děti se učí jízdě na vleku v několika krocích. V prvních lekcích výuky, po zvládnutí
                základních lyžařských nebo snowboardových dovedností, začínají děti používat Baby
                vlek. Tedy vlek, který je založený na principu &quot;chytím se lana a nechám se
                táhnout&quot;. Při jízdě na Baby vleku děti většinou necítí strach, protože je
                umístěn na velmi mírném svahu a pro jeho opuštění se stačí kdykoli pustit lana. V
                určité fázi výuky je nutné zvládnout jízdu na běžném lyžařském vleku. Děti se
                nejprve naučí pomocí cvičné kotvy jak nastoupit a jak se nechat táhnout -
                &quot;motorem&quot; je instruktor. Poté začíná nácvik jízdy na lyžařském vleku.
                Obsluha vleku je vždy dopředu seznámena s tímto faktem a na samotném nácviku se
                podílí udělováním pokynů a pomocí při nastupování společně s instruktorem. Děti
                opouští vlek v prvním výstupovém místě, tedy po jízdě do mírného kopce.
                <br />
                <br />
                <strong>Pozn.:</strong>&nbsp;Již dopředu jsou děti upozorněny, že pád na vleku není
                ostuda a že pády k nácviku patří. Pokud k pádu dojde, obsluha vlek zastaví a dítě má
                dost času na opuštění prostoru vleku ať již vlastními silami nebo za pomoci
                instruktora.
              </p>
              <h3>5. Musí mít děti při lyžování a snowboardění přilbu?</h3>
              <span id="druzstva"> </span>
              <p>
                V současné době není zákonem daná povinnost užívat na sjezdovkách přilbu. Ale při
                přeplněnosti sjezdových tratí a způsobu jízdy většiny lyžařů a snowboardistů je
                používání přilby u dětí nutností. Nebezpečí nehrozí z vlastního pádu dítěte při
                jízdě, ale především ze srážky s dospělým lyžařem nebo snowboardistou. Proto
                používání přilby doporučujeme.
                <br />
                <br />
                <strong>Pozn.:</strong>&nbsp;Přilba by měla mít odpovídající velikost. Pod přilbu
                není vhodné používat čepici nebo čelenku. Při mrazivém počasí je možné použít tenkou
                lyžařskou kuklu.
              </p>
              <h3>6. Jakým způsobem jsou děti rozdělovány do družstev?</h3>
              <span id="zacit"> </span>
              <p>
                Děti jsou rozdělovány do družstev podle lyžařské nebo snowboardové výkonnosti. Každé
                družstvo vede jeden lyžařský nebo snowboardový instruktor, který provádí výuku.
                <br />
                <br />
                <strong>Pozn.:</strong>&nbsp;Při rychlejším rozvoji lyžařských nebo snowboardových
                dovedností jsou děti přeřazovány do &quot;výkonnějších&quot; družstev.
              </p>
              <h3>7. Kdy je vhodné začít s lyžováním a snowboarděním?</h3>
              <p>
                S lyžováním je možné začít ve věku 2-3 let. Jako nejnižší hranici pro snowboardění
                se uvádí věk 8 let.
                <br />
                <br />
                <strong>Vysvětlení:</strong>&nbsp; Jedním z nejzávažnějších motorických úkonů, které
                se dítě v raném věku naučí, je chůze. K jejímu zvládnutí dochází asi v jednom roce
                věku a představuje velice zásadní změnu - vzpřímený postoj, nezávislé přenášení
                hmotnosti z nohy na nohu. Můžeme to označit jako &quot;trvalý boj o rovnováhu&quot;.
                Ve věku 2-3 let není rozvoj rovnovážné jistoty přenášení hmotnosti z jedné nohy na
                druhou zdaleka dokončený. Z toho důvodu je pro malého začátečníka vhodnější nechat
                každou dolní končetinu samostatnou, svobodnou v pohybu. Z tohoto pohledu se jeví
                vhodnější začít u nejmenších adeptů s lyžováním. (podle J. Tremla)
              </p>
              <br />
              <br />
              <br />
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </div>
  )
}
