/* eslint-disable react/jsx-props-no-spreading */
// @flow
import React from 'react'
import { FormattedMessage } from 'react-intl'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'

type Props = {
  input: Object,
  label: string,
  meta: Object,
}

export default function SarCheckBox(props: Props) {
  const {
    input,
    label,
    meta: { touched, error, warning },
  } = props

  return (
    <FormControl required error={Boolean(error)} component="fieldset">
      <FormControlLabel
        control={
          <Checkbox
            checked={!!input.value}
            onChange={input.onChange}
            name="checkedB"
            color="primary"
          />
        }
        label={label}
      />
      {touched &&
        ((error && (
          <FormHelperText>
            <FormattedMessage {...error} />
          </FormHelperText>
        )) ||
          (warning && (
            <FormHelperText>
              <FormattedMessage {...warning} />
            </FormHelperText>
          )))}
    </FormControl>
  )
}
