// @flow

import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

// import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";
import styles from '../../assets/jss/material-kit-react/views/componentsSections/basicsStyle'
import styles2 from '../../assets/jss/sar/completedStyle'

const useStyles = makeStyles(styles)
const useStyles2 = makeStyles(styles2)

const marginLeft = {
  margin: '10px 0 0 0',
}

type Props = {
  type: string,
  title: string,
}

function SarTitle(props: Props) {
  const { type, title } = props

  const classes = useStyles()
  const classes2 = useStyles2()

  return (
    <div>
      {type === 'h1' && (
        <div className={classes2.container}>
          <Grid container justify="center">
            <Grid item md={12}>
              <div className={classes.title}>
                <h1>{title}</h1>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      {type === 'h2' && (
        <Grid container style={marginLeft}>
          <Grid item md={12}>
            <div className={classes.title}>
              <h2>{title}</h2>
            </div>
          </Grid>
        </Grid>
      )}
      {type === 'h3' && (
        <Grid container>
          <Grid item md={12}>
            <div className={classes.title}>
              <h3>{title}</h3>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default SarTitle
