// @flow
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import TextField from '@material-ui/core/TextField'

export default function SarMultiTextField(props: Props) {
  const {
    label,
    input,
    meta: { touched, invalid, error, warning },
    ...custom
  } = props

  return (
    <TextField
      fullWidth
      label={label}
      placeholder={label}
      multiline
      rowsMax="10"
      error={touched && invalid}
      // helperText={touched && error && <FormattedMessage {...error} />}
      helperText={
        touched &&
        ((error && <FormattedMessage {...error} />) ||
          (warning && <FormattedMessage {...warning} />))
      }
      // value={value}
      variant="outlined"
      {...input}
      {...custom}
    />
  )
}
