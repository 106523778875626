// @flow
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

// import styles from '../../assets/jss/material-kit-react/views/components'
import styles from '../../assets/jss/sar/completedStyle'

import { messages, intl } from '../../i18n'

import Footer from '../../components/Footer/Footer'
import { AdminNewsItem } from './AdminNewsItem'

import {
  updateVisibilityNews,
  fetchNews,
  getNewsList,
  // getNewsLoadingStatus,
  updatePriorityNews,
} from '../../store/news/index'

import { SarHeaderImage } from '../../components/SarHeaderImage'

const useStyles = makeStyles(styles)

// import { getBeautifyLoadingStatuses } from '../../utils'

export const AdminNews = () => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const activeNews = useSelector(getNewsList)
  // const newsLoadingStatus = useSelector(getNewsLoadingStatus)

  const [visibility, setVisibility] = useState(true)

  // const newsLoading = getBeautifyLoadingStatuses(newsLoadingStatus)

  const handleVisibility = (id, active) => {
    const index = activeNews.findIndex(element => element.id === id)
    activeNews[index].active = !active

    dispatch(updateVisibilityNews(id, !active))

    setVisibility(!visibility)
  }

  const handlePriority = (id, increase) => {
    dispatch(updatePriorityNews(id, increase))
  }

  useEffect(() => {
    dispatch(fetchNews())
  }, [dispatch])

  // if (!newsLoading.isSuccess || !newsLoading.isSuccess) {
  //   return null
  // }

  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <Grid container justify="center">
          <Grid item md={10}>
            <AdminNewsItem
              image="/imgs/new.gif"
              title={intl.formatMessage(messages.newNews)}
              subtitle=""
              detail=""
              id={0}
              key={0}
            />
            {activeNews.map(item => (
              <AdminNewsItem
                image={item.img}
                title={item.title}
                subtitle={item.subtitle}
                detail={item.detail}
                id={item.id}
                key={item.id}
                active={item.active}
                handleVisibility={() => handleVisibility(item.id, item.active)}
                increasePriority={() => handlePriority(item.id, true)}
                decreasePriority={() => handlePriority(item.id, false)}
              />
            ))}
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  )
}
