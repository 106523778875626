/*eslint-disable*/
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { List, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Favorite from '@material-ui/icons/Favorite'
import { ROUTES } from '../../constants'

import styles from '../../assets/jss/sar/completedStyle'
import messages from '../../i18n/messages'
import { intl } from '../../i18n'

const useStyles = makeStyles(styles)

export default function Footer() {
  const classes = useStyles()

  return (
    <footer className={classes.footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href={'mailto:' + intl.formatMessage(messages.mail)} className={classes.block}>
                <FormattedMessage {...messages.vaclavMachac} />
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link to={ROUTES.CONTACTS} className={classes.block}>
                <FormattedMessage {...messages.contactsPage} />
              </Link>
            </ListItem>

            {/* <ListItem className={classes.inlineBlock}>
              <Link to={ROUTES.ADMIN_NEWS} className={classes.block}>  
              <FormattedMessage {...messages.adminSection} />   
            </Link>
            </ListItem> */}
          </List>
        </div>
        <div className={classes.footerRight}>
          <ListItem className={classes.inlineBlock}>
            &copy; 2004 - {1900 + new Date().getYear()}{' '}
            <FormattedMessage {...messages.blueKangaroo} />
          </ListItem>
        </div>
      </div>
    </footer>
  )
}
