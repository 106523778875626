// @flow
import { createAction } from 'redux-actions'
import { copyKeysToValues } from '../../utils/object'
import type { LoadingStatusT } from '../types'
import type { FetchMetaDataT } from '../../sagas/types'

import Endpoints from '../../constants/endpoints'

export const imagesActionTypes = {
  DATA_FETCH: '',
  DATA_SEND: '',
  ADD_IMAGE_SUCCESS: '',
  SET_IMAGES_LOADING_STATUS: '',
  FETCH_IMAGES_SUCCESS: '',
  DELETE_IMAGE_SUCCESS: '',
}

copyKeysToValues(imagesActionTypes)

type ActionTypesT = Keys<typeof imagesActionTypes>

export const addImage = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  imagesActionTypes.DATA_SEND,
  null,
  file => ({
    url: Endpoints.addImage(),
    actionSuccess: addImageSuccess,
    actionSetLoadingStatus: setLoadingStatusImages,
    params: file,
  })
)

export const addImageSuccess = createAction<ActionTypesT, empty>(
  imagesActionTypes.ADD_IMAGE_SUCCESS
)

export const setLoadingStatusImages = createAction<ActionTypesT, empty, LoadingStatusT>(
  imagesActionTypes.SET_IMAGES_LOADING_STATUS
)
// ///////////////////////

export const fetchImages = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  imagesActionTypes.DATA_FETCH,
  null,
  () => ({
    url: Endpoints.getImages(), // `/SAR/news/getNEWS.php?id=${id}`,
    actionSuccess: fetchImagesSuccess,
    actionSetLoadingStatus: setLoadingStatusImages,
  })
)
export const fetchImagesSuccess = createAction<ActionTypesT, empty>(
  imagesActionTypes.FETCH_IMAGES_SUCCESS
)
/// ///////////////

export const deleteImage = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  imagesActionTypes.DATA_SEND,
  null,
  file => ({
    url: Endpoints.deleteImage(file),
    actionSuccess: () => deleteImageSuccess(file),
    actionSetLoadingStatus: setLoadingStatusImages,
  })
)

export const deleteImageSuccess = createAction<ActionTypesT, empty>(
  imagesActionTypes.DELETE_IMAGE_SUCCESS
)

/// //////////////////

// export const fetchNews = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
//   imagesActionTypes.DATA_FETCH,
//   null,
//   () => ({
//     url: Endpoints.newsData(), // '/SAR/news/getNEWS.php', // todo - doresit failure
//     actionSuccess: fetchNewsSuccess,
//     actionSetLoadingStatus: setLoadingStatusNews,
//     //   actionSuccess: setCodebooksIdentityDocuments,
//     // actionSetLoadingStatus: setLoadingStatusIdentityDocuments,
//   })
// )

// export const fetchNewsSuccess = createAction<ActionTypesT, empty>(
//   imagesActionTypes.FETCH_NEWS_SUCCESS
// )
// export const setLoadingStatusNews = createAction<ActionTypesT, empty, LoadingStatusT>(
//   imagesActionTypes.SET_NEWS_LOADING_STATUS
// )
