// @flow

export const CZECH_PHONE_CODE = '+420'
export const CZECH_PHONE_MASK = [
  '+',
  '4',
  '2',
  '0',
  ' ',
  /[1-9]/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
]

export const FOREIGN_PHONE_MASK = [
  '+',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /[1-9]/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
]

const CZECH_PHONE_REG_EXP = /^(\+420)[1-9][0-9]{8}$/
const FOREIGN_PHONE_REG_EXP = /^\+[0-9]{1,4}[\s\0-9]*$/

export const PhoneNumber = {
  validateCzechNumber: (phoneNumber: string): boolean => {
    const item = clearWhitespaces(phoneNumber)
    return CZECH_PHONE_REG_EXP.test(item)
  },

  validateForeignNumber: (phoneNumber: string): boolean => {
    const item = clearWhitespaces(phoneNumber)
    return FOREIGN_PHONE_REG_EXP.test(item)
  },
}

/**
 * Remove all whitespaces
 * @param phoneNumber
 * @returns {string}
 */
const clearWhitespaces = (phoneNumber: string): string => phoneNumber.replace(/\s/g, '')
