/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { error } from 'react-toastify-redux'

import * as firebase from 'firebase/app'

import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import Email from '@material-ui/icons/Email'
import Grid from '@material-ui/core/Grid'

import { Link } from 'react-router-dom'
import message from '../../i18n/messages'
import styles from '../../assets/jss/material-kit-react/views/componentsSections/loginStyle'

import CardHeader from '../../components/Card/CardHeader'
import CardBody from '../../components/Card/CardBody'
import CardFooter from '../../components/Card/CardFooter'
import Button from '../../components/CustomButtons/Button'
import CustomInput from '../../components/CustomInput/CustomInput'
import Card from '../../components/Card/Card'
// import { withFirebase } from '../../components/Firebase'

import { signInSuccess, fetchAdmins } from '../../store/authorization'

import { auth, provider } from '../../firebase'

import { ROUTES } from '../../constants'

const useStyles = makeStyles(styles)

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
}

const SignIn = function SignIn(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [email, setEmail] = useState(INITIAL_STATE.email)
  const [password, setPassword] = useState(INITIAL_STATE.password)

  useEffect(() => {
    dispatch(fetchAdmins())
  }, [dispatch])

  const onChangeEmail = event => {
    setEmail(event.target.value)
  }
  const onChangePassword = event => {
    setPassword(event.target.value)
  }

  const signInWithGoogle = () => {
    // const provider = new firebase.auth.GoogleAuthProvider()
    let dataUser
    auth.setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
      firebase
        .auth()
        .signInWithPopup(provider)
        // .then(result => {
        //   dispatch(signInSuccessGoogle(result))
        // })
        .then(data => {
          dataUser = data
          return data.user.getIdToken()
        })
        .then(token => {
          dispatch(
            signInSuccess({
              dataUser,
              token,
            })
          )
        })
        .catch(errorX => {
          console.error(errorX)
          dispatch(error('problem with google login'))
        })
    })
  }

  const onSubmit = event => {
    login(email, password)

    event.preventDefault()
  }

  async function login(email, password) {
    let dataUser

    await auth
      .signInWithEmailAndPassword(email, password)
      .then(data => {
        dataUser = data
        return data.user.getIdToken()
      })
      .then(token => {
        dispatch(
          signInSuccess({
            dataUser,
            token,
          })
        )
      })
      .catch(errorX => {
        console.error(errorX)
        dispatch(error('wrong credentials, please try again'))
      })
  }

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <form className={classes.form} onSubmit={onSubmit}>
                <CardHeader color="primary" className={classes.cardHeader}>
                  <h4>
                    <Link to={ROUTES.EMPTY}>
                      <i className="fas fa-arrow-left" />{' '}
                    </Link>
                    <FormattedMessage {...message.loginPage} />
                  </h4>

                  {/* <div className={classes.socialLine}>
                    <Button
                      justIcon
                      href="#"
                      target="_blank"
                      color="transparent"
                      onClick={e => e.preventDefault()}>
                      <i className={`${classes.socialIcons} fab fa-twitter`} />
                    </Button>
                    <Button
                      justIcon
                      href="#"
                      target="_blank"
                      color="transparent"
                      onClick={e => e.preventDefault()}>
                      <i className={`${classes.socialIcons} fab fa-facebook`} />
                    </Button>
                    <Button
                      justIcon
                      target="_blank"
                      color="transparent"
                      onClick={() => signInWithGoogle()}>
                      <i className={`${classes.socialIcons} fab fa-google-plus-g`} />
                    </Button>
                  </div> */}
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Zadej email..."
                    id="email"
                    onChange={onChangeEmail}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'email',
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <CustomInput
                    labelText="Password..."
                    id="pass"
                    onChange={onChangePassword}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputIconsColor}>lock_outline</Icon>
                        </InputAdornment>
                      ),
                      autoComplete: 'off',
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button
                    justIcon
                    target="_blank"
                    color="transparent"
                    onClick={() => signInWithGoogle()}>
                    {/* onClick={() => onGSubmit()}> */}
                    <i className={`${classes.socialIcons} fab fa-google-plus-g`} />
                  </Button>
                  <Button simple color="primary" size="lg" type="submit">
                    <FormattedMessage {...message.appLogin} />
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

// export default withFirebase(SignIn)
export default SignIn
