/* eslint-disable react/jsx-props-no-spreading */
// @flow
import React from 'react'
// import React, { useEffect, useState } from 'react'
import { IntlProvider, addLocaleData, FormattedMessage } from 'react-intl'

import en from 'react-intl/locale-data/en'
import cs from 'react-intl/locale-data/cs'
import { ToastContainer } from 'react-toastify-redux'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ROUTES } from './constants'
import { Main } from './containers/Main'

import { SignIn } from './containers/SignIn'
import { News } from './containers/News'
import { AdminNews } from './containers/AdminNews'
import { AdminNewsDetail } from './containers/AdminNewsDetail'
import { Photogallery } from './containers/Photogallery'

import { Photoalbum } from './containers/Photogallery/Photoalbum'

import { AdminPhotogallery } from './containers/AdminPhotogallery'

import { AdminPhotoalbum } from './containers/AdminPhotogallery/AdminPhotoalbum'

import { AdminParticipants } from './containers/AdminParticipants'

import { AdminActions } from './containers/AdminActions'

import { Contacts } from './containers/Contacts'

import { Conditions } from './containers/Conditions'

import { FaQSki } from './containers/FaQSki'
import { FaQBike } from './containers/FaQBike'
import { FaQCamp } from './containers/FaQCamp'

import { Registration } from './containers/Registration'
import { RegistrationSuccess } from './containers/RegistrationSuccess'

import { Layout } from './components/layout/Layout'
import { AdminLayout } from './components/layout/AdminLayout'

import * as messages from './i18n'

import message from './i18n/messages'

import { isSigned } from './store/authorization'

// import { withFirebase, withAuthentication } from './components/Firebase'

export const locale = 'cs'
addLocaleData([...en, ...cs])

// díky tomuto lze pouzit i mimo komponenty diky: intl.formatMessage(messages.XXX)
const intlProvider = new IntlProvider({ locale: 'cs', messages: messages.cs })
export const { intl } = intlProvider.getChildContext()

type Props = {
  location: object,
}

const App = (props: Props) => {
  const { location } = props

  const signed = useSelector(isSigned)

  // private routes (administratorske) - dostupne pouze po prihlaseni

  type PrivateRouteProps = {
    component: object,
    path: string,
  }

  const PrivateRoute = ({ component: Component, path: Path, ...rest }: PrivateRouteProps) => (
    <Route
      {...rest}
      render={props =>
        signed ? <Component {...props} path={Path} /> : <Redirect to={ROUTES.SIGNIN} />
      }
    />
  )

  // public routes

  const mainRoute = <Route exact path={ROUTES.EMPTY} component={Main} />

  const defaultRoute = <Route exact path={ROUTES.MAIN} component={Main} />

  const newsDetailRoute = <Route exact path={ROUTES.NEWS_DETAIL_ID} component={News} />

  const photogallery = <Route exact path={ROUTES.PHOTOGALLERY} component={Photogallery} />

  const photoalbum = <Route exact path={ROUTES.PHOTOALBUM_ID} component={Photoalbum} />

  const contacts = <Route exact path={ROUTES.CONTACTS} component={Contacts} />

  const conditions = <Route exact path={ROUTES.CONDITIONS} component={Conditions} />

  const faQSki = <Route exact path={ROUTES.FAQ_SKI} component={FaQSki} />

  const faQCamp = <Route exact path={ROUTES.FAQ_CAMP} component={FaQCamp} />

  const faQBike = <Route exact path={ROUTES.FAQ_BIKE} component={FaQBike} />

  const adminNewsRoute = <PrivateRoute path={ROUTES.ADMIN_NEWS} component={AdminNews} />

  const reservationRoute = (
    <Route
      path={ROUTES.RESERVATION}
      render={() => (
        <Registration
          header={<FormattedMessage {...message.reservation} />}
          // initialValues={{ registrationType: 'Prihlaska' }}
        />
      )}
    />
  )
  const reservationSuccessRoute = (
    <Route
      path={ROUTES.RESERVATION_SUCCESS}
      render={() => (
        <RegistrationSuccess
          header={<FormattedMessage {...message.reservation} />}
          // initialValues={{ registrationType: 'Prihlaska' }}
        />
      )}
    />
  )

  // private routes

  const adminNewsDetailRoute = (
    <PrivateRoute exact path={ROUTES.ADMIN_NEWS_DETAIL_ID} component={AdminNewsDetail} />
  )

  const adminPhotogallery = (
    <PrivateRoute exact path={ROUTES.ADMIN_PHOTOGALLERY} component={AdminPhotogallery} />
  )

  const adminPhotoalbum = (
    <PrivateRoute exact path={ROUTES.ADMIN_PHOTOALBUM_ID} component={AdminPhotoalbum} />
  )

  const adminParticipants = (
    <PrivateRoute exact path={ROUTES.ADMIN_PARTICIPANTS} component={AdminParticipants} />
  )

  const adminActions = <PrivateRoute exact path={ROUTES.ADMIN_ACTIONS} component={AdminActions} />

  const signInRoute = signed ? (
    <Redirect to={ROUTES.ADMIN_NEWS} />
  ) : (
    <Route path={ROUTES.SIGNIN} component={SignIn} />
  )

  // other routes
  const NotFoundRoute = (
    <>
      <h1>NOT FOUND</h1>
      <h3>Please create better looking Not Found page!!!</h3>
    </>
  )

  // Layout - pokud stránka na "admin" - použije se admin menu
  const getGeneralLayout = location => {
    if (location.pathname.indexOf('/admin') !== -1) {
      return AdminLayout
    }

    return Layout
  }

  const GeneralLayout = getGeneralLayout(location)

  return (
    <IntlProvider key={locale} locale={locale} messages={messages[locale]}>
      <div className="App">
        <GeneralLayout location={location}>
          <ToastContainer />

          <Switch location={location}>
            {adminNewsRoute}
            {adminNewsDetailRoute}
            {adminPhotogallery}
            {adminPhotoalbum}
            {adminParticipants}
            {adminActions}

            {mainRoute}
            {defaultRoute}
            {newsDetailRoute}
            {reservationRoute}
            {reservationSuccessRoute}
            {photogallery}
            {photoalbum}
            {contacts}
            {conditions}
            {faQSki}
            {faQBike}
            {faQCamp}

            {signInRoute}

            <Route component={() => NotFoundRoute} />
          </Switch>
        </GeneralLayout>
      </div>
    </IntlProvider>
  )
}

// export default withAuthentication(withRouter(App))
export default withRouter(App)
