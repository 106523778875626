/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'

import Select from '@material-ui/core/Select'
import { FormHelperText } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '100%',
  },
}))

const SarSelectForm = props => {
  const {
    meta: { touched, invalid, error },
    input,
    label,
    data,
    onChangeCombo,
  } = props
  const [value, setValue] = React.useState('')

  const handleChange = changeAction => event => {
    event.persist()

    setValue(event.target.value)
    if (!(changeAction === null || changeAction === undefined)) {
      changeAction(event)
    }
  }

  const classes = useStyles()

  return (
    <FormControl variant="filled" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>

      <Select
        {...input}
        error={touched && invalid}
        // onChange={value => handleChange(value)}
        onChange={handleChange(onChangeCombo)}
        value={value}
        // onBlur={() => input.onBlur(input.value)}
      >
        <MenuItem value="0" key={-1}>
          {' '}
        </MenuItem>

        {data.map(data => (
          <MenuItem value={data.id} key={data.id}>
            {data.value}
          </MenuItem>
        ))}
      </Select>
      {touched && error && (
        <FormHelperText error>
          <FormattedMessage {...error} />
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default SarSelectForm
