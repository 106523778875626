// @flow
import { createAction } from 'redux-actions'
import { copyKeysToValues } from '../../utils/object'

import Endpoints from '../../constants/endpoints'

export const photoActionTypes = {
  DATA_FETCH: '',
  DATA_SEND: '',
  FETCH_ALBUMS_SUCCESS: '',
  FETCH_ALBUMS_LOADING_STATUS: '',
  FETCH_ALBUM_SUCCESS: '',
  INSERT_ALBUM_SUCCESS: '',
  ALBUM_PHOTO_LOADED: '',
  UPDATE_ALBUM_SUCCESS: '',
  INSERT_PHOTO_SUCCESS: '',
  PHOTOS_LOADED: '',
  DELETE_LOADED_PHOTO: '',
  FETCH_PHOTOS_SUCCESS: '',
  FETCH_PHOTOS_LOADING_STATUS: '',
  UPDATE_PRIORITY_ALBUM_SUCCESS: '',
  SET_UPDATE_PRIORITY_ALBUM_LOADING_STATUS: '',
  DELETE_ALBUM_SUCCESS: '',
  START_PHOTO_INSERT: '',
  UNMOUNT_ALBUM: '',
}
copyKeysToValues(photoActionTypes)

type ActionTypesT = Keys<typeof photoActionTypes>

export const fetchAlbums = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  photoActionTypes.DATA_FETCH,
  null,
  () => ({
    url: Endpoints.getAlbums(),
    actionSuccess: fetchAlbumsSuccess,
    actionSetLoadingStatus: setLoadingStatusFetchAlbums,
  })
)

export const fetchAlbumsSuccess = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  photoActionTypes.FETCH_ALBUMS_SUCCESS
)
export const setLoadingStatusFetchAlbums = createAction<ActionTypesT, empty, LoadingStatusT>(
  photoActionTypes.FETCH_ALBUMS_LOADING_STATUS
)

/// ////////////////////////////////////

export const startPhotoInsert = createAction<ActionTypesT, empty, LoadingStatusT>(
  photoActionTypes.START_PHOTO_INSERT
)

/// ////////////////////////////////////

export const fetchAlbum = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  photoActionTypes.DATA_FETCH,
  null,
  (id: string) => ({
    url: Endpoints.getAlbum(id),
    actionSuccess: fetchAlbumSuccess,
    actionSetLoadingStatus: setLoadingStatusFetchAlbums,
  })
)

export const fetchAlbumSuccess = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  photoActionTypes.FETCH_ALBUM_SUCCESS
)

/// ////////////////////////////////////

export const fetchPhotos = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  photoActionTypes.DATA_FETCH,
  null,
  id => ({
    url: Endpoints.getPhotos(id),
    actionSuccess: fetchPhotosSuccess,
    actionSetLoadingStatus: setLoadingStatusFetchPhotos,
  })
)

export const fetchPhotosSuccess = createAction<ActionTypesT, empty>(
  photoActionTypes.FETCH_PHOTOS_SUCCESS
)

export const setLoadingStatusFetchPhotos = createAction<ActionTypesT, empty, LoadingStatusT>(
  photoActionTypes.FETCH_PHOTOS_LOADING_STATUS
)

/// ////////////////////////////////////

export const insertAlbum = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  photoActionTypes.DATA_SEND,
  null,
  (data, photo) => ({
    url: Endpoints.insertAlbum(data.title, data.active),
    actionSuccess: insertAlbumSuccess,
    actionSetLoadingStatus: setLoadingStatusFetchAlbums,
    actionSuccessMessage: 'Album uloženo',
    // params: data,
    // params: { ...data, ...photo },
    params: photo,
  })
)

export const insertAlbumSuccess = createAction<ActionTypesT, empty>(
  photoActionTypes.INSERT_ALBUM_SUCCESS
)

/// ////////////////////////////////////

export const insertPhoto = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  photoActionTypes.DATA_SEND,
  null,
  (albumId, photo, fileName) => ({
    url: Endpoints.insertPhoto(albumId),
    actionSuccess: () => insertPhotoSuccess(fileName),
    actionSetLoadingStatus: setLoadingStatusFetchAlbums,
    // params: data,
    // params: { ...data, ...photo },
    params: photo,
  })
)

export const insertPhotoSuccess = createAction<ActionTypesT, empty>(
  photoActionTypes.INSERT_PHOTO_SUCCESS
)

/// ////////////////////////////////////

export const updateAlbum = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  photoActionTypes.DATA_SEND,
  null,
  (data, photo) => ({
    url: Endpoints.updateAlbum(data.id, data.title, data.active),
    actionSuccess: () => updateAlbumSuccess(),
    actionSuccessMessage: 'Album upraveno',
    actionSetLoadingStatus: setLoadingStatusFetchAlbums,
    // params: data,
    // params: { ...data, ...photo },
    params: photo,
  })
)

export const updateAlbumSuccess = createAction<ActionTypesT, empty>(
  photoActionTypes.UPDATE_ALBUM_SUCCESS
)

/// ////////////////////////////////////

export const deleteAlbum = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  photoActionTypes.DATA_SEND,
  null,
  id => ({
    url: Endpoints.deleteAlbum(id),
    actionSuccess: () => deleteAlbumSuccess(id),
    actionSuccessMessage: 'Album smazáno',
    actionSetLoadingStatus: setLoadingStatusFetchAlbums,
  })
)

export const deleteAlbumSuccess = createAction<ActionTypesT, empty>(
  photoActionTypes.DELETE_ALBUM_SUCCESS
)

/// ////////////////////////////////////

export const photosLoaded = createAction<ActionTypesT, empty>(photoActionTypes.PHOTOS_LOADED)

export const deleteLoadedPhoto = createAction<ActionTypesT, empty>(
  photoActionTypes.DELETE_LOADED_PHOTO
)

/// ////////////////////////////////////

export const albumPhotoLoaded = createAction<ActionTypesT, empty>(
  photoActionTypes.ALBUM_PHOTO_LOADED
)

/// ////////////////////////

export const updatePriorityAlbum = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  photoActionTypes.DATA_SEND,
  null,
  (id: string, increase: string) => {
    return {
      url: Endpoints.updateAlbumPriority(id, increase),
      actionSuccess: updatePriorityAlbumSuccess,
      actionSetLoadingStatus: setUpdatePriorityAlbumLoadingStatus,
    }
  }
)

export const updatePriorityAlbumSuccess = createAction<ActionTypesT, empty>(
  photoActionTypes.UPDATE_PRIORITY_ALBUM_SUCCESS
)
export const setUpdatePriorityAlbumLoadingStatus = createAction<
  ActionTypesT,
  empty,
  LoadingStatusT
>(photoActionTypes.SET_UPDATE_PRIORITY_ALBUM_LOADING_STATUS)

/// ///////////

export const unmountAlbum = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  photoActionTypes.UNMOUNT_ALBUM
)
