/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import styles from '../../assets/jss/sar/completedStyle'

import Parallax from '../Parallax/Parallax'
import messages from '../../i18n/messages'

const useStyles = makeStyles(styles)

function SarHeaderImage() {
  const classes = useStyles()
  const { sar } = messages

  return (
    <Parallax image={`${process.env.PUBLIC_URL}/imgs/bg.jpg`} className={classes.parallax}>
      <div className={classes.container}>
        <Grid container>
          <Grid item>
            <div className={classes.brand}>
              <h1 className={classes.title}>
                {/* pro ukazku jak by to vypadalo bez spreadingu */}
                <FormattedMessage id={sar.id} defaultMessage={sar.defaultMessage} />
              </h1>
              <h3 className={classes.subtitle}>
              <FormattedMessage {...messages.tabory} /> - <FormattedMessage {...messages.vaclavMachac} />
              </h3>
            </div>
          </Grid>
        </Grid>
      </div>
    </Parallax>
  )
}

export default SarHeaderImage
