// @flow
export default {
  refreshToken: {
    BUFFER_WINDOW: 60000, // 1 minute
  },

  notification: {
    DELAY: 2000,
  },
}
