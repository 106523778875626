// @flow
export const Endpoints = {
  newsData: () => '/news/getNEWS.php',
  newsDataById: (newsId: number) => `/news/getNEWS.php?id=${newsId}`,
  updateNewsData: (newsId: number) => `/news/updateNEWS.php?id=${newsId}`,
  updateNewsVisibility: (newsId: number, visibility: string) =>
    `/news/updateVisibilityNEWS.php?id=${newsId}&visibility=${visibility}`,
  updateNewsPriority: (newsId: number, increase: string) =>
    `/news/updatePriorityNEWS.php?id=${newsId}&increase=${increase}`,
  archiveNews: (newsId: number) => `/news/archiveNEWS.php?id=${newsId}`,
  insertNewsData: () => `/news/insertNEWS.php`,
  // putTest: (requestId: number) => `/api/SAR/getNEWS.php?id=${requestId}`,
  sendRegistration: () => `/registration/sendRegistration.php`,
  // getActions: () => `/SAR/getActions.php`,
  getActions: () => `/actions/getActions.php`,
  updateAction: (actionId: number) => `/actions/updateAction.php?id=${actionId}`,
  archiveAction: (actionId: number) => `/actions/archiveAction.php?id=${actionId}`,
  updateParticipant: (id: number) => `/participants/updateParticipant.php?id=${id}`,
  updateActionDetail: (actionId: number) => `/actions/updateActionDetail.php?id=${actionId}`,
  updateProgram: (programId: number) => `/actions/updateProgram.php?id=${programId}`,
  updateTransport: (transportId: number) => `/actions/updateTransport.php?id=${transportId}`,
  // updateAction: (newsId: number) => `/SAR/news/updateNEWS.php?id=${newsId}`,
  getTransports: () => `/actions/getTransports.php`,
  getProgramsAdmin: () => `/actions/getPrograms.php`,
  getPrograms: (actionId: number) => `/actions/getPrograms.php?id=${actionId}`,
  getActionPrograms: (actionId: number) => `/actions/getActionPrograms.php?id=${actionId}`,
  getActionTransports: (actionId: number) => `/actions/getActionTransports.php?id=${actionId}`,
  // getProgram: (actionId: number) => `/actions/getProgram.php?id=${actionId}`,
  // getPlaces: (programId: number) => `/SAR/getPlaces.php?id=${programId}`,
  getPlaces: (actionId: number) => `/actions/getPlaces.php?id=${actionId}`,
  getParticipants: () => `/participants/getParticipants.php`,
  uploadFile: () => `/files/uploadFile.php`,
  getAlbums: () => `/photos/getAlbums.php`,
  getAlbum: (albumId: number) => `/photos/getAlbums.php?id=${albumId}`,
  // insertAlbum: () => `/SAR/photos/insertAlbum.php`,
  insertAlbum: (title: string, active: boolean) =>
    `/photos/insertAlbum.php?title=${title}&active=${active}`,
  // updateAlbum: (albumId: number) => `/SAR/photos/updateAlbum.php?id=${albumId}`,
  updateAlbum: (albumId: number, title: string, active: boolean) =>
    `/photos/updateAlbum.php?id=${albumId}&title=${title}&active=${active}`,
  insertPhoto: (albumId: number) => `/photos/insertPhoto.php?id=${albumId}`,
  getPhotos: (albumId: number) => `/photos/getPhotos.php?id=${albumId}`,
  updateAlbumPriority: (albumId: number, increase: string) =>
    `/photos/updatePriorityAlbum.php?id=${albumId}&increase=${increase}`,
  deleteAlbum: (albumId: number) => `/photos/deleteAlbum.php?id=${albumId}`,
  getDocuments: () => `/files/getDocuments.php`,
  deleteDocument: (file: string) => `/files/deleteDocument.php?file=${file}`,
  addDocument: () => `/files/addDocument.php`,
  getImages: () => `/images/getImages.php`,
  deleteImage: (file: string) => `/images/deleteImage.php?file=${file}`,
  addImage: () => `/images/addImage.php`,
  // google: () => 'https://oauth2.googleapis.com/token',
  // googleAuth: (code: string) => `/api/SAR/google.php?code=${code}`
  fetchAdmins: () => `/login/admins.php`,
  loginVerify: () => `/login/verify.php`,
}

export default Endpoints
