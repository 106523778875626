// @flow
import { createAction } from 'redux-actions'
import { copyKeysToValues } from '../../utils/object'
import Endpoints from '../../constants/endpoints'

export const actionsActionTypes = {
  DATA_FETCH: '',
  DATA_SEND: '',
  FETCH_ACTIONS_SUCCESS: '',
  FETCH_ACTIONS_LOADING_STATUS: '',
  UPDATE_ACTION_SUCCESS: '',
  UPDATE_ACTION_LOADING_STATUS: '',
  ARCHIVE_ACTION_SUCCESS: '',
  ARCHIVE_ACTION_LOADING_STATUS: '',
  UPDATE_PROGRAM_SUCCESS: '',
  UPDATE_PROGRAM_LOADING_STATUS: '',
  UPDATE_TRANSPORT_SUCCESS: '',
  UPDATE_TRANSPORT_LOADING_STATUS: '',
  UPDATE_ACTION_DETAIL_SUCCESS: '',
  UPDATE_ACTION_DETAIL_LOADING_STATUS: '',
  FETCH_TRANSPORTS_SUCCESS: '',
  FETCH_TRANSPORTS_LOADING_STATUS: '',
  FETCH_PROGRAMS_SUCCESS: '',
  FETCH_PROGRAMS_LOADING_STATUS: '',
  FETCH_ACTION_PROGRAMS_SUCCESS: '',
  FETCH_ACTION_PROGRAMS_LOADING_STATUS: '',
  FETCH_ACTION_TRANSPORTS_SUCCESS: '',
  FETCH_ACTION_TRANSPORTS_LOADING_STATUS: '',
}

copyKeysToValues(actionsActionTypes)

type ActionTypesT = Keys<typeof actionsActionTypes>

export const fetchActions = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  actionsActionTypes.DATA_FETCH,
  null,
  () => ({
    url: Endpoints.getActions(),
    actionSuccess: fetchActionsSuccess,
    actionSetLoadingStatus: setLoadingStatusActions,
  })
)

export const fetchActionsSuccess = createAction<ActionTypesT, empty>(
  actionsActionTypes.FETCH_ACTIONS_SUCCESS
)
export const setLoadingStatusActions = createAction<ActionTypesT, empty, LoadingStatusT>(
  actionsActionTypes.FETCH_ACTIONS_LOADING_STATUS
)

/// ///////////

export const updateAction = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  actionsActionTypes.DATA_SEND,
  null,
  (id: string, dataAction) => {
    return {
      url: Endpoints.updateAction(id),
      actionSuccess: updateActionSuccess,
      actionSuccessMessage: 'Akce aktualizována',
      actionSetLoadingStatus: setUpdateLoadingStatusAction,
      params: dataAction,
    }
  }
)

export const updateActionSuccess = createAction<ActionTypesT, empty>(
  actionsActionTypes.UPDATE_ACTION_SUCCESS
)

export const setUpdateLoadingStatusAction = createAction<ActionTypesT, empty, LoadingStatusT>(
  actionsActionTypes.UPDATE_ACTION_LOADING_STATUS
)

/// ///////

export const archiveAction = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  actionsActionTypes.DATA_SEND,
  null,
  (id: string, dataAction) => {
    return {
      url: Endpoints.archiveAction(id),
      actionSuccess: archiveActionSuccess,
      actionSuccessMessage: 'Akce archivována',
      actionSetLoadingStatus: setArchiveLoadingStatusAction,
      params: dataAction,
    }
  }
)

export const archiveActionSuccess = createAction<ActionTypesT, empty>(
  actionsActionTypes.ARCHIVE_ACTION_SUCCESS
)

export const setArchiveLoadingStatusAction = createAction<ActionTypesT, empty, LoadingStatusT>(
  actionsActionTypes.ARCHIVE_ACTION_LOADING_STATUS
)

/// ///////
export const updateActionDetail = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  actionsActionTypes.DATA_SEND,
  null,
  (id: string, dataPrograms, dataTransports) => {
    return {
      url: Endpoints.updateActionDetail(id),
      actionSuccess: updateActionDetailSuccess,
      actionSuccessMessage: 'Akce aktualizována',
      actionSetLoadingStatus: setUpdateLoadingStatusActionDetail,
      params: { programs: dataPrograms, transports: dataTransports },
    }
  }
)

export const updateActionDetailSuccess = createAction<ActionTypesT, empty>(
  actionsActionTypes.UPDATE_ACTION_DETAIL_SUCCESS
)

export const setUpdateLoadingStatusActionDetail = createAction<ActionTypesT, empty, LoadingStatusT>(
  actionsActionTypes.UPDATE_ACTION_DETAIL_LOADING_STATUS
)

export const updateProgram = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  actionsActionTypes.DATA_SEND,
  null,
  (id: string, dataProgram) => {
    return {
      url: Endpoints.updateProgram(id),
      actionSuccess: updateProgramSuccess,
      actionSuccessMessage: 'Aktualizace programu proběhla úspěšně',
      actionSetLoadingStatus: setUpdateLoadingStatusProgram,
      params: dataProgram,
    }
  }
)

export const updateProgramSuccess = createAction<ActionTypesT, empty>(
  actionsActionTypes.UPDATE_PROGRAM_SUCCESS
)

export const setUpdateLoadingStatusProgram = createAction<ActionTypesT, empty, LoadingStatusT>(
  actionsActionTypes.UPDATE_PROGRAM_LOADING_STATUS
)

export const updateTransport = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  actionsActionTypes.DATA_SEND,
  null,
  (id: string, dataTransport) => {
    return {
      url: Endpoints.updateTransport(id),
      actionSuccess: updateTransportSuccess,
      actionSetLoadingStatus: setUpdateLoadingStatusTransport,
      params: dataTransport,
    }
  }
)

export const updateTransportSuccess = createAction<ActionTypesT, empty>(
  actionsActionTypes.UPDATE_TRANSPORT_SUCCESS
)

export const setUpdateLoadingStatusTransport = createAction<ActionTypesT, empty, LoadingStatusT>(
  actionsActionTypes.UPDATE_TRANSPORT_LOADING_STATUS
)

/// //////////

export const fetchTransports = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  actionsActionTypes.DATA_FETCH,
  null,
  () => ({
    url: Endpoints.getTransports(),
    actionSuccess: fetchTransportsSuccess,
    actionSetLoadingStatus: setLoadingStatusTransports,
  })
)

export const fetchTransportsSuccess = createAction<ActionTypesT, empty>(
  actionsActionTypes.FETCH_TRANSPORTS_SUCCESS
)

export const setLoadingStatusTransports = createAction<ActionTypesT, empty, LoadingStatusT>(
  actionsActionTypes.FETCH_TRANSPORTS_LOADING_STATUS
)

/// //////////

export const fetchPrograms = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  actionsActionTypes.DATA_FETCH,
  null,
  () => ({
    url: Endpoints.getProgramsAdmin(),
    actionSuccess: fetchProgramsSuccess,
    // actionSuccessMessage: 'fetch programs success',
    actionSetLoadingStatus: setLoadingStatusTransports,
  })
)

export const fetchProgramsSuccess = createAction<ActionTypesT, empty>(
  actionsActionTypes.FETCH_PROGRAMS_SUCCESS
)

export const setLoadingStatusPrograms = createAction<ActionTypesT, empty, LoadingStatusT>(
  actionsActionTypes.FETCH_PROGRAMS_LOADING_STATUS
)

/// //////////

export const fetchActionPrograms = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  actionsActionTypes.DATA_FETCH,
  null,
  (id: string) => ({
    url: Endpoints.getActionPrograms(id),
    actionSuccess: fetchActionProgramsSuccess,
    actionSetLoadingStatus: setLoadingStatusActionPrograms,
  })
)

export const fetchActionProgramsSuccess = createAction<ActionTypesT, empty>(
  actionsActionTypes.FETCH_ACTION_PROGRAMS_SUCCESS
)

export const setLoadingStatusActionPrograms = createAction<ActionTypesT, empty, LoadingStatusT>(
  actionsActionTypes.FETCH_ACTION_PROGRAMS_LOADING_STATUS
)

/// //////////

export const fetchActionTransports = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  actionsActionTypes.DATA_FETCH,
  null,
  (id: string) => ({
    url: Endpoints.getActionTransports(id),
    actionSuccess: fetchActionTransportsSuccess,
    actionSetLoadingStatus: setLoadingStatusActionTransports,
  })
)

export const fetchActionTransportsSuccess = createAction<ActionTypesT, empty>(
  actionsActionTypes.FETCH_ACTION_TRANSPORTS_SUCCESS
)

export const setLoadingStatusActionTransports = createAction<ActionTypesT, empty, LoadingStatusT>(
  actionsActionTypes.FETCH_ACTION_TRANSPORTS_LOADING_STATUS
)
