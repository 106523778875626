// @flow
// import { get } from 'lodash'
import * as _ from 'lodash'

import { getFormValues } from 'redux-form'
// import { createSelector } from 'reselect'
import Forms from '../../constants/forms'

// import { customFormValueSelector } from "../forms";

export const getNewsDetail = state => state.newsDetail.newsDetail

export const getNewsformValues = (state: State): ?Object => getFormValues(Forms.newsForm)(state)

export const getNewsState = (state: StateT) => _.get(state, ['news'], {})

// export const getNewsData = (state: StateT) => get(getNewsState(state), ['data'], {})

export const getNewsLoadingStatus = (state: StateT) =>
  _.get(getNewsState(state), ['loadingStatus'], null)

export const getNewsList = (state: StateT) =>
  _.orderBy(_.get(getNewsState(state), ['list'], {}), 'priority', 'desc')

export const getActiveNewsList = (state: StateT) => getNewsList(state).filter(item => item.active)

export const getNewsDetailState = (state: StateT) => _.get(state, ['newsDetail'], {})

export const getNewsDetailLoadingStatus = (state: StateT) =>
  _.get(getNewsDetailState(state), ['loadingStatus'], null)
