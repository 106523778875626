/* eslint-disable react/jsx-props-no-spreading */
// @flow
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'

// import styles from '../../assets/jss/material-kit-react/views/components'
import styles from '../../assets/jss/sar/completedStyle'
import { messages, intl } from '../../i18n'
import message from '../../i18n/messages'

import Footer from '../../components/Footer/Footer'
import { SarHeaderImage } from '../../components/SarHeaderImage'
import { SarMultiList } from '../../components/SarMultiList'

import {
  fetchTransports,
  fetchActions,
  fetchPrograms,
  fetchActionPrograms,
  fetchActionTransports,
  getActiveActions,
  getInactiveActions,
  getActions,
  getPrograms,
  getActivePrograms,
  getInactivePrograms,
  getTransports,
  getActiveTransports,
  getInactiveTransports,
  getActiveActionTransports,
  getInactiveActionTransports,
  getActiveActionPrograms,
  getInactiveActionPrograms,
  updateAction,
  archiveAction,
  updateProgram,
  updateTransport,
  updateActionDetail,
} from '../../store/actions/index'

// function getModalStyle() {
//   const top = 50
//   const left = 50

//   return {
//     top: `${top}%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`,
//   }
// }

const useStyles = makeStyles(styles)

// const useStyles2 = makeStyles(theme => ({
//   paper: {
//     position: 'absolute',
//     width: 400,
//     backgroundColor: theme.palette.background.paper,
//     border: '2px solid #000',
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(2, 4, 3),
//   },
//   root: {
//     margin: 'auto',
//   },
//   button: {
//     margin: theme.spacing(0.5, 0),
//   },
// }))

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1)
}

export const AdminActions = () => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const [selectedAction, setSelectedAction] = React.useState('0')
  const [modifyAction, setModifyAction] = React.useState({ id: 0 })

  const [selectedTransport, setSelectedTransport] = React.useState('0')
  const [modifyTransport, setModifyTransport] = React.useState({ id: 0 })

  const [selectedProgram, setSelectedProgram] = React.useState('0')
  const [modifyProgram, setModifyProgram] = React.useState({ id: 0 })

  const [selectedActionToEdit, setSelectedActionToEdit] = React.useState('0')

  const [checkedTransport, setCheckedTransport] = React.useState([])
  const [selectedTransports, setSelectedTransports] = React.useState([])
  const [unselectedTransports, setUnselectedTransports] = React.useState([])

  const [checkedProgram, setCheckedProgram] = React.useState([])
  const [selectedPrograms, setSelectedPrograms] = React.useState([])
  const [unselectedPrograms, setUnselectedPrograms] = React.useState([])

  const [openAction, setOpenAction] = React.useState(false)
  const [openTransport, setOpenTransport] = React.useState(false)
  const [openProgram, setOpenProgram] = React.useState(false)

  const allActions = useSelector(getActions)
  const activeActions = useSelector(getActiveActions)
  const inactiveActions = useSelector(getInactiveActions)
  const programs = useSelector(getPrograms)
  const activePrograms = useSelector(getActivePrograms)
  const inactivePrograms = useSelector(getInactivePrograms)
  const transports = useSelector(getTransports)
  const activeTransports = useSelector(getActiveTransports)
  const inactiveTransports = useSelector(getInactiveTransports)
  const activeActionTransports = useSelector(getActiveActionTransports)
  const inactiveActionTransports = useSelector(getInactiveActionTransports)
  const activeActionPrograms = useSelector(getActiveActionPrograms)
  const inactiveActionPrograms = useSelector(getInactiveActionPrograms)

  /// /////////////

  const selectedTransportsChecked = intersection(checkedTransport, selectedTransports)
  const unselectedTransportsChecked = intersection(checkedTransport, unselectedTransports)

  const selectedProgramsChecked = intersection(checkedProgram, selectedPrograms)
  const unselectedProgramsChecked = intersection(checkedProgram, unselectedPrograms)

  // zavreni modalovych oken (editace akci, programu, dopravs)
  const handleCloseAction = () => {
    setOpenAction(false)
  }
  const handleCloseTransport = () => {
    setOpenTransport(false)
  }
  const handleCloseProgram = () => {
    setOpenProgram(false)
  }

  // editace akce, programu, dopravy (v modalu)
  const handleChangeActionActive = event => {
    setModifyAction({ ...modifyAction, active: !modifyAction.active })
  }

  const handleChangeActionName = event => {
    setModifyAction({ ...modifyAction, value: event.target.value })
  }

  const handleChangeProgramActive = event => {
    setModifyProgram({ ...modifyProgram, active: !modifyProgram.active })
  }

  const handleChangeProgramName = event => {
    setModifyProgram({ ...modifyProgram, value: event.target.value })
  }

  const handleChangeTransportActive = event => {
    setModifyTransport({ ...modifyTransport, active: !modifyTransport.active })
  }

  const handleChangeTransportName = event => {
    setModifyTransport({ ...modifyTransport, value: event.target.value })
  }

  // vyber dopravy v multiselectu
  const handleSelectTransport = value => () => {
    const currentIndex = checkedTransport.indexOf(value)
    const newChecked = [...checkedTransport]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setCheckedTransport(newChecked)
  }

  // vyber programu v multiselectu
  const handleSelectProgram = value => () => {
    const currentIndex = checkedProgram.indexOf(value)
    const newChecked = [...checkedProgram]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setCheckedProgram(newChecked)
  }

  // akce tlacitka na preneseni doprav z aktivnich na neaktivni
  const handleCheckedTransportsToUnselected = () => {
    setUnselectedTransports(unselectedTransports.concat(selectedTransportsChecked))
    setSelectedTransports(not(selectedTransports, selectedTransportsChecked))
    setCheckedTransport(not(checkedTransport, selectedTransportsChecked))
  }

  // akce tlacitka na preneseni doprav z neaktivnich na aktivni
  const handleCheckedTransportsToSelected = () => {
    setSelectedTransports(selectedTransports.concat(unselectedTransportsChecked))
    setUnselectedTransports(not(unselectedTransports, unselectedTransportsChecked))
    setCheckedTransport(not(checkedTransport, unselectedTransportsChecked))
  }

  // akce tlacitka na preneseni programu z aktivnich na neaktivni
  const handleCheckedProgramsToUnselected = () => {
    setUnselectedPrograms(unselectedPrograms.concat(selectedProgramsChecked))
    setSelectedPrograms(not(selectedPrograms, selectedProgramsChecked))
    setCheckedProgram(not(checkedProgram, selectedProgramsChecked))
  }

  // akce tlacitka na preneseni doprav z neaktivnich na aktivni
  const handleCheckedProgramsToSelected = () => {
    setSelectedPrograms(selectedPrograms.concat(unselectedProgramsChecked))
    setUnselectedPrograms(not(unselectedPrograms, unselectedProgramsChecked))
    setCheckedProgram(not(checkedProgram, unselectedProgramsChecked))
  }

  const saveActionDetail = () => {
    dispatch(updateActionDetail(selectedActionToEdit, selectedPrograms, selectedTransports))
    setSelectedActionToEdit('0')
    setSelectedPrograms([])
  }

  /// /////////////////

  // zmena comboboxu neaktivnich akci
  const handleChangeInactiveAction = event => {
    setSelectedAction(event.target.value)
  }

  // zmena comboboxu neaktivnich dopravach
  const handleChangeInactiveTransport = event => {
    setSelectedTransport(event.target.value)
  }

  // zmena comboboxu neaktivnich programech
  const handleChangeInactiveProgram = event => {
    setSelectedProgram(event.target.value)
  }

  // zmena comboboxu pro editaci doprav a programu akce
  const handleChangeActiveAction = event => {
    setSelectedActionToEdit(event.target.value)
  }

  // nacteni dat z API
  useEffect(() => {
    dispatch(fetchActions())
    dispatch(fetchTransports())
    dispatch(fetchPrograms())
  }, [dispatch])

  // po zmene akce - nacteni programu a doprav
  useEffect(() => {
    dispatch(fetchActionPrograms(selectedActionToEdit))
    dispatch(fetchActionTransports(selectedActionToEdit))
  }, [dispatch, selectedActionToEdit])

  useEffect(() => {
    setSelectedTransports(activeActionTransports)
  }, [activeActionTransports])

  useEffect(() => {
    setUnselectedTransports(inactiveActionTransports)
  }, [inactiveActionTransports])

  useEffect(() => {
    setSelectedPrograms(activeActionPrograms)
  }, [activeActionPrograms])

  useEffect(() => {
    setUnselectedPrograms(inactiveActionPrograms)
  }, [inactiveActionPrograms])

  // otevreni modalu editace akce s danou akci
  const editAction = id => {
    const actionId = id === 0 ? selectedAction : id

    actionId !== 0 &&
      actionId !== -1 &&
      setModifyAction(allActions.find(element => element.id === actionId))

    actionId === -1 && setModifyAction({ id: 0, value: null, active: false })

    setOpenAction(true)
  }

  // editace akce
  const updateModifiedAction = () => {
    dispatch(updateAction(modifyAction.id, modifyAction))
    setOpenAction(false)
  }
  // editace akce
  const archiveModifiedAction = () => {
    const r = window.confirm('Opravdu archivovat akci?')
    if (r === true) {
      dispatch(archiveAction(modifyAction.id, modifyAction))
      setOpenAction(false)
    }
  }

  // otevreni modalu editace programu s danym programem
  const editProgram = id => {
    const programId = id === 0 ? selectedProgram : id

    programId !== 0 &&
      programId !== -1 &&
      setModifyProgram(programs.find(element => element.id === programId))

    programId === -1 && setModifyProgram({ id: 0, value: null, active: false })

    setOpenProgram(true)
  }

  // editace programu
  const updateModifiedProgram = () => {
    dispatch(updateProgram(modifyProgram.id, modifyProgram))
    setOpenProgram(false)
  }

  // otevreni modalu editace dopravy s danou dopravou
  const editTransport = id => {
    const transportId = id === 0 ? selectedTransport : id

    transportId !== 0 &&
      transportId !== -1 &&
      setModifyTransport(transports.find(element => element.id === transportId))

    transportId === -1 && setModifyTransport({ id: 0, value: null, active: false })

    setOpenTransport(true)
  }

  // editace dopravy
  const updateModifiedTransport = () => {
    dispatch(updateTransport(modifyTransport.id, modifyTransport))
    setOpenTransport(false)
  }

  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <Grid container justify="center">
          <Grid item md={8} xs={11}>
            <Grid container justify="center">
              <Grid item md={4}>
                <h3>
                  <FormattedMessage {...message.registrationAction} />
                </h3>
                <FormControl variant="outlined">
                  <InputLabel id="inactive-actions" className={classes.actionsInputStyle}>
                    <FormattedMessage {...message.inactive} />
                  </InputLabel>
                  <Select
                    labelId="inactive-actions"
                    id="demo-simple-select-outlined"
                    onChange={handleChangeInactiveAction}
                    value={selectedAction}
                    label={intl.formatMessage(messages.inactive)}
                    className={classes.actionsInputStyle}>
                    <MenuItem value="0">
                      <em>
                        <FormattedMessage {...message.inactiveActionList} />
                      </em>
                    </MenuItem>
                    {inactiveActions.map(item => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  variant="outlined"
                  disabled={selectedAction === '0'}
                  onClick={() => editAction(0)}
                  className={classes.actionsEditInput}>
                  <i className="far fa-edit" />
                </Button>
                {activeActions.map(item => (
                  <Button
                    key={item.id}
                    variant="outlined"
                    onClick={() => editAction(item.id)}
                    className={classes.actionsInputStyle}>
                    {item.value}
                  </Button>
                ))}
                <Button
                  variant="outlined"
                  onClick={() => editAction(-1)}
                  className={classes.actionsInputStyle}>
                  <FormattedMessage {...message.createNewAction} />
                </Button>
              </Grid>
              <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={openAction}
                onClose={handleCloseAction}>
                <div className={`${classes.paper} ${classes.modalStyle}`}>
                  <h3 id="simple-modal-title">
                    <FormattedMessage {...message.registrationAction} />
                    (ID: {modifyAction.id})
                  </h3>
                  <TextField
                    id="standard-basic"
                    value={modifyAction.value}
                    onChange={handleChangeActionName}
                    className={classes.actionsTextFieldEdit}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={modifyAction.active}
                        onChange={handleChangeActionActive}
                        name="checkedActive"
                        color="primary"
                      />
                    }
                    label={intl.formatMessage(messages.active)}
                  />
                  <Button
                    variant="outlined"
                    onClick={() => updateModifiedAction()}
                    className={`${classes.right} ${classes.margin8Top}`}>
                    <FormattedMessage {...message.save} />
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => archiveModifiedAction()}
                    className={`${classes.right} ${classes.margin8Top}`}>
                    <FormattedMessage {...message.archive} />
                  </Button>
                </div>
              </Modal>

              {/* /////////////////////////////////////////////////////////////////////////// */}

              <Grid item md={4}>
                <h3>
                  <FormattedMessage {...message.registrationPrograms} />
                </h3>
                <FormControl variant="outlined">
                  <InputLabel id="inactive-actions" className={classes.actionsInputStyle}>
                    <FormattedMessage {...message.inactive} />
                  </InputLabel>
                  <Select
                    labelId="inactive-actions"
                    id="demo-simple-select-outlined"
                    onChange={handleChangeInactiveProgram}
                    value={selectedProgram}
                    label={intl.formatMessage(messages.inactive)}
                    className={classes.actionsInputStyle}>
                    <MenuItem value="0">
                      <em>
                        <FormattedMessage {...message.inactiveProgramList} />
                      </em>
                    </MenuItem>
                    {inactivePrograms.map(item => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  variant="outlined"
                  disabled={selectedProgram === '0'}
                  onClick={() => editProgram(0)}
                  className={classes.actionsEditInput}>
                  <i className="far fa-edit" />
                </Button>
                {activePrograms.map(item => (
                  <Button
                    key={item.id}
                    variant="outlined"
                    onClick={() => editProgram(item.id)}
                    className={classes.actionsInputStyle}>
                    {item.value}
                  </Button>
                ))}
                <Button
                  variant="outlined"
                  onClick={() => editProgram(-1)}
                  className={classes.actionsInputStyle}>
                  <FormattedMessage {...message.createNewProgram} />
                </Button>
              </Grid>
              <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={openProgram}
                onClose={handleCloseProgram}>
                <div className={`${classes.paper} ${classes.modalStyle}`}>
                  <h3 id="simple-modal-title">
                    <FormattedMessage {...message.registrationProgram} />
                    (ID: {modifyProgram.id})
                  </h3>
                  <TextField
                    id="standard-basic"
                    value={modifyProgram.value}
                    onChange={handleChangeProgramName}
                    className={classes.actionsTextFieldEdit}
                  />
                  <br />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={modifyProgram.active}
                        onChange={handleChangeProgramActive}
                        name="checkedActive"
                        color="primary"
                      />
                    }
                    label={intl.formatMessage(messages.active)}
                  />
                  <Button
                    variant="outlined"
                    onClick={() => updateModifiedProgram()}
                    className={`${classes.right} ${classes.margin8Top}`}>
                    <FormattedMessage {...message.save} />
                  </Button>
                </div>
              </Modal>
              {/* /////////////////////////////////////////////////////////////////////////// */}

              <Grid item md={4}>
                <h3>
                  <FormattedMessage {...message.registrationTransport} />
                </h3>

                <FormControl variant="outlined">
                  <InputLabel id="inactive-actions" className={classes.actionsInputStyle}>
                    <FormattedMessage {...message.inactive} />
                  </InputLabel>
                  <Select
                    labelId="inactive-actions"
                    id="demo-simple-select-outlined"
                    onChange={handleChangeInactiveTransport}
                    value={selectedTransport}
                    label={intl.formatMessage(messages.inactive)}
                    className={classes.actionsInputStyle}>
                    <MenuItem value="0">
                      <em>
                        <FormattedMessage {...message.inactiveTransportList} />
                      </em>
                    </MenuItem>
                    {inactiveTransports.map(item => (
                      <MenuItem value={item.id}>{item.value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  variant="outlined"
                  onClick={() => editTransport(0)}
                  disabled={selectedTransport === '0'}
                  className={classes.actionsEditInput}>
                  <i className="far fa-edit" />
                </Button>

                {activeTransports.map(item => (
                  <Button
                    key={item.id}
                    variant="outlined"
                    onClick={() => editTransport(item.id)}
                    className={classes.actionsInputStyle}>
                    {item.value}
                  </Button>
                ))}
                <Button
                  variant="outlined"
                  onClick={() => editTransport(-1)}
                  className={classes.actionsInputStyle}>
                  <FormattedMessage {...message.createNewTransport} />
                </Button>
              </Grid>
              <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={openTransport}
                onClose={handleCloseTransport}>
                <div className={`${classes.paper} ${classes.modalStyle}`}>
                  <h3 id="simple-modal-title">
                    <FormattedMessage {...message.registrationTransport} />
                    (ID: {modifyTransport.id})
                  </h3>
                  <TextField
                    id="standard-basic"
                    value={modifyTransport.value}
                    onChange={handleChangeTransportName}
                    className={classes.actionsTextFieldEdit}
                  />
                  <br />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={modifyTransport.active}
                        onChange={handleChangeTransportActive}
                        name="checkedActive"
                        color="primary"
                      />
                    }
                    label={intl.formatMessage(messages.active)}
                  />
                  <Button
                    variant="outlined"
                    onClick={() => updateModifiedTransport()}
                    className={`${classes.right} ${classes.margin8Top}`}>
                    <FormattedMessage {...message.save} />
                  </Button>
                </div>
              </Modal>
            </Grid>
            {/* /////////////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////-----------------------------------------------------////////////////////////// */}
            {/* /////////////////////////////////////////////////////////////////////////// */}

            <br />
            <hr />
            <br />

            <Grid container justify="center">
              <Grid item md={6}>
                <Grid container justify="center">
                  <FormControl variant="outlined">
                    <InputLabel id="inactive-actions" className={classes.actionsInputStyle}>
                      <FormattedMessage {...message.actionList} />
                    </InputLabel>
                    <Select
                      labelId="inactive-actions"
                      id="demo-simple-select-outlined"
                      onChange={handleChangeActiveAction}
                      value={selectedActionToEdit}
                      label={intl.formatMessage(messages.actionList)}
                      className={classes.actionsInputStyle}>
                      <MenuItem value="0">
                        <em>
                          <FormattedMessage {...message.chooseActionList} />
                        </em>
                      </MenuItem>
                      {activeActions.map(item => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="outlined"
                    onClick={() => saveActionDetail()}
                    disabled={selectedActionToEdit === '0'}
                    className={classes.actionsInputStyle}>
                    <FormattedMessage {...message.save} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {/* /////////////////////////////////////////////////////////////////////////// */}
            <Grid container justify="center">
              <Grid item md={8}>
                <h3>
                  <FormattedMessage {...message.registrationTransport} />
                </h3>
                <Grid container justify="center">
                  <Grid item md={5}>
                    <SarMultiList
                      title={intl.formatMessage(messages.selected)}
                      items={selectedTransports}
                      checkedList={checkedTransport}
                      handleSelect={handleSelectTransport}
                      setCheckedList={setCheckedTransport}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Grid container direction="column" alignItems="center">
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedTransportsToUnselected}
                        disabled={selectedTransportsChecked.length === 0}
                        aria-label="move selected right">
                        &gt;
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedTransportsToSelected}
                        disabled={unselectedTransportsChecked.length === 0}
                        aria-label="move selected left">
                        &lt;
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item md={5}>
                    <SarMultiList
                      title={intl.formatMessage(messages.others)}
                      items={unselectedTransports}
                      checkedList={checkedTransport}
                      handleSelect={handleSelectTransport}
                      setCheckedList={setCheckedTransport}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* /////////////////////////////////////////////////////////////////////////// */}

              <Grid item md={8}>
                <h3>
                  <FormattedMessage {...message.registrationPrograms} />
                </h3>
                <Grid container justify="center">
                  <Grid item md={5}>
                    <SarMultiList
                      title={intl.formatMessage(messages.actionList)}
                      items={selectedPrograms}
                      checkedList={checkedProgram}
                      handleSelect={handleSelectProgram}
                      setCheckedList={setCheckedProgram}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Grid container direction="column" alignItems="center">
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedProgramsToUnselected}
                        disabled={selectedProgramsChecked.length === 0}
                        aria-label="move selected right">
                        &gt;
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedProgramsToSelected}
                        disabled={unselectedProgramsChecked.length === 0}
                        aria-label="move selected left">
                        &lt;
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item md={5}>
                    <SarMultiList
                      title={intl.formatMessage(messages.others)}
                      items={unselectedPrograms}
                      checkedList={checkedProgram}
                      handleSelect={handleSelectProgram}
                      setCheckedList={setCheckedProgram}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </div>
  )
}
