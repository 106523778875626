// @flow
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFormSyncErrors } from 'redux-form'
import { FormattedMessage } from 'react-intl'

import { Forms } from '../../constants'
import message from '../../i18n/messages'

import Button from '../../components/CustomButtons/Button'

import { createRegistration, getRegistrationFormValues } from '../../store/registration'

export const RegistrationSubmit = (props: Props) => {
  const { registrationType } = props

  const dispatch = useDispatch()

  const syncErrors = useSelector(getFormSyncErrors(Forms.registrationForm))
  const registrationFormValues = useSelector(getRegistrationFormValues)

  const canSubmitRegistration = true

  const saveAndSubmitRegistration = () => {
    const { touch, valid } = props

    if (valid) {
      dispatch(createRegistration(registrationFormValues))
      // dispatch(createRegistration())
    } else {
      touch(...Object.keys(syncErrors))
    }
  }

  return (
    <Button color="primary" onClick={saveAndSubmitRegistration} disabled={!canSubmitRegistration}>
      {registrationType === 'Rezervace' ? (
        <FormattedMessage {...message.registrationSendReservation} />
      ) : (
        <FormattedMessage {...message.registrationSendApplication} />
      )}
    </Button>
  )
}

export default RegistrationSubmit
