// @flow
import { createAction } from 'redux-actions'
import { copyKeysToValues } from '../../utils/object'

import Endpoints from '../../constants/endpoints'

export const filesActionTypes = {
  FILES_LOAD: '',
  DELETE_LOADED_FILE: '',
  DATA_SEND: '',
  DATA_FETCH: '',
  SEND_FILES_SUCCESS: '',
  SET_FILES_LOADING_STATUS: '',
  FETCH_DOCUMENTS_SUCCESS: '',
  SET_DOCUMENTS_LOADING_STATUS: '',
  DELETE_DOCUMENT_SUCCESS: '',
  ADD_DOCUMENT_SUCCESS: '',
}
copyKeysToValues(filesActionTypes)

type ActionTypesT = Keys<typeof filesActionTypes>

export const sendFiles = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  filesActionTypes.DATA_SEND,
  null,
  (data, fileName) => ({
    url: Endpoints.uploadFile(),
    actionSuccess: () => sendFilesSuccess(fileName),
    actionSetLoadingStatus: setLoadingStatusFiles,
    params: data,
  })
)
export const filesLoaded = createAction<ActionTypesT, empty>(filesActionTypes.FILES_LOAD)

export const deleteLoadedFile = createAction<ActionTypesT, empty>(
  filesActionTypes.DELETE_LOADED_FILE
)

export const sendFilesSuccess = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  filesActionTypes.SEND_FILES_SUCCESS
)
export const setLoadingStatusFiles = createAction<ActionTypesT, empty, LoadingStatusT>(
  filesActionTypes.SET_FILES_LOADING_STATUS
)

// ///////////////////////

export const fetchDocuments = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  filesActionTypes.DATA_FETCH,
  null,
  () => ({
    url: Endpoints.getDocuments(), // `/SAR/news/getNEWS.php?id=${id}`,
    actionSuccess: fetchDocumentsSuccess,
    actionSetLoadingStatus: setLoadingStatusDocuments,
  })
)
export const fetchDocumentsSuccess = createAction<ActionTypesT, empty>(
  filesActionTypes.FETCH_DOCUMENTS_SUCCESS
)
export const setLoadingStatusDocuments = createAction<ActionTypesT, empty, LoadingStatusT>(
  filesActionTypes.SET_DOCUMENTS_LOADING_STATUS
)

/// ///////////////

export const deleteDocument = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  filesActionTypes.DATA_SEND,
  null,
  file => ({
    url: Endpoints.deleteDocument(file),
    actionSuccess: () => deleteDocumentSuccess(file),
    actionSetLoadingStatus: setLoadingStatusDocuments,
  })
)

export const deleteDocumentSuccess = createAction<ActionTypesT, empty>(
  filesActionTypes.DELETE_DOCUMENT_SUCCESS
)

/// ////////////////////////////////////

export const addDocument = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  filesActionTypes.DATA_SEND,
  null,
  file => ({
    url: Endpoints.addDocument(),
    actionSuccess: addDocumentSuccess,
    actionSetLoadingStatus: setLoadingStatusDocuments,
    params: file,
  })
)

export const addDocumentSuccess = createAction<ActionTypesT, empty>(
  filesActionTypes.ADD_DOCUMENT_SUCCESS
)
