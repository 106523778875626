import { handleActions } from 'redux-actions'
import history from '../../history'
import { ROUTES } from '../../constants'

import {
  getActionsSuccess,
  // getActionsFailure,
  getProgramsSuccess,
  // getProgramsFailure,
  getPlacesSuccess,
  // getPlacesFailure,
  registrationActionTypes,
} from './actions'
// import { photoActionTypes } from './actions'

const reduceRegistrationSubmitSucess = (state, action) => {
  history.push(`${ROUTES.RESERVATION_SUCCESS}`)
  return {
    ...state,
    registrationSuccessData: action.payload.data,
  }
}

export const getRegistrationsReducer = handleActions(
  {
    [registrationActionTypes.CREATE_REGISTRATION_SUCCESS]: reduceRegistrationSubmitSucess,
    [getActionsSuccess]: (state, action) => ({
      actions: action.payload,
      programs: state.programs,
      places: state.places,
      registrationSuccessData: {},
    }),
    // [getActionsFailure]: (state, action) => ({
    //   actions: [{ id: 0, value: '' }],
    //   programs: [{ id: 0, value: '' }],
    //   places: [{ id: 0, value: '' }],
    //   registrationSuccessData: {},
    // }),
    [getProgramsSuccess]: (state, action) => ({
      actions: state.actions,
      programs: action.payload,
      places: state.places,
      registrationSuccessData: {},
    }),
    // [getProgramsFailure]: (state, action) => ({
    //   actions: state.actions,
    //   programs: [{ id: 0, value: '' }],
    //   places: [{ id: 0, value: '' }],
    //   registrationSuccessData: {},
    // }),
    [getPlacesSuccess]: (state, action) => ({
      actions: state.actions,
      programs: state.programs,
      places: action.payload,
      registrationSuccessData: {},
    }),
    // [getPlacesFailure]: (state, action) => ({
    //   actions: state.actions,
    //   programs: state.programs,
    //   places: [{ id: 0, value: '' }],
    //   registrationSuccessData: {},
    // }),
  },
  {
    actions: [{ id: 0, value: '' }],
    programs: [{ id: 0, value: 'Nejprve vyberte akci' }],
    places: [{ id: 0, value: 'Nejprve vyberte akci' }],
    registrationSuccessData: {},
  }
)
