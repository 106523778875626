/* eslint-disable react/jsx-props-no-spreading */
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { FormHelperText } from '@material-ui/core'

import { FormattedMessage } from 'react-intl'
import React from 'react'

export const SarToggleButtonGroup = props => {
  const {
    defaultValue,
    input,
    meta,
    children,
    // meta: { touched, invalid, error, warning },
    meta: { touched, error },
    ...custom
  } = props

  // const { onChange } = input
  const { value, onChange } = input

  // const [buttonValue, setButtonValue] = React.useState(defaultValue)

  return (
    <>
      <ToggleButtonGroup
        {...custom}
        // value={buttonValue}
        value={value}
        exclusive
        onChange={(_, newValue) => {
          // newValue && setButtonValue(newValue)
          onChange(newValue)
        }}
        // helperText={
        //   touched &&
        //   ((error && <FormattedMessage {...error} />) ||
        //     (warning && <FormattedMessage {...warning} />))
        // }
        touched={meta.touched.toString()}>
        {children}
      </ToggleButtonGroup>
      {touched && error && (
        <FormHelperText error>
          <FormattedMessage {...error} />
        </FormHelperText>
      )}
    </>
  )
}
