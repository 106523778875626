// @flow
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import InputMask from 'react-input-mask'

import TextField from '@material-ui/core/TextField'

export default function SarPhoneField(props: Props) {
  const {
    label,
    input,
    // value,
    meta: { touched, invalid, error, warning },
    ...custom
  } = props

  return (
    <InputMask mask="+420 999 999 999" {...input}>
      {() => (
        <TextField
          fullWidth
          label={label}
          placeholder={label}
          helperText={
            touched &&
            ((error && <FormattedMessage {...error} />) ||
              (warning && <FormattedMessage {...warning} />))
          }
          variant="outlined"
          error={touched && invalid}
          {...custom}
        />
      )}
    </InputMask>
  )
}
