import React from 'react'

import Parallax from '../Parallax/Parallax'

import './style.css'

function SarAdminHeaderImage() {
  return <Parallax className="adminHeader" image={`${process.env.PUBLIC_URL}/imgs/bgAdmin.jpg`} />
}

export default SarAdminHeaderImage
