// @flow
import messages from '../../i18n/messages'

const warnings = (values: Object) => {
  const result: Object = {}

  // console.log(messages.sar);
  if (!values.telefon2) {
    result.telefon2 = messages.validatePhone
  }

  return result
}

export default warnings
