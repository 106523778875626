// @flow
import { createAction } from 'redux-actions'
import { copyKeysToValues } from '../../utils/object'
import type { FetchMetaDataT } from '../../sagas/types'
import type { LoadingStatusT } from '../types'
import Endpoints from '../../constants/endpoints'

export const authActionTypes = {
  DATA_FETCH: '',
  DATA_SEND: '',
  SIGN_IN_SUCCESS: '',
  // SIGN_IN_SUCCESS_GOOGLE: '',
  // LOGIN_CHECK_SUCCESS: '',
  // SET_LOGIN_LOADING_STATUS: '',
  // LOGOUT: '',
  SIGN_IN: '',
  SIGN_OUT: '',
  FETCH_ADMINS_SUCCESS: '',
  SET_LOADING_STATUS_FETCH_ADMINS: '',
}

copyKeysToValues(authActionTypes)

export type AuthActionTypesT = $Keys<typeof authActionTypes>

// /**
//  * Action for starting logout
//  *
//  * @return {LoginAction} action object
//  */
// export const login = createAction<ActionTypesT, empty>(authActionTypes.LOGIN)

// export const loginCheck = createAction<AuthActionTypesT, empty, Object, FetchMetaDataT>(
//   authActionTypes.DATA_SEND,
//   null,
//   value => ({
//     url: Endpoints.loginVerify(), // '/SAR/login/verify.php', // todo - doresit failure
//     actionSuccess: loginCheckSuccess,
//     actionSetLoadingStatus: setLoadingStatusLogin,
//     data: value,
//     //   actionSuccess: setCodebooksIdentityDocuments,
//     // actionSetLoadingStatus: setLoadingStatusIdentityDocuments,
//   })
// )

// export const loginCheckSuccess = createAction<AuthActionTypesT, empty>(
//   authActionTypes.LOGIN_CHECK_SUCCESS
// )
// export const setLoadingStatusLogin = createAction<AuthActionTypesT, empty, LoadingStatusT>(
//   authActionTypes.SET_LOGIN_LOADING_STATUS
// )
/// //////

export const fetchAdmins = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  authActionTypes.DATA_FETCH,
  null,
  () => ({
    url: Endpoints.fetchAdmins(),
    actionSuccess: fetchAdminSuccess,
    actionSetLoadingStatus: setLoadingStatusFetchAdmins,
  })
)

export const fetchAdminSuccess = createAction<AuthActionTypesT, empty>(
  authActionTypes.FETCH_ADMINS_SUCCESS
)
export const setLoadingStatusFetchAdmins = createAction<AuthActionTypesT, empty, LoadingStatusT>(
  authActionTypes.SET_LOADING_STATUS_FETCH_ADMINS
)

// ///////

// export const logout = createAction<AuthActionTypesT, empty>(authActionTypes.LOGOUT)

// export const signIn2 = credentials => {
//   // return (dispatch, getState, { getFirebase }) => {
//   //   const firebase = getFirebase()
//   //   firebase
//   //     .auth()
//   //     .signInWithEmailAndPassword(credentials.email, credentials.password)
//   //     .then(() => {
//   //       dispatch({ type: 'LOGIN_SUCCESS' })
//   //     })
//   //     .catch(err => {
//   //       dispatch({ type: 'LOGIN_ERROR', err })
//   //     })
//   // }
// }

export const signIn = createAction(authActionTypes.SIGN_IN)

export const signInSuccess = createAction(authActionTypes.SIGN_IN_SUCCESS)

// export const signInSuccessGoogle = createAction(authActionTypes.SIGN_IN_SUCCESS_GOOGLE)

export const signOut = createAction(authActionTypes.SIGN_OUT)

export const signOutSuccess = createAction('SIGN_OUT_SUCCESS')

// //////

export const RefreshTokenActionTypes = {
  REFRESH_TOKEN_SUCCESS: '',
  REFRESH_TOKEN_FAILURE: '',
  REFRESH_TOKEN: '',
}

copyKeysToValues(RefreshTokenActionTypes)

export const refreshTokenSuccess = createAction(RefreshTokenActionTypes.REFRESH_TOKEN_SUCCESS)

export const refreshTokenFailure = createAction(RefreshTokenActionTypes.REFRESH_TOKEN_FAILURE)

export const refreshToken = createAction(RefreshTokenActionTypes.REFRESH_TOKEN)
