// @flow
// React Router path definition
// Naming convention = constant-case page container names
export default {
  EMPTY: '/',
  MAIN: '/main',
  LANDING: '/landing',
  NEWS_DETAIL_ID: '/news/:newsId',
  NEWS_DETAIL: '/news/',
  RESERVATION: '/reservation',
  RESERVATION_SUCCESS: '/reservationSend',
  LOGIN: '/login',
  ADMIN_NEWS: '/adminNews',
  ADMIN_NEWS_DETAIL_ID: '/adminNewsDetail/:newsId',
  ADMIN_NEWS_DETAIL: '/adminNewsDetail/',
  SIGNIN: '/signin',
  PHOTOGALLERY: '/foto',
  PHOTOALBUM: '/album/',
  PHOTOALBUM_ID: '/album/:albumId',
  ADMIN_PHOTOGALLERY: '/adminFoto',
  ADMIN_PHOTOALBUM: '/adminAlbum/',
  ADMIN_PHOTOALBUM_ID: '/adminAlbum/:albumId',
  ADMIN_PARTICIPANTS: '/adminParticipants/',
  ADMIN_ACTIONS: '/adminActions/',
  CONTACTS: '/contacts/',
  CONDITIONS: '/conditions/',
  FAQ_SKI: '/lyze/',
  FAQ_CAMP: '/tabory/',
  FAQ_BIKE: '/kolo/',
}
