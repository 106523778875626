// @flow
import { handleActions } from 'redux-actions'

import { authActionTypes } from './actions'

import type { LoginStateT, setLoginActionT, setLoadingStatusActionT } from './types'

// export const loginInitialState = (): LoginStateT => ({
//   data: {
//     access_token: null,
//     expires_at: null,
//     name: null,
//     email: null,
//     image: null,
//     admins: null,
//   },
//   loadingStatus: null,
// })

const signInInitState = {
  email: null,
  token: null,
  authError: null,
}

// const reduceLoginCheckSuccess = (state: LoginStateT, action: setLoginActionT) => {
//   const { data } = action.payload
//   return {
//     ...state,
//     data: {
//       access_token: data.tc.access_token,
//       expires_at: new Date(data.tokenObj.expires_at).toLocaleString(),
//       first_issued_at: new Date(data.tokenObj.first_issued_at).toLocaleString(),
//       name: data.profileObj.name,
//       email: data.profileObj.email,
//       image: data.profileObj.imageUrl,
//       // data: action.payload,
//     },
//   }
// }

// const reduceSetLoadingStatus = (state: LoginStateT, action: setLoadingStatusActionT) => {
//   return {
//     ...state,
//     loadingStatus: action.payload,
//   }
// }
// const reduceLogout = () => {
//   return {
//     data: {
//       access_token: null,
//       refresh_token: null,
//       expires_at: null,
//       name: null,
//       email: null,
//       image: null,
//       admins: null,
//     },
//     loadingStatus: null,
//   }
// }

// const reduceFetchAdmins = (state: LoginStateT, action: setLoginActionT) => {
//   return {
//     data: {
//       access_token: null,
//       refresh_token: null,
//       expires_at: null,
//       name: null,
//       email: null,
//       image: null,
//       admins: action.payload,
//     },
//     loadingStatus: null,
//   }
// }

// export const loginCheckReducer = handleActions<LoginStateT, setLoginActionT>(
//   {
//     [authActionTypes.LOGIN_CHECK_SUCCESS]: reduceLoginCheckSuccess,
//     [authActionTypes.SET_LOGIN_LOADING_STATUS]: reduceSetLoadingStatus,
//     [authActionTypes.LOGOUT]: reduceLogout,
//     [authActionTypes.FETCH_ADMINS_SUCCESS]: reduceFetchAdmins,
//   },
//   loginInitialState()
// )

export const authReducer = (state = signInInitState, action) => {
  switch (action.type) {
    case 'LOGIN_ERROR':
      // console.log('login error')
      return { ...state, authError: 'Login failed' }
    case 'SIGN_IN_SUCCESS':
      // console.log(`SIGN_IN_SUCCESS${action.payload}`)
      return {
        ...state,
        email: action.payload.dataUser.user.email,
        token: action.payload.token,
        refresh_token: action.payload.dataUser.user.refreshToken,
        issued_at: new Date(action.payload.dataUser.user.date),
        expires_at: new Date(new Date(action.payload.dataUser.user.date).getTime() + 50 * 60000),
        authError: null,
      }
    // case 'SIGN_IN_SUCCESS_GOOGLE':
    //   // console.log(`SIGN_IN_SUCCESS_GOOGLE${action.payload.user.email}`)
    //   // console.log(`SIGN_IN_SUCCESS_GOOGLE${action.payload.credential.idToken}`)

    //   const found = state.admins.find(element => element.email === action.payload.user.email)

    //   // console.table(action.payload)

    //   if (found) {
    //     return {
    //       ...state,
    //       email: action.payload.user.email,
    //       token: action.payload.credential.idToken, // accessToken,
    //       refresh_token: action.payload.user.refreshToken,
    //       issued_at: new Date(Date.now()),
    //       expires_at: new Date(new Date(Date.now()).getTime() + 50 * 60000),
    //       authError: null,
    //     }
    //   }
    //   return {
    //     ...state,
    //     email: action.payload.email,
    //     token: action.payload.token,
    //     refresh_token: action.payload.refreshToken,
    //     issued_at: null,
    //     expires_at: null,
    //     authError: null,
    //   }

    case 'SIGN_OUT_SUCCESS':
      // console.log(`SIGN_OUT_SUCCESS${action.payload.email}`)

      return {
        ...state,
        // email: action.payload.email,
        // token: action.payload.token,
        // refresh_token: action.payload.refreshToken,
        email: null,
        token: null,
        refresh_token: null,
        issued_at: null,
        expires_at: null,
        authError: null,
      }
    case authActionTypes.FETCH_ADMINS_SUCCESS:
      return {
        ...state,
        admins: action.payload.data,
      }
    default:
      return state
  }
}
