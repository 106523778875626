// @flow
import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { ROUTES } from '../../../constants'
import styles from '../../../assets/jss/sar/completedStyle'

const useStyles = makeStyles(styles)

type Props = {
  url: string,
  id: number,
  title: string,
  visibility: boolean,
}

export const Album = (props: Props) => {
  const classes = useStyles()

  const { url, id, title, visibility } = props

  return visibility ? (
    <Grid item md={4} sm={6} xs={11} className={`${classes.imgBox}`}>
      <div className={`${classes.imgCrop} ${classes.imgRounded} ${classes.imgContainer}`}>
        <Link to={ROUTES.PHOTOALBUM + id}>
          <img
            src={url} // zde pouze nahled
            alt={title}
            className={`${classes.imgRaised} ${classes.imgFluid} ${classes.imgZoom}`}
          />
        </Link>
        <h4 className={classes.overlay}>{title}</h4>
      </div>
    </Grid>
  ) : (
    ' '
  )
}
