// @flow
import messages from '../../i18n/messages'
import { Email, PhoneNumber } from '../../utils'

const validate = (values: Object) => {
  const result: Object = {}

  if (!values.akce || values.akce === '0') {
    result.akce = messages.required
  }
  if (!values.program || values.program === '0') {
    result.program = messages.required
  }
  if (!values.odjezd || values.odjezd === '0') {
    result.odjezd = messages.required
  }
  if (!values.prijezd || values.prijezd === '0') {
    result.prijezd = messages.required
  }
  if (!values.jmeno) {
    result.jmeno = messages.required
  }
  if (!values.prijmeni) {
    result.prijmeni = messages.required
  }
  if (!values.rodneCislo) {
    result.rodneCislo = messages.required
  }
  if (!values.ulice) {
    result.ulice = messages.required
  }
  if (!values.cp) {
    result.cp = messages.required
  }
  if (!values.mesto) {
    result.mesto = messages.required
  }
  if (!values.psc) {
    result.psc = messages.required
  }
  if (!values.zastupceJmeno) {
    result.zastupceJmeno = messages.required
  }
  if (!values.email) {
    result.email = messages.required
  }
  if (values.email && !Email.validate(values.email)) {
    // errors.person.businessman.businessmanInformation.contactEmail = messages.invalidEmailAddress
    result.email = messages.validateEmail
  }
  if (!values.telefon1) {
    result.telefon1 = messages.required
  }
  if (values.telefon1 && !PhoneNumber.validateCzechNumber(values.telefon1)) {
    result.telefon1 = messages.validatePhone
  }

  if (!values.souhlas) {
    result.souhlas = messages.required
  }
  if (!values.registrationType) {
    result.registrationType = messages.required
  }

  return result
}

export default validate
