// @flow
import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import Grid from '@material-ui/core/Grid'
import styles from '../../../assets/jss/sar/completedStyle'
import { ROUTES } from '../../../constants'

const useStyles = makeStyles(styles)

type Props = {
  image: string,
  id: number,
  title: string,
  subtitle: string,
  active: boolean,
  handleVisibility: any,
  increasePriority: any,
  decreasePriority: any,
}

function AdminNewsItem(props: Props) {
  const classes = useStyles()

  const {
    image,
    id,
    title,
    subtitle,
    active,
    handleVisibility,
    increasePriority,
    decreasePriority,
  } = props

  return (
    //     <Grid container className={classes.newsItem} justify="center">

    //   <Grid item xs={6} sm={4} md={3} lg={2}>
    //     <img src={image} alt="icon" className={`${classes.imgRounded} ${classes.imgFluid}`} />
    //   </Grid>
    //   <Grid item xs={11} sm={7} md={8} lg={9} className={`${classes.margin10Left}`}>
    //     <h3>
    //       <Link to={ROUTES.NEWS_DETAIL + id}>
    //         <button type="button" className={classes.linkButton}>
    //           {title}
    //         </button>
    //       </Link>
    //     </h3>
    //     <div>
    //       <div className={`${classes.defaultFontStyle} ${classes.mutedText}`}>{subtitle}</div>
    //     </div>
    //   </Grid>
    // </Grid>

    <Grid container className={classes.newsItem} justify="center">
      <Grid item md={10}>
        <Grid container>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <img src={image} alt="..." className={`${classes.imgRounded} ${classes.imgFluid}`} />
          </Grid>
          <Grid
            item
            xs={2}
            sm={1}
            md={1}
            className={`${classes.margin10Left} ${classes.adminNewsItemPrioButtons}`}>
            {id === 0 ? (
              ' '
            ) : (
              <div>
                <Button variant="outlined" onClick={increasePriority}>
                  <i className="fas fa-arrow-up" />
                </Button>
                {active ? (
                  <Button
                    variant="outlined"
                    onClick={handleVisibility}
                    className={classes.margin10Top}>
                    <i className="fas fa-check" />
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={handleVisibility}
                    className={classes.margin10Top}>
                    <i className="fas fa-times" />
                  </Button>
                )}
                <Button
                  variant="outlined"
                  onClick={decreasePriority}
                  className={classes.margin10Top}>
                  <i className="fas fa-arrow-down" />
                </Button>
              </div>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={5}
            md={7}
            className={`${classes.margin10Left} ${classes.adminNewsItemMargin}`}>
            <Link to={ROUTES.ADMIN_NEWS_DETAIL + id}>
              <h3>
                <button type="button" className={classes.linkButton}>
                  {title}
                </button>
              </h3>
            </Link>
            <div>
              {' '}
              {/* <Muted>{subtitle}</Muted> */}
              <div className={`${classes.defaultFontStyle} ${classes.mutedText}`}>{subtitle}</div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AdminNewsItem
