// @flow
import { handleActions } from 'redux-actions'
import { imagesActionTypes } from './actions'

import type { FilesStateT, SendFilesActionT, setLoadingStatusActionT } from './types'

export const imagesInitialState = (): FilesStateT => ({
  images: [],
})

const reduceFetchImagesSuccess = (state: FilesStateT, action: SendFilesActionT) => {
  console.log(action.payload)
  return {
    ...state,
    images: action.payload.data.files,
  }
}

const reduceSetLoadingStatus = (state: FilesStateT, action: setLoadingStatusActionT) => {
  return {
    ...state,
    loadingStatus: action.payload,
  }
}

const reduceDeleteImageSuccess = (state: FilesStateT, action: SendFilesActionT) => {
  return {
    ...state,
    images: state.images.filter(file => file.file !== action.payload),
  }
}

const reduceAddImageSuccess = (state: FilesStateT, action: SendFilesActionT) => {
  return {
    ...state,
    // documents: state.documents.push(action.payload.data),
    images: [...state.images, action.payload.data],
  }
}

// const reduceDeleteDocumentSuccess = (state: FilesStateT, action: SendFilesActionT) => {
//   return {
//     ...state,
//     documents: state.documents.filter(file => file.file !== action.payload),
//   }
// }

// const reduceAddDocumentSuccess = (state: FilesStateT, action: SendFilesActionT) => {
//   navigator.clipboard.writeText(action.payload.data.path)

//   return {
//     ...state,
//     // documents: state.documents.push(action.payload.data),
//     documents: [...state.documents, action.payload.data],
//   }
// }

export const imagesReducer = handleActions<FilesStateT, SendFilesActionT>(
  {
    [imagesActionTypes.FETCH_IMAGES_SUCCESS]: reduceFetchImagesSuccess,
    [imagesActionTypes.SET_IMAGES_LOADING_STATUS]: reduceSetLoadingStatus,
    [imagesActionTypes.DELETE_IMAGE_SUCCESS]: reduceDeleteImageSuccess,
    [imagesActionTypes.ADD_IMAGE_SUCCESS]: reduceAddImageSuccess,
  },
  imagesInitialState()
)
