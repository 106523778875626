// @flow
export const keys = {
  apiUrl: 'apiUrl',
  externalApplicationUrl: 'externalApplicationUrl',
  environment: 'environment',
  timeout: 'timeout',
  tokenExpirationTolerance: 'tokenExpirationTolerance',
  automaticLogoutTimeout: 'automaticLogoutTimeout',
  logoutModalConfirmationTimeout: 'logoutModalConfirmationTimeout',
  applicationListResetInterval: 'applicationListResetInterval',
  allowSigningInPostCheckingPhase: 'allowSigningInPostCheckingPhase',
}
