// @flow
import { createAction } from 'redux-actions'
import { intl, messages } from '../../i18n'

import { copyKeysToValues } from '../../utils/object'
import Endpoints from '../../constants/endpoints'

export const registrationActionTypes = {
  DATA_SEND: '',
  DATA_FETCH: '',
  CREATE_REGISTRATION: '',
  CREATE_REGISTRATION_SUCCESS: '',
  UPDATE_REGISTRATION_LOADING_STATUS: '',
  GET_ACTIONS: '',
  GET_ACTIONS_LOADING_STATUS: '',
  GET_ACTIONS_SUCCESS: '',
  GET_PROGRAMS: '',
  GET_PROGRAMS_SUCCESS: '',
  GET_PROGRAMS_LOADING_STATUS: '',
  GET_PLACES: '',
  GET_PLACES_SUCCESS: '',
  GET_PLACES_LOADING_STATUS: '',
}

copyKeysToValues(registrationActionTypes)

/// //////////

export const createRegistration = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  registrationActionTypes.DATA_SEND,
  null,
  dataRegistration => {
    return {
      url: Endpoints.sendRegistration(),
      actionSuccess: createRegistrationSuccess,
      actionSuccessMessage: intl.formatMessage(messages.successfullySent),
      actionSetLoadingStatus: setUpdateLoadingStatusRegistration,
      params: dataRegistration,
    }
  }
)

export const createRegistrationSuccess = createAction<ActionTypesT, empty>(
  registrationActionTypes.CREATE_REGISTRATION_SUCCESS
)

export const setUpdateLoadingStatusRegistration = createAction<ActionTypesT, empty, LoadingStatusT>(
  registrationActionTypes.UPDATE_REGISTRATION_LOADING_STATUS
)

/// //////////

export const getActions = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  registrationActionTypes.DATA_FETCH,
  null,
  () => {
    return {
      url: Endpoints.getActions(),
      actionSuccess: payload => getActionsSuccess(payload.data),
      actionSetLoadingStatus: setLoadingStatusGetActions,
    }
  }
)

export const getActionsSuccess = createAction<ActionTypesT, empty>(
  registrationActionTypes.GET_ACTIONS_SUCCESS
)

export const setLoadingStatusGetActions = createAction<ActionTypesT, empty, LoadingStatusT>(
  registrationActionTypes.GET_ACTIONS_LOADING_STATUS
)

/// //////////

export const getPrograms = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  registrationActionTypes.DATA_FETCH,
  null,
  payload => {
    return {
      url: Endpoints.getPrograms(payload),
      actionSuccess: payload => getProgramsSuccess(payload.data),
      actionSetLoadingStatus: setLoadingStatusGetPrograms,
    }
  }
)

export const getProgramsSuccess = createAction<ActionTypesT, empty>(
  registrationActionTypes.GET_PROGRAMS_SUCCESS
)

export const setLoadingStatusGetPrograms = createAction<ActionTypesT, empty, LoadingStatusT>(
  registrationActionTypes.GET_PROGRAMS_LOADING_STATUS
)

/// //////////

export const getPlaces = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  registrationActionTypes.DATA_FETCH,
  null,
  payload => {
    return {
      url: Endpoints.getPlaces(payload),
      actionSuccess: payload => getPlacesSuccess(payload.data),
      actionSetLoadingStatus: setLoadingStatusGetPlaces,
    }
  }
)

export const getPlacesSuccess = createAction<ActionTypesT, empty>(
  registrationActionTypes.GET_PLACES_SUCCESS
)

export const setLoadingStatusGetPlaces = createAction<ActionTypesT, empty, LoadingStatusT>(
  registrationActionTypes.GET_PLACES_LOADING_STATUS
)
