// @flow
import Immutable from 'seamless-immutable'

import type { ConfigState } from './types'
import { keys } from './constants'

export const configInitialState: Immutable<ConfigState> = Immutable({
  [keys.apiUrl]: '',
  [keys.environment]: 'development',
  [keys.timeout]: 120000,
  [keys.tokenExpirationTolerance]: 60000,
  [keys.automaticLogoutTimeout]: 300,
  [keys.logoutModalConfirmationTimeout]: 600,
  [keys.applicationListResetInterval]: 30,
  [keys.allowSigningInPostCheckingPhase]: true,
})
