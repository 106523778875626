// @flow
import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'
import Grid from '@material-ui/core/Grid'

import { PhotoSwipe } from 'react-photoswipe-2'
import CircularProgress from '@material-ui/core/CircularProgress'

import styles from '../../assets/jss/sar/completedStyle'

import 'react-photoswipe-2/lib/photoswipe.css'
import './style.css'

import { Photo } from './Photo'

const useStyles = makeStyles(styles)

const PageSize = 12

type Props = {
  albumInfo: string,
  images: object,
  title: string,
}

export const SarPhotos = (props: Props) => {
  const classes = useStyles()
  const { albumInfo, images, loading } = props

  const [page, setPage] = useState(1)
  const [photos, setPhotos] = useState(images)
  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState({
    index: 0,
    closeOnScroll: false,
  })

  const handleChange = (event, value) => {
    setPage(value)
    const max = value * PageSize
    const min = max - PageSize

    setPhotos(
      photos.map((el, index) =>
        index >= min && index < max
          ? { ...el, visibility: true, id: index }
          : { ...el, visibility: false, id: index }
      )
    )
  }

  useEffect(() => {
    const max = page * PageSize
    const min = max - PageSize

    setPhotos(
      images.map((el, index) =>
        index >= min && index < max
          ? { ...el, visibility: true, id: index }
          : { ...el, visibility: false, id: index }
      )
    )
  }, [images, page])

  const handleClose = () => {
    setIsOpen(false)
  }

  const openPhotoGallery = id => {
    setOptions({ ...options, index: id })
    setIsOpen(true)
  }

  /// ////

  return (
    <Grid item md={8} xs={12}>
      <div>
        <PhotoSwipe isOpen={isOpen} items={photos} options={options} onClose={handleClose} />
      </div>
      <Grid container justify="center">
        <Grid item md={12} className={classes.center}>
          <h1>{albumInfo.title}</h1>
        </Grid>
        {loading && (
          // <div className={`${classes.center} ${classes2.root}`}>
          <div className={`${classes.center} `}>
            <CircularProgress size={120} />
          </div>
        )}
        {photos.map(
          image =>
            image.visibility && (
              <Photo
                url={image.src}
                thumbnail={image.thumbnail}
                id={image.id}
                openPhotoGallery={openPhotoGallery}
                key={image.id}
              />
            )
        )}
        <Grid
          container
          item
          md={12}
          xs={12}
          className={classes.inputStyle}
          alignItems="center"
          justify="center">
          {images.length > 0 && (
            <Pagination
              count={Math.ceil(images.length / PageSize)}
              page={page}
              onChange={handleChange}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
