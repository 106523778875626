// @flow
import { createAction } from 'redux-actions'
import { copyKeysToValues } from '../../utils/object'
import type { LoadingStatusT } from '../types'
import type { FetchMetaDataT } from '../../sagas/types'

import Endpoints from '../../constants/endpoints'

export const newsActionTypes = {
  DATA_FETCH: '',
  DATA_SEND: '',
  SET_NEWS_LOADING_STATUS: '',
  FETCH_NEWS_SUCCESS: '',
  FETCH_DETAIL_NEWS: '',
  FETCH_DETAIL_NEWS_SUCCESS: '',
  SET_NEWS_DETAIL_LOADING_STATUS: '',
  FETCH_DETAIL_NEWS_FAILURE: '',
  UPDATE_NEWS: '',
  UPDATE_ADMIN_NEWS: '',
  UPDATE_NEWS_SUCCESS: '',
  SET_UPDATE_NEWS_LOADING_STATUS: '',
  UPDATE_VISIBILITY_NEWS: '',
  UPDATE_VISIBILITY_NEWS_SUCCESS: '',
  SET_UPDATE_VISIBILITY_NEWS_LOADING_STATUS: '',
  UPDATE_PRIORITY_NEWS: '',
  UPDATE_PRIORITY_NEWS_SUCCESS: '',
  SET_UPDATE_PRIORITY_NEWS_LOADING_STATUS: '',
  INSERT_NEWS: '',
  INSERT_NEWS_SUCCESS: '',
  SET_INSERT_NEWS_LOADING_STATUS: '',
  CLEAR_ERROR_ADMIN: '',
  ARCHIVE_NEWS_SUCCESS: '',
}

copyKeysToValues(newsActionTypes)

type ActionTypesT = Keys<typeof newsActionTypes>

export const fetchNews = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  newsActionTypes.DATA_FETCH,
  null,
  () => ({
    url: Endpoints.newsData(), // '/SAR/news/getNEWS.php', // todo - doresit failure
    actionSuccess: fetchNewsSuccess,
    actionSetLoadingStatus: setLoadingStatusNews,
    //   actionSuccess: setCodebooksIdentityDocuments,
    // actionSetLoadingStatus: setLoadingStatusIdentityDocuments,
  })
)

export const fetchNewsSuccess = createAction<ActionTypesT, empty>(
  newsActionTypes.FETCH_NEWS_SUCCESS
)
export const setLoadingStatusNews = createAction<ActionTypesT, empty, LoadingStatusT>(
  newsActionTypes.SET_NEWS_LOADING_STATUS
)

// ///////////////////////

export const fetchDetailNews = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  newsActionTypes.DATA_FETCH,
  null,
  (id: string) => ({
    url: Endpoints.newsDataById(id), // `/SAR/news/getNEWS.php?id=${id}`,
    actionSuccess: fetchDetailNewsSuccess,
    actionSetLoadingStatus: setLoadingStatusNewsDetail,
    //   actionSuccess: setCodebooksIdentityDocuments,
    // actionSetLoadingStatus: setLoadingStatusIdentityDocuments,
  })
)
export const fetchDetailNewsSuccess = createAction<ActionTypesT, empty>(
  newsActionTypes.FETCH_DETAIL_NEWS_SUCCESS
)
export const setLoadingStatusNewsDetail = createAction<ActionTypesT, empty, LoadingStatusT>(
  newsActionTypes.SET_NEWS_DETAIL_LOADING_STATUS
)

export const updateNews = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  newsActionTypes.DATA_SEND,
  null,
  (id: string, dataNews) => {
    // console.log('xxx')

    // console.log(dataNews)

    return {
      url: Endpoints.updateNewsData(id),
      actionSuccess: updateNewsSuccess,
      actionSetLoadingStatus: setUpdateLoadingStatusNews,
      actionSuccessMessage: 'Aktualita upravena',
      params: dataNews,
      //   actionSuccess: setCodebooksIdentityDocuments,
      // actionSetLoadingStatus: setLoadingStatusIdentityDocuments,
    }
  }
)

export const updateNewsSuccess = createAction<ActionTypesT, empty>(
  newsActionTypes.UPDATE_NEWS_SUCCESS
)
export const setUpdateLoadingStatusNews = createAction<ActionTypesT, empty, LoadingStatusT>(
  newsActionTypes.SET_UPDATE_NEWS_LOADING_STATUS
)

export const updateVisibilityNews = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  newsActionTypes.DATA_SEND,
  null,
  (id: string, visibility: string) => {
    // console.log('xxx')

    // console.log(dataNews)

    return {
      url: Endpoints.updateNewsVisibility(id, visibility),
      actionSuccess: updateVisibilityNewsSuccess,
      actionSetLoadingStatus: setUpdateVisibilityLoadingStatusNews,
      //   actionSuccess: setCodebooksIdentityDocuments,
      // actionSetLoadingStatus: setLoadingStatusIdentityDocuments,
    }
  }
)

export const updateVisibilityNewsSuccess = createAction<ActionTypesT, empty>(
  newsActionTypes.UPDATE_VISIBILITY_NEWS_SUCCESS
)
export const setUpdateVisibilityLoadingStatusNews = createAction<
  ActionTypesT,
  empty,
  LoadingStatusT
>(newsActionTypes.SET_UPDATE_VISIBILITY_NEWS_LOADING_STATUS)

export const updatePriorityNews = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  newsActionTypes.DATA_SEND,
  null,
  (id: string, increase: string) => {
    return {
      url: Endpoints.updateNewsPriority(id, increase),
      actionSuccess: updatePriorityNewsSuccess,
      actionSetLoadingStatus: setUpdatePriorityLoadingStatusNews,
    }
  }
)

export const updatePriorityNewsSuccess = createAction<ActionTypesT, empty>(
  newsActionTypes.UPDATE_PRIORITY_NEWS_SUCCESS
)
export const setUpdatePriorityLoadingStatusNews = createAction<ActionTypesT, empty, LoadingStatusT>(
  newsActionTypes.SET_UPDATE_PRIORITY_NEWS_LOADING_STATUS
)
// export const fetchContract = createAction<ContractActionTypesT, empty, Object, FetchMetaDataT>(
//   ContractActionTypes.DATA_FETCH,
//   null,
//   (id: string) => ({
//     url: `applications/${id}`,
//     actionSuccess: setContract,
//     actionSetLoadingStatus: setContractLoadingStatus,
//   })
// )

// export const fetchDetailNews2 = createAction<ActionTypesT, empty>(newsActionTypes.FETCH_DETAIL_NEWS)

// export const fetchDetailNewsSuccess = createAction<ActionTypesT, empty>(
//   newsActionTypes.FETCH_DETAIL_NEWS_SUCCESS
// )
export const fetchDetailNewsFailure = createAction<ActionTypesT, empty>(
  newsActionTypes.FETCH_DETAIL_NEWS_FAILURE
)
// export const updateNews = createAction<ActionTypesT, empty>(newsActionTypes.UPDATE_NEWS)
export const updateAdminNews = createAction<ActionTypesT, empty>(newsActionTypes.UPDATE_ADMIN_NEWS)

// export const updateNewsSuccess = createAction<ActionTypesT, empty>(
//   newsActionTypes.UPDATE_NEWS_SUCCESS
// )
// export const updateNewsFailure = createAction<ActionTypesT, empty>(
//   newsActionTypes.UPDATE_NEWS_FAILURE
// )

/// //////////

// export const insertNews = createAction<ActionTypesT, empty>(newsActionTypes.INSERT_NEWS)

// export const insertNewsSuccess = createAction<ActionTypesT, empty>(
//   newsActionTypes.INSERT_NEWS_SUCCESS
// )
// export const insertNewsFailure = createAction<ActionTypesT, empty>(
//   newsActionTypes.INSERT_NEWS_FAILURE
// )

/// //////////

export const insertNews = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  newsActionTypes.DATA_SEND,
  null,
  dataNews => {
    // console.log('xxx')

    // console.log(dataNews)

    return {
      url: Endpoints.insertNewsData(),
      actionSuccess: insertNewsSuccess,
      actionSetLoadingStatus: setInsertLoadingStatusNews,
      actionSuccessMessage: 'Aktualita uložena',
      params: dataNews,
      //   actionSuccess: setCodebooksIdentityDocuments,
      // actionSetLoadingStatus: setLoadingStatusIdentityDocuments,
    }
  }
)

export const insertNewsSuccess = createAction<ActionTypesT, empty>(
  newsActionTypes.INSERT_NEWS_SUCCESS
)
export const setInsertLoadingStatusNews = createAction<ActionTypesT, empty, LoadingStatusT>(
  newsActionTypes.SET_INSERT_NEWS_LOADING_STATUS
)

/// ///////

export const clearErrorAdmin = createAction<ActionTypesT, empty>(newsActionTypes.CLEAR_ERROR_ADMIN)

/// //////////

export const archiveNews = createAction<ActionTypesT, empty, Object, FetchMetaDataT>(
  newsActionTypes.DATA_SEND,
  null,
  (id: string) => {
    return {
      url: Endpoints.archiveNews(id),
      actionSuccess: archiveNewsSuccess,
      actionSuccessMessage: 'Aktualita smazána',
      actionSetLoadingStatus: setLoadingStatusNews,
    }
  }
)

export const archiveNewsSuccess = createAction<ActionTypesT, empty>(
  newsActionTypes.ARCHIVE_NEWS_SUCCESS
)
