// @flow
/* eslint-disable import/no-mutable-exports */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import { Field, reduxForm, initialize, change } from 'redux-form'
import GooglePicker from 'react-google-picker'
import { warning, success } from 'react-toastify-redux'
import { FormattedMessage } from 'react-intl'
import { connect, useDispatch, useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import DescriptionIcon from '@material-ui/icons/Description'
import AddIcon from '@material-ui/icons/Add'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'

import message from '../../i18n/messages'
import { messages, intl } from '../../i18n'
import { Forms } from '../../constants'
import { config } from '../../constants/config'

import styles from '../../assets/jss/sar/completedStyle'

import Footer from '../../components/Footer/Footer'
import { SarNewsArticle } from '../../components/SarNewsArticle'
import { SarMultiTextField } from '../../components/SarMultiTextField'
import { SarEditor } from '../../components/SarEditor'
import { SarCheckBox } from '../../components/SarCheckBox'
import { SarTextField } from '../../components/SarTextField'
import Button from '../../components/CustomButtons/Button'
import { SarTitle } from '../../components/SarTitle'
import { SarAdminHeaderImage } from '../../components/SarAdminHeaderImage'

import AdminNewsSubmit from './AdminNewsSubmit'
import validate from './validate'

import { fetchDetailNews, getNewsDetail, getNewsformValues, archiveNews } from '../../store/news'
import { fetchDocuments, getDocuments, deleteDocument, addDocument } from '../../store/files'
import { addImage, fetchImages, getImages, deleteImage } from '../../store/images'

const useStyles = makeStyles(styles)

type Props = {
  match: object,
}

let AdminNewsDetail = (props: Props) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const { match } = props

  const newsDetail = useSelector(getNewsDetail)
  const newsDetailForm = useSelector(getNewsformValues)
  const documents = useSelector(getDocuments)
  const images = useSelector(getImages)

  const [openFiles, setOpenFiles] = React.useState(false)
  const [url, setUrl] = React.useState(null)
  const [openImages, setOpenImages] = React.useState(false)
  const [openDeleteNews, setOpenDeleteNews] = React.useState(false)

  const handleClickOpenServerFiles = () => {
    setOpenFiles(true)
  }

  const handleClickOpenServerImages = () => {
    setOpenImages(true)
  }

  const handleCloseFiles = () => {
    setOpenFiles(false)
  }

  const handleCloseImages = () => {
    setOpenImages(false)
  }

  const handleListDocumentClick = value => {
    dispatch(success(`${intl.formatMessage(messages.urlCopied)}  - ${value}`))

    setUrl(value)

    navigator.clipboard.writeText(value)
    handleCloseFiles(value)
  }

  const handleDeleteDocumentClick = value => {
    dispatch(deleteDocument(value))
    handleCloseFiles(value)
  }

  const handleListImageClick = value => {
    dispatch(change(Forms.newsForm, 'img', value))
    handleCloseImages(value)
  }

  const handleDeleteImageClick = value => {
    dispatch(deleteImage(value))
    handleCloseImages(value)
  }

  const handleInputDocument = event => {
    const data = new FormData()

    data.append('file', event.target.files[0])

    dispatch(addDocument(data))

    handleCloseFiles()
  }

  useEffect(() => {
    dispatch(fetchDetailNews(match.params.newsId))
  }, [dispatch, match.params.newsId])

  useEffect(() => {
    dispatch(fetchDocuments())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchImages())
  }, [dispatch])

  useEffect(() => {
    dispatch(initialize(Forms.newsForm, newsDetail)) // TODO - doresit vsechny atributy a pak lze asi i odebrat   enableReinitialize: true,
  }, [dispatch, newsDetail])

  const onSelectFile = data => {
    if (data.action === 'picked') {
      if (!data.docs[0].isShared) {
        dispatch(warning(intl.formatMessage(messages.notShared)))
      }
      dispatch(success(`${intl.formatMessage(messages.urlCopied)} - ${data.docs[0].url}`))

      navigator.clipboard.writeText(data.docs[0].url)
    }
  }

  const handleChangePhotoNews = event => {
    const data = new FormData()

    data.append('file', event.target.files[0])

    dispatch(addImage(data))

    handleCloseImages()
  }

  const handleCloseDeleteNews = () => {
    setOpenDeleteNews(false)
  }

  const handleClickDeleteNews = () => {
    setOpenDeleteNews(true)
  }

  const confirmDeleteNews = () => {
    dispatch(archiveNews(match.params.newsId))
    setOpenDeleteNews(false)
  }

  return (
    <div>
      <SarAdminHeaderImage />

      <div className={classes.raisedMainAdmin}>
        <form>
          <Grid container justify="center">
            <Grid item md={8} xs={11}>
              <Grid container>
                <SarTitle title={intl.formatMessage(messages.news)} type="h3" />
              </Grid>

              <Grid container justify="center" spacing={2}>
                <Grid item md={12} xs={12}>
                  <Field
                    name="title"
                    component={SarTextField}
                    type="text"
                    label={intl.formatMessage(messages.title)}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Field
                    name="subtitle"
                    component={SarMultiTextField}
                    type="text"
                    label={intl.formatMessage(messages.newsSubtitle)}
                  />
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Field
                    name="img"
                    component={SarTextField}
                    type="text"
                    label={intl.formatMessage(messages.newsImage)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid container>
                    <Grid item>
                      <Tooltip
                        classes={{ tooltip: classes.noMaxWidth }}
                        title={
                          <img src={newsDetailForm && newsDetailForm.img} alt="obr" width="300" />
                        }>
                        <Avatar className={classes.adminNewsImgIcon}>
                          <img
                            src={newsDetailForm && newsDetailForm.img}
                            alt="obr"
                            width="40"
                            height="40"
                          />
                        </Avatar>
                      </Tooltip>
                    </Grid>

                    <Grid item md={10} xs={6}>
                      <Button color="primary" onClick={handleClickOpenServerImages}>
                        <FormattedMessage {...message.newsChooseImage} />
                      </Button>
                      <span className={classes.adminNewsActive}>
                        <Field
                          name="active"
                          component={SarCheckBox}
                          className={classes.adminNewsActive}
                          label={intl.formatMessage(messages.active)}
                        />
                      </span>
                    </Grid>
                    {/* <Grid item md={5} xs={6}>
                      <Field
                        name="active"
                        component={SarCheckBox}
                        label={intl.formatMessage(messages.active)}
                      />
                    </Grid> */}
                  </Grid>
                </Grid>
                {/* <Grid item md={3} xs={6}>
                  <GooglePicker
                    clientId={config.GOOGLE_CLIENT_ID} // "217069772914-j31p92ljmjpg1g5rf9cv6tbmpchpego7.apps.googleusercontent.com"
                    developerKey={config.GOOGLE_DEVELOPER_KEY} // "AIzaSyCY6UVre4CTHNeXzX2FDEGNXzcd8R_HqGI"
                    scope={['https://www.googleapis.com/auth/drive.readonly']}
                    // onChange={data => console.log('on change:', data)}
                    onChange={onSelectFile}
                    // onAuthFailed={data => console.log('on auth failed:', data)}
                    multiselect={false}
                    navHidden
                    authImmediate={false}
                    mimeTypes={['image/png', 'image/jpeg', 'image/jpg']}
                    query=""
                    viewId="FOLDERS">
                    <Button color="primary">
                      <FormattedMessage {...message.googleDrive} />
                    </Button>
                  </GooglePicker>
                </Grid> */}
                <Grid item md={3} xs={6}>
                  <Button color="primary" onClick={handleClickOpenServerFiles}>
                    <FormattedMessage {...message.addFile} />
                  </Button>
                </Grid>
                <Grid item md={3} xs={6}>
                  {url}
                </Grid>
                <Dialog
                  onClose={handleCloseImages}
                  aria-labelledby="simple-dialog-title"
                  open={openImages}>
                  <DialogTitle id="simple-dialog-title">
                    <FormattedMessage {...message.newsChooseImage} />
                  </DialogTitle>
                  <List>
                    {images &&
                      images.map(file => (
                        <ListItem
                          button
                          onClick={() => handleListImageClick(file.path)}
                          key={file.file}>
                          <ListItemAvatar>
                            {file.mimetype.startsWith('image') ? (
                              <Tooltip
                                classes={{ tooltip: classes.noMaxWidth }}
                                title={<img src={file.path} alt="obr" width="300" />}>
                                <Avatar>
                                  <img src={file.path} alt="obr" width="40" height="40" />
                                </Avatar>
                              </Tooltip>
                            ) : (
                              <Avatar>
                                <DescriptionIcon />
                              </Avatar>
                            )}
                          </ListItemAvatar>
                          <ListItemText primary={file.file} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleDeleteImageClick(file.file)}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}

                    <ListItem autoFocus button>
                      <ListItemAvatar>
                        <Avatar>
                          <AddIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <label htmlFor="upload-file">
                        <input
                          style={{ display: 'none' }}
                          id="upload-file"
                          name="upload-file"
                          type="file"
                          onChange={handleChangePhotoNews}
                        />

                        <ListItemText primary={intl.formatMessage(messages.addFile)} />
                      </label>
                    </ListItem>
                  </List>
                </Dialog>

                <Dialog
                  onClose={handleCloseFiles}
                  aria-labelledby="simple-dialog-title"
                  open={openFiles}>
                  <DialogTitle id="simple-dialog-title">
                    <FormattedMessage {...message.addFile} />
                  </DialogTitle>
                  <List>
                    {documents &&
                      documents.map(file => (
                        <ListItem
                          button
                          onClick={() => handleListDocumentClick(file.path)}
                          key={file.file}>
                          <ListItemAvatar>
                            {file.mimetype.startsWith('image') ? (
                              <Tooltip
                                classes={{ tooltip: classes.noMaxWidth }}
                                title={<img src={file.path} alt="obr" width="300" />}>
                                <Avatar>
                                  <img src={file.path} alt="obr" width="40" height="40" />
                                </Avatar>
                              </Tooltip>
                            ) : (
                              <Avatar>
                                <DescriptionIcon />
                              </Avatar>
                            )}
                          </ListItemAvatar>
                          <ListItemText primary={file.file} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleDeleteDocumentClick(file.file)}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}

                    <ListItem autoFocus button>
                      <ListItemAvatar>
                        <Avatar>
                          <AddIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <label htmlFor="upload-file">
                        <input
                          style={{ display: 'none' }}
                          id="upload-file"
                          name="upload-file"
                          type="file"
                          onChange={handleInputDocument}
                        />

                        <ListItemText primary={intl.formatMessage(messages.addFile)} />
                      </label>
                    </ListItem>
                  </List>
                </Dialog>

                <Grid item md={6} xs={12} />

                <Grid item md={12} xs={12}>
                  <Field name="detail" component={SarEditor} modules={AdminNewsDetail.modules} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={8} xs={11}>
              <Grid container justify="flex-end">
                <Button color="danger" onClick={handleClickDeleteNews}>
                  <FormattedMessage {...message.newsDeleteNews} />
                </Button>
                <Dialog
                  open={openDeleteNews}
                  onClose={handleCloseDeleteNews}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description">
                  <DialogTitle id="alert-dialog-title">
                    <FormattedMessage {...message.newsConfirmDeleteNews} />
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <FormattedMessage {...message.newsConfirmDeleteNewsLong} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={confirmDeleteNews} color="primary">
                      <FormattedMessage {...message.yes} />
                    </Button>
                    <Button onClick={handleCloseDeleteNews} color="primary" autoFocus>
                      <FormattedMessage {...message.no} />
                    </Button>
                  </DialogActions>
                </Dialog>
                <AdminNewsSubmit {...props} />
              </Grid>
            </Grid>
          </Grid>
        </form>
        <hr />
        <br />
        <br />
        {newsDetailForm && (
          <SarNewsArticle title={newsDetailForm.title} detail={newsDetailForm.detail} />
        )}
      </div>

      <Footer />
    </div>
  )
}

AdminNewsDetail = reduxForm({
  form: Forms.newsForm, // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(AdminNewsDetail)

// nacteni initial hodnot pro redux form z redux store
export default AdminNewsDetail = connect(state => ({
  initialValues: state.newsDetail.newsDetail, // pull initial values from account reducer
}))(AdminNewsDetail)
