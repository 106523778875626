/* eslint-disable react/jsx-props-no-spreading */
// @flow
// libs
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFormSyncErrors } from 'redux-form'
import { FormattedMessage } from 'react-intl'

import { Forms } from '../../constants'
import message from '../../i18n/messages'

import Button from '../../components/CustomButtons/Button'

import { updateNews, insertNews } from '../../store/news'
import { getNewsformValues } from '../../store/news/selectors'

type AdminNewsSubmitProps = {
  touch: boolean,
  valid: boolean,
  match: Object,
}

const AdminNewsSubmit = (props: AdminNewsSubmitProps) => {
  const { touch, valid, match } = props

  const dispatch = useDispatch()
  const syncErrors = getFormSyncErrors(Forms.newsForm)
  const newsFormValues = useSelector(getNewsformValues)

  const saveAndSubmitRegistration = () => {
    if (valid) {
      if (match.params.newsId === '0') {
        dispatch(insertNews(newsFormValues))
      } else {
        dispatch(updateNews(match.params.newsId, newsFormValues))
      }
    } else {
      touch(...Object.keys(syncErrors))
    }
  }

  return (
    <Button color="success" onClick={saveAndSubmitRegistration}>
      <FormattedMessage {...message.save} />
    </Button>
  )
}

export default AdminNewsSubmit
