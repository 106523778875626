/* eslint-disable react/jsx-props-no-spreading */
// @flow
import * as React from 'react'

import messages from '../../../i18n/messages'
import { ROUTES } from '../../../constants'
import { Header } from '../../Header'

type Props = {
  children?: React.Node,
}

export default function Layout(props: Props) {
  const { children } = props

  const dropdownLinks = [
    {
      id: 1,
      link: ROUTES.CONDITIONS,
      message: { ...messages.conditionsPage },
    },
    {
      id: 2,
      link: ROUTES.FAQ_BIKE,
      message: { ...messages.faqBikePage },
    },
    {
      id: 3,
      link: ROUTES.FAQ_CAMP,
      message: { ...messages.faqCampPage },
    },
    {
      id: 4,
      link: ROUTES.FAQ_SKI,
      message: { ...messages.faqSkiPage },
    },
  ]

  const menuLinks = [
    {
      id: 5,
      link: ROUTES.RESERVATION,
      message: { ...messages.reservation },
    },
    {
      id: 6,
      link: ROUTES.PHOTOGALLERY,
      message: { ...messages.photogallery },
    },
  ]

  const socialLinks = [
    // {
    //   id: 7,
    //   link: { ...messages.twitterURL },
    //   message: { ...messages.twitterLabel },
    //   img: ' fab fa-twitter',
    // },
    {
      id: 8,
      link: { ...messages.fbURL },
      message: { ...messages.fbLabel },
      img: ' fab fa-facebook',
    },
    {
      id: 9,
      link: { ...messages.instaURL },
      message: { ...messages.instaLabel },
      img: ' fab fa-instagram',
    },
  ]

  return (
    <div>
      <Header
        dropdownLinks={dropdownLinks}
        menuLinks={menuLinks}
        socialLinks={socialLinks}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 200,
          color: 'white',
        }}
      />
      {children}
      {/* vykreslí komponentu podle switche který je uvnitř této komponenty (v souboru app.js) */}
    </div>
  )
}
