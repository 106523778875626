// @flow
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import Button from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'

import styles from '../../assets/jss/sar/completedStyle'

const useStyles = makeStyles(styles)

const useStyles2 = makeStyles(() => ({
  root: {
    position: 'absolute',
    left: '20%',
    right: '20%',
    bottom: '20%',
    top: '20%',
    margin: '10px',
    padding: '10px',
  },
  cardContent: {
    position: 'relative',
  },
}))

export default function SarFileUploader(props: Props) {
  const { handleInputChange, submit, files, deleteFile, disabled, uploaded } = props
  const classes = useStyles()
  const classes2 = useStyles2()

  return (
    <div>
      <Card className={classes.root} variant="outlined">
        <CardHeader title="Nahrávání fotografií" />
        <CardContent className={classes2.cardContent}>
          <div>
            <List style={{ maxHeight: '500px', overflow: 'auto' }}>
              {files.map(file => (
                <ListItem key={file.name}>
                  <ListItemAvatar>
                    <Tooltip
                      classes={{ tooltip: classes.noMaxWidth }}
                      title={<img src={URL.createObjectURL(file)} alt="obr" width="300" />}>
                      <Button className={classes.button}>
                        <Avatar>
                          <img src={URL.createObjectURL(file)} alt="obr" width="40" height="40" />
                        </Avatar>
                      </Button>
                    </Tooltip>
                  </ListItemAvatar>
                  <ListItemText primary={file.name} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteFile(file.name)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            {uploaded && (
              <div className={`${classes.center} ${classes2.root}`}>
                <CircularProgress size={120} />
              </div>
            )}
          </div>
        </CardContent>
        <CardActions className={classes.right}>
          <label htmlFor="upload-photo">
            <input
              style={{ display: 'none' }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              onChange={handleInputChange}
              multiple
              accept="image/png, image/jpeg, image/gif"
            />

            <Button color="default" variant="contained" component="span" disabled={disabled}>
              Vybrat fotografie
            </Button>
          </label>
          <Button
            color="primary"
            variant="contained"
            component="span"
            disabled={disabled}
            onClick={() => submit()}
            className={classes.right}>
            Nahrát na server
          </Button>{' '}
        </CardActions>
      </Card>
      <br />
    </div>
  )
}
