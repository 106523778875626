// @flow
import messages from '../../../i18n/messages'

const validate = (values: Object) => {
  const result: Object = {}

  // if (!values.akce || values.akce === '0') {
  //   result.akce = messages.required
  // }
  if (!values.title) {
    result.title = messages.required
  }

  if (!values.albumPhoto) {
    result.albumPhoto = messages.required
  }

  return result
}

export default validate
