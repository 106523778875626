// @flow
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

// import { getBeautifyLoadingStatuses } from '../../utils'
import styles from '../../assets/jss/sar/completedStyle'

import Footer from '../../components/Footer/Footer'
import { SarHeaderImage } from '../../components/SarHeaderImage'
import { NewsItem } from './NewsItem'

// import { fetchNews, getActiveNewsList, getNewsLoadingStatus } from '../../store/news'
import { fetchNews, getActiveNewsList } from '../../store/news'

const useStyles = makeStyles(styles)

export const Main = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const activeNews = useSelector(getActiveNewsList)
  // const newsLoadingStatus = useSelector(getNewsLoadingStatus)

  // const newsLoading = getBeautifyLoadingStatuses(newsLoadingStatus)

  useEffect(() => {
    dispatch(fetchNews())
  }, [dispatch])

  // if (!newsLoading.isSuccess || !newsLoading.isSuccess) {
  //   return null
  // }

  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <Grid container justify="center">
          <Grid item md={10} className={classes.newsList}>
            {activeNews.map(item => (
              <NewsItem
                image={item.img}
                title={item.title}
                subtitle={item.subtitle}
                detail={item.detail}
                id={item.id}
                key={item.id}
              />
            ))}
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  )
}
