// @flow
import type { ActionType } from 'redux-actions'
import { channel } from 'redux-saga'
import { call, put, take } from 'redux-saga/effects'

// helper generator function, that sends action to other saga to process
// and then blocks current saga until response is delivered (using temporary action channel)
export function* putAndWait(actionCreator: ActionType<*>): Generator<Object, Object, any> {
  const resultChannel = yield call(channel)
  const action = actionCreator({ resultChannel })
  yield put(action)
  return yield take(resultChannel)
}
