// @flow
import { handleActions } from 'redux-actions'
import { set } from 'lodash'

import history from '../../history'
import { ROUTES } from '../../constants'

import { updateAdminNews, updateNewsSuccess, clearErrorAdmin, newsActionTypes } from './actions'

import { imagesActionTypes } from '../images/actions'

import type { NewsStateT, setNewsActionT, setLoadingStatusActionT } from './types'

export const newsInitialState = (): NewsStateT => ({
  list: [
    {
      title: 'Studio aktivní rekreace',
      subtitle: 'aktuality - Studio aktivní rekreace',
      detail: 'aktuality - Studio aktivní rekreace',
      img: 'http://studioar.cz/img/dokumenty.svg',
      active: 'A',
      id: 0,
    },
  ],
  loadingStatus: null,
})

const reduceFetchNewsSuccess = (state: NewsStateT, action: setNewsActionT) => {
  const { data } = action.payload
  // console.log(state)

  return {
    ...state,
    list: data,
  }
}

const reduceSetLoadingStatus = (state: NewsStateT, action: setLoadingStatusActionT) => {
  return {
    ...state,
    loadingStatus: action.payload,
  }
}

// const reduceUpdateVisibilityNewsSuccess = (state: NewsStateT, action: setNewsActionT) => {
//   const { data } = action.payload
//   return {
//     ...state,
//     newsDetail: data,
//   }
// }

const reduceUpdateVisibilityNewsSuccess = (state: NewsStateT, action: setNewsActionT) => {
  // console.log(state)

  // console.log(set(state, 'list[1].active', true))
  const { data } = action.payload

  // console.log('reduceVisibilityNews')
  // console.log(action.payload)
  // console.log(state)

  const index = state.list.findIndex(element => element.id === data.id)

  // console.log(index)

  return {
    // ...set(state, 'checkStatuses.loadingStatus', getBeautifyLoadingStatuses(action.payload)),
    ...set(state, `list[${index}].active`, data.visibility),
    // ...state,
    // newsDetail: { newsDetail: { id: 1 } },
  }
}

const reduceUpdatePriorityNewsSuccess = (state: NewsStateT, action: setNewsActionT) => {
  // console.log(state)

  // console.log(set(state, 'list[1].active', true))
  const { data } = action.payload

  // console.log('reduceVisibilityNews')
  // console.log(action.payload)
  // console.log(data)

  const index = state.list.findIndex(element => element.id === data.id)
  const index2 = state.list.findIndex(element => element.id === data.id2)

  // console.log(index)

  // console.log(index2)

  // console.log(index)

  return {
    // ...set(state, 'checkStatuses.loadingStatus', getBeautifyLoadingStatuses(action.payload)),
    ...set(state, `list[${index}].priority`, data.priority),
    ...set(state, `list[${index2}].priority`, data.priority2),

    // ...state,
    // newsDetail: { newsDetail: { id: 1 } },
  }
}

const reduceArchiveNewsSuccess = (state: NewsStateT, action: setLoadingStatusActionT) => {
  history.push(`${ROUTES.ADMIN_NEWS}`)

  return {
    ...state,
  }
}

export const newsReducer = handleActions<NewsStateT, fetchNewsActionT>(
  {
    [newsActionTypes.FETCH_NEWS_SUCCESS]: reduceFetchNewsSuccess,
    [newsActionTypes.SET_NEWS_LOADING_STATUS]: reduceSetLoadingStatus,
    [newsActionTypes.UPDATE_VISIBILITY_NEWS_SUCCESS]: reduceUpdateVisibilityNewsSuccess,
    [newsActionTypes.UPDATE_PRIORITY_NEWS_SUCCESS]: reduceUpdatePriorityNewsSuccess,
    [newsActionTypes.ARCHIVE_NEWS_SUCCESS]: reduceArchiveNewsSuccess,
  },
  newsInitialState()
)

// ///////////////////
export const newsDetailInitialState = (): NewsStateT => ({
  newsDetail: {
    title: 'Studio aktivní rekreace',
    detail: 'Studio aktivní rekreace',
  },
  newsAdminDetail: {
    error: 'Studio aktivní rekreace',
  },
  loadingStatus: null,
})

const reduceFetchNewsDetailSuccess = (state: NewsStateT, action: setNewsActionT) => {
  const { data } = action.payload
  return {
    ...state,
    newsDetail: data,
  }
}

const reduceAddImageSuccess = (state: NewsStateT, action: setNewsActionT) => {
  const { data } = action.payload
  // console.log(data.path)
  return {
    ...state,
    newsDetail: {
      ...state.newsDetail,
      img: data.path,
    },
  }
}

const reduceInsertNewsSucess = (state: fetchAlbumsStateT, action: fetchAlbumsActionT) => {
  const { data } = action.payload
  // history.push(`${ROUTES.ADMIN_PHOTOALBUM}${data.data.id}`)
  history.push(ROUTES.ADMIN_NEWS_DETAIL + data.id)

  return {
    ...state,
    newsDetail: action.payload,
    newsAdminDetail: {
      error: 'no',
    },
  }
}

export const newsDetailReducer = handleActions<NewsStateT, fetchNewsActionT>(
  {
    [newsActionTypes.FETCH_DETAIL_NEWS_SUCCESS]: reduceFetchNewsDetailSuccess,
    [newsActionTypes.SET_NEWS_DETAIL_LOADING_STATUS]: reduceSetLoadingStatus,
    [imagesActionTypes.ADD_IMAGE_SUCCESS]: reduceAddImageSuccess,
    [newsActionTypes.INSERT_NEWS_SUCCESS]: reduceInsertNewsSucess,
    [updateAdminNews]: (state, action) => ({
      // newsDetail: action.payload
      newsDetail: action.payload,
      newsAdminDetail: state.newsAdminDetail,
    }),
    [updateNewsSuccess]: (state, action) => ({
      // newsDetail: action.payload
      newsDetail: action.payload.data,
      newsAdminDetail: {
        error: 'no',
      },
    }),
    // [updateNewsFailure]: atr => ({
    //   // newsDetail: {
    //   //   title: "err",
    //   //   detail: atr
    //   // }
    // }),
    [clearErrorAdmin]: (state, action) => ({
      // newsDetail: action.payload
      newsDetail: state.newsDetail,
      newsAdminDetail: {
        error: 'no',
      },
    }),
    // [insertNewsSuccess]: (state, action) => ({
    //   // newsDetail: action.payload
    //   // newsDetail: state.newsDetail,
    //   // newsAdminDetail: action.payload,
    //   newsDetail: action.payload,
    //   newsAdminDetail: {
    //     error: 'no',
    //   },
    // }),
    // [insertNewsFailure]: (state, action) => ({
    //   newsDetail: state.newsDetail,
    //   newsAdminDetail: {
    //     error: 'yes',
    //   },
    // }),
    // [newsActionTypes.UPDATE_VISIBILITY_NEWS_SUCCESS]: (id, viditelnost) => ({
    //   newsDetail: state.newsDetail,
    //   newsAdminDetail: {
    //     error: 'yes',
    //   },
    // }),
  },
  newsDetailInitialState()
)
