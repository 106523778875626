// @flow
/* eslint-disable no-underscore-dangle */
import { applyMiddleware, compose, combineReducers, createStore } from 'redux'
import { toastsReducer } from 'react-toastify-redux'

import { reducer as formReducer } from 'redux-form'
import createSagaMiddleware from 'redux-saga'
import { persistReducer, persistStore } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
// import { reducer as reduxFormReducer } from 'redux-form'

import type { StoreEnhancer } from 'redux'
// import { toastsReducer } from 'react-toastify-redux'

import { firebaseReducer } from 'react-redux-firebase'
import { newsReducer, newsDetailReducer } from './news'
import { participantsReducer } from './participants'
import { filesReducer } from './files'
import { imagesReducer } from './images'
import { photosReducer } from './photos'
import { getRegistrationsReducer } from './registration'
import { actionsReducer } from './actions'

import { authReducer } from './authorization'

import { configReducer } from './config'

// import { authReducer } from './auth'
// import { passwordReducer } from './auth/password/reducers'

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['auth'],
}

const appReducers = combineReducers({
  // form: reduxFormReducer,
  auth: authReducer,
  config: configReducer,
  news: newsReducer,
  newsDetail: newsDetailReducer,
  participants: participantsReducer,
  registration: getRegistrationsReducer,
  actions: actionsReducer,
  files: filesReducer,
  images: imagesReducer,
  photos: photosReducer,
  form: formReducer,
  // authorization: loginCheckReducer,
  toasts: toastsReducer,
  // firebase: firebaseReducer,
})

// const combinedReducers = (state, action) => {
//   // This will set default state to store on dispatched action CLEAR_STORE!
//   // const newState = action.type === LogoutActionTypes.CLEAR_STORE ? undefined : state
//   const newState = state;
//   return appReducers(newState, action);
// };

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose

// FIX: new version of flow types from flow-typed caused types mismatch for now it must be cast as any
const persistedReducer: any = persistReducer(persistConfig, appReducers)

export const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const middleware = [sagaMiddleware]

  const store: Object = createStore(
    persistedReducer,
    (composeEnhancers(applyMiddleware(...middleware)): StoreEnhancer<*, *, *>)
  )

  // sagaMiddleware is exposed because of our saga HMR
  store.sagaMiddleware = sagaMiddleware

  const persistor = persistStore(store)

  return [store, persistor]
}
