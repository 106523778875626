// @flow
export default {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  UNPROCESSABLE_ENTITY: 422,
  SERVICE_UNAVAILABLE: 503,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
}
