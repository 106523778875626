/* eslint-disable react/no-danger */
// @flow
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

// import styles2 from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";
import styles from '../../assets/jss/sar/completedStyle'

import 'react-quill/dist/quill.snow.css'

// import 'react-quill/dist/quill.core.css' // ES6

// import "https://unpkg.com/react-quill@1.0.0/dist/quill.core.css";

const useStyles = makeStyles(styles)

type Props = {
  title: string,
  detail: string,
}

function SarNewsArticle(props: Props) {
  const classes = useStyles()
  const { title, detail } = props

  return (
    <div>
      <Grid container justify="center">
        <Grid item md={11} className={classes.center}>
          <h1>{title}</h1>
        </Grid>
        {/* {props.detail} */}
        <Grid container justify="center">
          <Grid item>
            {/* <div dangerouslySetInnerHTML={{ __html: detail }} /> */}
            {/* <div className="ql-editor" style={{ padding: 0 }}> */}
            <div className="ql-editor" style={{ margin: '10px' }}>
              <div dangerouslySetInnerHTML={{ __html: detail }} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default SarNewsArticle
