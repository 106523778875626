// @flow
export const getAccessToken = state => state.auth.token

export const isSigned = state => {
  // if (state.auth.token && state.auth.expires_at) {
  //   const d1 = new Date()
  //   const d2 = new Date(state.auth.expires_at)
  //   console.log(d1)

  //   console.log('<')

  //   console.log(d2)

  //   return d1 < d2
  // }
  // return false

  if (state.auth.token) {
    return true
  }
  return false

  // let d2 = new Date(d1);
  // let same = d1.getTime() === d2.getTime();
  // let notSame = d1.getTime() !== d2.getTime();

  //   return !!(state.auth.token && state.auth.expires_at)
  //    return  state.auth.token && state.auth.expires_at ?  true :  false
}
