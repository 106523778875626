// @flow
import { handleActions } from 'redux-actions'

import { setEnvironment } from './actions'
import { configInitialState } from './model'
import { keys } from './constants'

export const configReducer = handleActions(
  {
    [setEnvironment]: (state, { payload: { config } }) =>
      state
        .set(keys.apiUrl, config.apiUrl)
        .set(keys.externalApplicationUrl, config.externalApplicationUrl)
        .set(keys.environment, config.environment)
        .set(keys.timeout, config.timeout)
        .set(keys.tokenExpirationTolerance, config.tokenExpirationTolerance)
        .set(keys.automaticLogoutTimeout, config.automaticLogoutTimeout)
        .set(keys.logoutModalConfirmationTimeout, config.logoutModalConfirmationTimeout)
        .set(keys.applicationListResetInterval, config.applicationListResetInterval)
        .set(keys.allowSigningInPostCheckingPhase, config.allowSigningInPostCheckingPhase),
  },
  configInitialState
)
