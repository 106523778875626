// @flow
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { FormattedMessage } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ToggleButton from '@material-ui/lab/ToggleButton'

import { Forms, ROUTES } from '../../constants'
import styles from '../../assets/jss/sar/completedStyle'
import { messages, intl } from '../../i18n'
import message from '../../i18n/messages'

import Footer from '../../components/Footer/Footer'
import { SarHeaderImage } from '../../components/SarHeaderImage'
import { SarSelectForm } from '../../components/SarSelectForm'
import { SarToggleButtonGroup } from '../../components/SarToggleButtonGroup'
import { SarTitle } from '../../components/SarTitle'
import { SarCheckBox } from '../../components/SarCheckBox'
import { SarTextField } from '../../components/SarTextField'
import { SarPhoneField } from '../../components/SarPhoneField'
import { SarBirthnumberField } from '../../components/SarBirthnumberField'
import { SarPscField } from '../../components/SarPscField'

import RegistrationSubmit from './RegistrationSubmit'
import validate from './validate'
import warnings from './warnings'

import {
  getActions,
  getPrograms,
  getPlaces,
  getRegistrationType,
} from '../../store/registration/index'

import {
  getActiveActionsList,
  getProgramsList,
  getPlacesList,
} from '../../store/registration/selectors'

type Props = {
  fetchDetailNews: (payload: ?{ name: string }) => void,
}

const useStyles = makeStyles(styles)

let Registration = (props: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const registrationType = useSelector(getRegistrationType)
  const actions = useSelector(getActiveActionsList)
  const programs = useSelector(getProgramsList)
  const places = useSelector(getPlacesList)

  useEffect(() => {
    dispatch(getActions())
  }, [dispatch])

  const onActionSelect = val => {
    dispatch(getPrograms(val.target.value))
    dispatch(getPlaces(val.target.value))
  }

  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <Grid container justify="center">
          <Grid item md={8} xs={11}>
            <Grid item md={12} className={classes.center}>
              <h1>
                <FormattedMessage {...message.reservation} />
              </h1>
            </Grid>
          </Grid>
        </Grid>

        <form>
          <Grid container justify="center">
            {/* akce */}
            <Grid item md={8} xs={11}>
              <Grid container>
                <SarTitle title={intl.formatMessage(messages.registrationAction)} type="h3" />
              </Grid>

              <Grid container justify="center" spacing={2}>
                <Grid item md={6} xs={12}>
                  <Field
                    name="akce"
                    component={SarSelectForm}
                    onChangeCombo={onActionSelect}
                    label={intl.formatMessage(messages.registrationAction)}
                    data={actions}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  {/* <SarComboBox label="program" /> */}
                  <Field
                    name="program"
                    component={SarSelectForm}
                    // onChangeCombo={this.onProgramSelect}
                    type="text"
                    label={intl.formatMessage(messages.registrationProgram)}
                    data={programs}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    name="odjezd"
                    component={SarSelectForm}
                    type="text"
                    label={intl.formatMessage(messages.registrationDeparture)}
                    data={places}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    name="prijezd"
                    component={SarSelectForm}
                    type="text"
                    label={intl.formatMessage(messages.registrationArrival)}
                    data={places}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* --------------------------- */}
            {/* ucastnik */}
            <Grid item md={8} xs={11}>
              <Grid container>
                <SarTitle title={intl.formatMessage(messages.registrationParticipant)} type="h3" />
              </Grid>

              <Grid container justify="center" spacing={2}>
                <Grid item md={4} xs={12}>
                  <Field
                    name="jmeno"
                    component={SarTextField}
                    type="text"
                    label={intl.formatMessage(messages.registrationName)}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Field
                    name="prijmeni"
                    component={SarTextField}
                    type="text"
                    label={intl.formatMessage(messages.registrationSurname)}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Field
                    name="rodneCislo"
                    component={SarBirthnumberField}
                    type="text"
                    label={intl.formatMessage(messages.registrationBirthnumber)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* --------------------------- */}
            {/* --------------------------- */}
            {/* adresa */}
            <Grid item md={8} xs={11}>
              <Grid container>
                <SarTitle title={intl.formatMessage(messages.registrationAddress)} type="h3" />
              </Grid>

              <Grid container justify="center" spacing={2}>
                <Grid item md={6} xs={12}>
                  <Field
                    name="ulice"
                    component={SarTextField}
                    type="text"
                    label={intl.formatMessage(messages.registrationStreet)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    name="cp"
                    component={SarTextField}
                    type="text"
                    label={intl.formatMessage(messages.registrationHouseNumber)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    name="mesto"
                    component={SarTextField}
                    type="text"
                    label={intl.formatMessage(messages.registrationTown)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    name="psc"
                    component={SarPscField}
                    type="text"
                    label={intl.formatMessage(messages.registrationPostCode)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* --------------------------- */}
            {/* zastupce */}
            <Grid item md={8} xs={11}>
              <Grid container>
                <SarTitle
                  title={intl.formatMessage(messages.registrationRepresentative)}
                  type="h3"
                />
              </Grid>

              <Grid container justify="center" spacing={2}>
                <Grid item md={6} xs={12}>
                  <Field
                    name="zastupceJmeno"
                    component={SarTextField}
                    type="text"
                    label={intl.formatMessage(messages.registrationRepresentativeName)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field name="email" component={SarTextField} type="text" label="Email" />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    name="telefon1"
                    component={SarPhoneField}
                    type="text"
                    label={intl.formatMessage(messages.registrationRepresentativePhone1)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    name="telefon2"
                    component={SarPhoneField}
                    type="text"
                    label={intl.formatMessage(messages.registrationRepresentativePhone2)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="poznamka"
                    component={SarTextField}
                    type="text"
                    label={intl.formatMessage(messages.registrationInfo)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="registrationType"
                    component={SarToggleButtonGroup}
                    // defaultValue="Prihlaska"
                  >
                    <ToggleButton value="Prihlaska">
                      <FormattedMessage {...message.registrationApplication} />
                    </ToggleButton>
                    <ToggleButton value="Rezervace">
                      <FormattedMessage {...message.registrationReservation} />
                    </ToggleButton>
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <p>
                    <FormattedMessage {...message.registrationAgreement1} />
                  </p>
                  <p>
                    <FormattedMessage {...message.registrationAgreement2} />
                  </p>
                  <p>
                    <FormattedMessage {...message.registrationAgreement3} />
                  </p>
                  {registrationType === 'Rezervace' ? (
                    <p>
                      <b>
                        {' '}
                        <FormattedMessage {...message.registrationReservationAgreement1} />
                      </b>
                      <FormattedMessage {...message.registrationReservationAgreement2} />
                    </p>
                  ) : null}
                  <Link to={ROUTES.CONDITIONS}>
                    <FormattedMessage {...message.conditionsPage} />
                  </Link>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="souhlas"
                    component={SarCheckBox}
                    label={intl.formatMessage(messages.registrationSubmitAgreement)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={8} xs={11}>
              <Grid container justify="flex-end">
                <RegistrationSubmit {...props} registrationType={registrationType} />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <Footer />
    </div>
  )
}

export default Registration = reduxForm({
  form: Forms.registrationForm, // a unique identifier for this form
  validate,
  warn: warnings,
  // enableReinitialize: true,
})(Registration)
