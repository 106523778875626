// @flow
import { createSelector } from 'reselect'

import type { State } from '../types'

export const getApiUrl = (state: State) => state.config.apiUrl

export const getApiUrlIsInitialized = createSelector(
  [getApiUrl],
  apiUrl => apiUrl !== ''
)

export const getConfig = (state: State): Object => state.config

export const getAllowSigningInPostCheckingPhase = (state: State): boolean =>
  state.config.allowSigningInPostCheckingPhase

export const getExternalApplicationUrl = (state: State) => state.config.externalApplicationUrl
