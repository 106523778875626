// @flow
import { call, put, actionChannel, take } from 'redux-saga/effects'
import { buffers } from 'redux-saga'

// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/auth'

import Limits from '../../constants/limits'

// Actions
import {
  signInSuccess,
  signOutSuccess,
  RefreshTokenActionTypes,
} from '../../store/authorization/actions'

// Types
import type { GeneratorState } from '../types'

export function* signInSaga(action) {
  try {
    const userData = {
      email: null,
      token: null,
      date: null,
      refreshToken: null,
    }

    const auth = firebase.auth()

    yield call(
      [auth, auth.signInWithEmailAndPassword],
      action.payload.email,
      action.payload.password
    )

    yield auth.currentUser.getIdToken().then(idToken => {
      userData.token = idToken
    })

    // TODO - uložit aktuální datum a čas a ten pak porovnávat (zda je user již dlouho přihlášen - refresh)
    userData.email = auth.currentUser.email
    userData.date = Date.now()
    userData.refreshToken = auth.currentUser.refreshToken

    yield put(signInSuccess(userData))
  } catch (error) {}
}

export function* signOutSaga(action) {
  try {
    const userData = {
      email: null,
      token: null,
      date: null,
      refreshToken: null,
    }

    const auth = firebase.auth()

    yield auth.signOut().then(() => {
      console.log('signed out')
    })

    yield put(signOutSuccess(userData))
  } catch (error) {}
}

function* performRefresh() {
  try {
    const userData = {
      email: null,
      token: null,
      date: null,
      refreshToken: null,
    }
    const auth = firebase.auth()

    yield auth.currentUser.getIdToken(true).then(idToken => {
      userData.token = idToken
    })

    userData.email = auth.currentUser.email
    userData.date = auth.currentUser.metadata.lastSignInTime
    userData.refreshToken = auth.currentUser.refreshToken

    yield put(signInSuccess(userData))
  } catch (error) {}
}

export function* refreshTokenSaga(): GeneratorState {
  // create channel for listening to `refresh_token` actions
  const requests = yield actionChannel(RefreshTokenActionTypes.REFRESH_TOKEN, buffers.fixed(100))

  // console.log('refresh')

  let lastRefreshTime

  // start daemon saga, that will infinitely wait for `refresh_token` actions in the background
  while (true) {
    // wait for the next request
    // console.log('refresh2')

    const {
      payload: { resultChannel },
    } = yield take(requests)

    // console.log('refresh2a')

    const now = Date.now()

    let backendCall = false

    // console.log(now)

    // perform token refresh only if enough time has elapsed since last call
    if (!lastRefreshTime || now - lastRefreshTime > Limits.refreshToken.BUFFER_WINDOW) {
      // console.log('refresh3')

      // call backend to refresh token
      backendCall = yield* performRefresh()

      // update time of last refresh
      lastRefreshTime = Date.now()
    }
    // console.log('refresh4')

    // return empty result (analogous to `void`) back to requester saga
    yield put(resultChannel, { status: backendCall })
  }
}
