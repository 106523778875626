/* eslint-disable react/jsx-props-no-spreading */
// @flow
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'
import Grid from '@material-ui/core/Grid'

import message from '../../i18n/messages'
import styles from '../../assets/jss/sar/completedStyle'

import { SarHeaderImage } from '../../components/SarHeaderImage'
import Footer from '../../components/Footer/Footer'
import { Album } from './Album'

import { fetchAlbums, getActiveAlbums } from '../../store/photos'

const useStyles = makeStyles(styles)

const PageSize = 9

type Props = {
  // match: { params: { newsId: number } },
}

export const Photogallery = (props: Props) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const albums = useSelector(getActiveAlbums)

  const [page, setPage] = useState(1)
  const [photos, setPhotos] = useState(albums)

  useEffect(() => {
    dispatch(fetchAlbums())
  }, [dispatch])

  useEffect(() => {
    const max = page * PageSize
    const min = max - PageSize

    setPhotos(
      albums.map((el, index) =>
        index >= min && index < max
          ? { ...el, visibility: true, id: el.id }
          : { ...el, visibility: false, id: el.id }
      )
    )
  }, [albums, page])

  const handleChange = (event, value) => {
    setPage(value)
    const max = value * PageSize
    const min = max - PageSize

    setPhotos(
      photos.map((el, index) =>
        index >= min && index < max
          ? { ...el, visibility: true, id: el.id }
          : { ...el, visibility: false, id: el.id }
      )
    )
  }
  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <Grid container justify="center">
          <Grid item md={8} xs={11}>
            <Grid item md={12} className={classes.center}>
              <h1>
                <FormattedMessage {...message.photogallery} />
              </h1>
            </Grid>

            <Grid container justify="center">
              {photos &&
                photos.map(image => (
                  <Album
                    url={image.img}
                    title={image.title}
                    id={image.id}
                    visibility={image.visibility}
                    key={image.id}
                  />
                ))}
              <Grid item md={4} xs={11} className={`${classes.imgBox}`} />
              <Grid
                container
                item
                md={12}
                xs={12}
                className={classes.inputStyle}
                alignItems="center"
                justify="center">
                <Pagination
                  count={Math.ceil(photos.length / PageSize)}
                  page={page}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </div>
  )
}
