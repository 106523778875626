// @flow
import { handleActions } from 'redux-actions'
import { filesActionTypes } from './actions'

import type { FilesStateT, SendFilesActionT, setLoadingStatusActionT } from './types'

export const filesInitialState = (): FilesStateT => ({
  files: [],
  documents: [],
  loadingStatus: null,
})

const reduceFilesLoaded = (state: FilesStateT, action: SendFilesActionT) => {
  return {
    ...state,
    files: action.payload,
  }
}

const reduceDeleteFileLoaded = (state: FilesStateT, action: SendFilesActionT) => {
  return {
    ...state,
    files: state.files.filter(file => file.name !== action.payload),
  }
}

const reduceSendFileSucess = (state: FilesStateT, action: SendFilesActionT) => {
  return {
    ...state,
    files: state.files.filter(file => file.name !== action.payload),
  }
}

const reduceFetchDocumentsSuccess = (state: FilesStateT, action: SendFilesActionT) => {
  return {
    ...state,
    documents: action.payload.data.files,
  }
}

const reduceSetLoadingStatus = (state: FilesStateT, action: setLoadingStatusActionT) => {
  return {
    ...state,
    loadingStatus: action.payload,
  }
}

const reduceDeleteDocumentSuccess = (state: FilesStateT, action: SendFilesActionT) => {
  return {
    ...state,
    documents: state.documents.filter(file => file.file !== action.payload),
  }
}

const reduceAddDocumentSuccess = (state: FilesStateT, action: SendFilesActionT) => {
  navigator.clipboard.writeText(action.payload.data.path)

  const stateDocuments = state.documents ? [...state.documents] : []

  return {
    ...state,
    // documents: state.documents.push(action.payload.data),
    documents: [...stateDocuments, action.payload.data],
  }
}

export const filesReducer = handleActions<FilesStateT, SendFilesActionT>(
  {
    [filesActionTypes.FILES_LOAD]: reduceFilesLoaded,
    [filesActionTypes.DELETE_LOADED_FILE]: reduceDeleteFileLoaded,
    [filesActionTypes.SEND_FILES_SUCCESS]: reduceSendFileSucess,
    [filesActionTypes.SET_PARTICIPANTS_LOADING_STATUS]: reduceSetLoadingStatus,
    [filesActionTypes.FETCH_DOCUMENTS_SUCCESS]: reduceFetchDocumentsSuccess,
    [filesActionTypes.DELETE_DOCUMENT_SUCCESS]: reduceDeleteDocumentSuccess,
    [filesActionTypes.ADD_DOCUMENT_SUCCESS]: reduceAddDocumentSuccess,
  },
  filesInitialState()
)
