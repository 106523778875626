// @flow
import { handleActions } from 'redux-actions'
import { actionsActionTypes } from './actions'

import type { ActionStateT, setActionsActionT, setLoadingStatusActionT } from './types'

export const actionsInitialState = (): ActionStateT => ({
  actions: [],
  transports: [],
  programs: [],
  actionPrograms: [],
  actionTransports: [],
  loadingStatus: null,
})

const reduceFetchActionsSuccess = (state: ActionsStateT, action: setActionsActionT) => {
  const { data } = action.payload
  return {
    ...state,
    actions: data,
  }
}

const reduceFetchTransportsSuccess = (state: ActionsStateT, action: setActionsActionT) => {
  const { data } = action.payload
  return {
    ...state,
    transports: data,
  }
}

const reduceFetchProgramsSuccess = (state: ActionsStateT, action: setActionsActionT) => {
  const { data } = action.payload
  return {
    ...state,
    programs: data,
  }
}

const reduceFetchActionProgramsSuccess = (state: ActionsStateT, action: setActionsActionT) => {
  const { data } = action.payload
  return {
    ...state,
    actionPrograms: data,
  }
}

const reduceFetchActionTransportsSuccess = (state: ActionsStateT, action: setActionsActionT) => {
  const { data } = action.payload
  return {
    ...state,
    actionTransports: data,
  }
}

const reduceSetLoadingStatus = (state: ActionStateT, action: setLoadingStatusActionT) => {
  return {
    ...state,
    loadingStatus: action.payload,
  }
}

const reduceUpdateActionsSuccess = (state: ActionStateT, action: setLoadingStatusActionT) => {
  const { data } = action.payload

  let isNew = true

  const actionsList = state.actions.map(value => {
    if (value.id === data.id) {
      isNew = false
      return {
        id: data.id,
        value: data.value,
        active: data.active,
      }
    }
    return value

    // return value.id === data.id ? { id: data.id, value: data.value, active: data.active } : value
  })

  if (isNew) {
    actionsList.push(data)
  }

  return {
    ...state,
    actions: actionsList,
    //  loadingStatus: action.payload,
  }
}

const reduceArchiveActionsSuccess = (state: ActionStateT, action: setLoadingStatusActionT) => {
  const { data } = action.payload

  const actionsList = state.actions.filter(value => value.id !== data.id)

  return {
    ...state,
    actions: actionsList,
    //  loadingStatus: action.payload,
  }
}

const reduceUpdateProgramSuccess = (state: ActionStateT, action: setLoadingStatusActionT) => {
  const { data } = action.payload
  let isNew = true

  const programsList = state.programs.map(value => {
    if (value.id === data.id) {
      isNew = false
      return {
        id: data.id,
        value: data.value,
        active: data.active,
      }
    }
    return value
  })

  if (isNew) {
    programsList.push(data)
  }
  return {
    ...state,
    programs: programsList,
    //  loadingStatus: action.payload,
  }
}

const reduceUpdateTransportSuccess = (state: ActionStateT, action: setLoadingStatusActionT) => {
  const { data } = action.payload
  let isNew = true

  const transportsList = state.transports.map(value => {
    if (value.id === data.id) {
      isNew = false
      return {
        id: data.id,
        value: data.value,
        active: data.active,
      }
    }
    return value
  })

  if (isNew) {
    transportsList.push(data)
  }

  return {
    ...state,
    transports: transportsList,
    //  loadingStatus: action.payload,
  }
}

const reduceUpdateActionDetailSuccess = (state: ActionStateT, action: setLoadingStatusActionT) => {
  // const { data } = action.payload

  return {
    ...state,
    // actionTransports: data.transports,
    // actionPrograms: data.programs,
  }
}

export const actionsReducer = handleActions<ActionStateT, setActionsActionT>(
  {
    [actionsActionTypes.FETCH_ACTIONS_SUCCESS]: reduceFetchActionsSuccess,
    [actionsActionTypes.FETCH_ACTIONS_LOADING_STATUS]: reduceSetLoadingStatus,
    [actionsActionTypes.FETCH_TRANSPORTS_SUCCESS]: reduceFetchTransportsSuccess,
    [actionsActionTypes.FETCH_TRANSPORTS_LOADING_STATUS]: reduceSetLoadingStatus,
    [actionsActionTypes.FETCH_PROGRAMS_SUCCESS]: reduceFetchProgramsSuccess,
    [actionsActionTypes.FETCH_PROGRAMS_LOADING_STATUS]: reduceSetLoadingStatus,
    [actionsActionTypes.FETCH_ACTION_PROGRAMS_SUCCESS]: reduceFetchActionProgramsSuccess,
    [actionsActionTypes.FETCH_ACTION_PROGRAMS_LOADING_STATUS]: reduceSetLoadingStatus,
    [actionsActionTypes.FETCH_ACTION_TRANSPORTS_SUCCESS]: reduceFetchActionTransportsSuccess,
    [actionsActionTypes.FETCH_ACTION_TRANSPORTS_LOADING_STATUS]: reduceSetLoadingStatus,
    [actionsActionTypes.UPDATE_ACTION_SUCCESS]: reduceUpdateActionsSuccess,
    [actionsActionTypes.ARCHIVE_ACTION_SUCCESS]: reduceArchiveActionsSuccess,
    [actionsActionTypes.UPDATE_PROGRAM_SUCCESS]: reduceUpdateProgramSuccess,
    [actionsActionTypes.UPDATE_TRANSPORT_SUCCESS]: reduceUpdateTransportSuccess,
    [actionsActionTypes.UPDATE_ACTION_DETAIL_SUCCESS]: reduceUpdateActionDetailSuccess,
  },
  actionsInitialState()
)
