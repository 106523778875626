// @flow
import { getFormValues } from 'redux-form'
// import { createSelector } from 'reselect'
import Forms from '../../constants/forms'

// import { customFormValueSelector } from '../forms'

export const getRegistrationFormValues = (state: State): ?Object =>
  getFormValues(Forms.registrationForm)(state)

export const getRegistrationType = (state: State): ?Object => {
  const value = getRegistrationFormValues(state)
    ? getRegistrationFormValues(state).registrationType
    : 'Prihlaska'
  return value
}

export const getActionsList = state => state.registration.actions

export const getRegistrationSuccessData = state => state.registration.registrationSuccessData

// export const getActiveActionsList = (state: State) =>
//   getActionsList(state)
//     .filter(item => item.active)
//     .map(item => {
//       return { id: item.id, value: item.title }
//     })

export const getActiveActionsList = (state: State) =>
  getActionsList(state).filter(item => item.active)

export const getProgramsList = state => state.registration.programs

export const getPlacesList = state => state.registration.places

// export const getActionsId = (state: State): ?Object =>
//   getFormValues(Forms.registrationForm)(state);

// export const getActions = (state: State): ?Object => customFormValueSelector(state, 'akce')

// export const getActionsId = createSelector(
//   [getActions],
//   item => item

//   // (item: ?Object): ?string => item && item.id
// )

// export const getVehicleBrand = (state: State): ?VehicleBrandState =>
//   applicationFormValueSelector(state, "vehicle.car.carBrand");

// export const getVehicleBrandId = createSelector(
//   [getVehicleBrand],
//   (item: ?VehicleBrandState): ?string => item && item.id
// );

//----------------

// export const getActions = (state: State): ?Object =>
//   formValueSelector(state, "akce");

// export const getActionsId = createSelector(
//   [getActions],
//   (item: ?Object): ?string => item
// );

// export const getActionsId = (state: State): ?Object =>
//   formValueSelector(state, "akce");

// export const getVehicleBrandId = createSelector(
//   [getVehicleBrand],
//   (item: ?VehicleBrandState): ?string => item && item.id
// );
