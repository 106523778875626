/* eslint-disable react/jsx-props-no-spreading */
// @flow
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import { ROUTES } from '../../constants'
import message from '../../i18n/messages'
import styles from '../../assets/jss/sar/completedStyle'

import Footer from '../../components/Footer/Footer'
import { SarHeaderImage } from '../../components/SarHeaderImage'

import { Album } from './Album'

import { fetchAlbums, getAlbums, updatePriorityAlbum } from '../../store/photos'

const useStyles = makeStyles(styles)

export const AdminPhotogallery = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const albums = useSelector(getAlbums)

  const handlePriority = (id, increase) => {
    dispatch(updatePriorityAlbum(id, increase))
  }

  useEffect(() => {
    dispatch(fetchAlbums())
  }, [dispatch])

  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <Grid container justify="center">
          <Grid item md={8} xs={11}>
            <Grid item md={12} className={classes.center}>
              <h1>
                <FormattedMessage {...message.photogallery} />
              </h1>
            </Grid>

            <Grid container>
              <Grid item md={4} xs={11} className={`${classes.imgBox}`}>
                <div className={`${classes.imgCrop} ${classes.imgRounded}`}>
                  <Link to={ROUTES.ADMIN_PHOTOALBUM + 0}>
                    <AddCircleOutlineIcon style={{ fontSize: 200 }} />
                  </Link>
                </div>
              </Grid>
              {albums.map(image => (
                <Album
                  url={image.img}
                  title={image.title}
                  id={image.id}
                  increasePriority={() => handlePriority(image.id, true)}
                  decreasePriority={() => handlePriority(image.id, false)}
                  visibility={image.active}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </div>
  )
}
