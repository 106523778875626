// flow
import { defineMessages } from 'react-intl'

export default defineMessages({
  unauthorized: {
    id: 'app.toast.unauthorized',
    defaultMessage: '401 - Unauthorized',
  },
  serviceUnavailable: {
    id: 'app.toast.serviceUnavailable',
    defaultMessage: '503 - Service unavailable',
  },
  noEnvConfig: {
    id: 'app.toast.noEnvConf',
    defaultMessage: "Couldn't find config.json, app couldn't be loaded",
  },
  serverError: {
    id: 'toast.serverError',
    defaultMessage: 'Server error',
  },
})
