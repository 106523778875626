// @flow
import { handleActions } from 'redux-actions'
import { participantActionTypes } from './actions'

import type { ParticipantStateT, setParticipantActionT, setLoadingStatusActionT } from './types'

export const participantsInitialState = (): ParticipantStateT => ({
  list: [
    // {
    //   // name: 'default',
    //   // surname: 'default',
    //   // RC: 'default',
    //   // id: 0,
    //   // action_id: 0,
    //   // type_id: 0,
    // },
  ],
  loadingStatus: null,
})

const reduceFetchParticipantsSuccess = (
  state: ParticipantStateT,
  action: setParticipantActionT
) => {
  const { data } = action.payload
  return {
    ...state,
    list: data,
  }
}

const reduceSetLoadingStatus = (state: ParticipantStateT, action: setLoadingStatusActionT) => {
  return {
    ...state,
    loadingStatus: action.payload,
  }
}

export const participantsReducer = handleActions<ParticipantStateT, setParticipantActionT>(
  {
    [participantActionTypes.FETCH_PARTICIPANTS_SUCCESS]: reduceFetchParticipantsSuccess,
    [participantActionTypes.FETCH_PARTICIPANTS_SUCCESS]: reduceFetchParticipantsSuccess,

    [participantActionTypes.SET_PARTICIPANTS_LOADING_STATUS]: reduceSetLoadingStatus,
  },
  participantsInitialState()
)
