// @flow
import React from 'react'
import { HashLink } from 'react-router-hash-link'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import styles from '../../assets/jss/sar/completedStyle'

import Footer from '../../components/Footer/Footer'
import { SarHeaderImage } from '../../components/SarHeaderImage'

const useStyles = makeStyles(styles)

export const FaQCamp = (props: Props) => {
  const classes = useStyles()

  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <Grid container justify="center">
          <Grid item md={8} xs={11}>
            <Grid item md={12} className={classes.center}>
              <h1>Nejčastější dotazy</h1>
              <img alt="klokan" src={`${process.env.PUBLIC_URL}/imgs/klokanBlack.png`} />
              <h2>Letní tábory</h2>
            </Grid>

            <Grid item md={12}>
              <p>
                <HashLink to="#kapesne">1. Jak vysoké kapesné máme dát dítěti na tábor?</HashLink>
                <br />
                <HashLink to="#klistata">2. Jsou děti sledovány, zda nemají klíšťata?</HashLink>
                <br />
                <HashLink to="#mobil">3. Mohou mít děti na táboře mobil?</HashLink>
                <br />
                <HashLink to="#navstevy">
                  4. Je možné navštěvovat děti během letního tábora?
                </HashLink>
                <br /> <span id="kapesne"> </span>
                <HashLink to="#hlidky">5. Musí se děti účastnit nočních her a hlídek?</HashLink>
                <br />
                <HashLink to="#nemoc">
                  6. Jak postupujete v případě onemocnění či úrazu dítěte?
                </HashLink>
                <br />
                <HashLink to="#poprve">
                  7. Moje dítě je poprvé na táboře. Co mohu udělat, aby to zvládlo co nejlépe?
                </HashLink>
                <br />
                <HashLink to="#oddily">8. Jak jsou děti rozděleny do oddílů?</HashLink>
                <br />
                <HashLink to="#sikana">
                  9. Vyskytuje se na Vašich akcích šikana mezi dětmi?
                </HashLink>
                <br />
                <HashLink to="#ubytovani">
                  10. Je možné ubytovat děti společně podle přání?
                </HashLink>
                <br />
                <HashLink to="#vsi">11. Jak je řešena problematika vší na táboře? </HashLink>
                <br />
              </p>

              <h3>1. Jak vysoké kapesné máme dát dítěti na tábor?</h3>
              <span id="klistata"> </span>

              <p>
                <strong>Námi doporučené kapesné je 200 Kč.</strong>
                <br />
                <strong>Pozn.:</strong>&nbsp;Dítě vlastně žádné kapesné na táboře nepotřebuje,
                protože je plně zabezpečeno jídlem a tekutinami po celou dobu pobytu. Ovšem
                nakládání s kapesným je jednou z dovedností, kterou se dítě postupně učí. Proto je
                potřeba dopředu zvážit, k jakým účelům bude dítě kapesné používat. Zda pouze k
                drobným nákupům sladkostí, nebo drobným dárkům blízkým. Nebo bude v rámci kapesného
                kupovat pohledy a k ním známky atd.
                <br /> <br />
                <strong>doporučujeme:</strong>
                <br />
                &nbsp; - říci dětem, aby si peníze uschovaly v táborovém trezoru
                <br />
                &nbsp; - pokud máte zájem na tom, aby dítě čerpalo kapesné postupně, oznamte nám
                toto a dejte dítěti peníze v odpovídajících hodnotách (např. dvacetikoruny)
              </p>

              <h3>2. Jsou děti sledovány, zda nemají klíšťata?</h3>
              <span id="mobil"> </span>

              <p>
                Zdravotní stav dětí je v průběhu našich akcí celkově sledován.
                <br />
                <br />
                Vždy je přítomen zdravotní dohled. <br />
                <br />V případě klíšťat jsou děti: <br />
                a) poučeny o vzájemném prohlédnutí se před večerkou <br />
                b) prohlíženy v pravidelných intervalech vedoucími a praktikanty <br />
                <br />
                Vyjmutí klíšťat je výhradně v kompetenci zdravotníka. O tomto je udělán zápis do
                zdravotní knihy a dítě si odváží domů písemné upozornění s datem vyjmutí klíštěte a
                místem, kde se nacházelo pro případ pozdějších komplikací. Na skutečnost, že dítěti
                bylo vyjmuto klíště, jsou rodiče upozorněni také při přebírání dětí po ukončení
                akce.
              </p>

              <h3>3. Mohou mít děti na táboře mobil?</h3>
              <span id="navstevy"> </span>

              <p>
                Ano, mohou. Mobilní telefon se stal nedílnou součástí každodenního života. Stal se
                nástrojem komunikace mezi dětmi a rodiči, který umožňuje na jedné straně dítěti
                podávat informace o své činnosti a na straně druhé rodičům kontrolovat a usměrňovat
                činnost dítěte v době, kdy mu nejsou nablízku. <br />U menších a lítostivějších dětí
                je používání mobilních telefonů na táboře nebo podobných akcích vhodné předem v
                rodinném kruhu promyslet. Někdy je totiž velmi těžké určit míru zkreslenosti
                informací, které dítě pomocí mobilního telefonu podává. Toto je samozřejmě těžké i v
                případě obdrženého dopisu či pohledu. Ale zatímco písemná informace dorazí nejvíce
                1x denně, a to ještě bez soboty a neděle, pomocí mobilního telefonu mohou být rodiče
                &quot;bombardováni&quot; informacemi takřka nepřetržitě. V některých případech
                lítostivějších dětí může jít až o citové vydírání, kterému se bez předchozí přípravy
                těžko odolává (viz poprvé bez rodičů). Proto je vhodné postupně připravovat děti na
                to, že v době vzájemného &quot;odloučení&quot; bude mobilní telefon prostředkem ke
                sdělování příjemných a zajímavých věcí, které jsme bez toho druhého právě prožili.{' '}
                <br />
                <br />
                <strong>doporučujeme:</strong>
                <br />
                a) promyslet dopředu, jakým způsobem bude mobilní telefon užíván - např. posílání
                SMS, volání v určitou dobu atd. <br />
                b) pokud předpokládáte (nebo již tyto zkušenosti máte) , že dítě bude mít snahu Vás
                mobilním telefonem přesvědčit o tom, abyste ho vzali domů - dopředu si společně
                říci, jak budete reagovat <br />
                c) ověřovat si informace spadající do kategorie &quot;vše je na táboře špatné&quot;
                u hlavního vedoucího - tel. č. na akci je vždy uvedeno v organizačních informacích a
                na těchto webových stránkách <br />
                d) konzultujte získané informace s hlavním vedoucím - hlavní vedoucí společně s
                vedoucími oddílu jsou na místě akce a dokáží vzniklé situace i problémy efektivně
                řešit <br />
                - dobíjení mobilních telefonů u menších dětí zařídí vedoucí oddílu
                <br />- mobilní telefon si každý může uložit do trezoru u hlavního vedoucího akce
                <br /> <br />
                <strong>Pozn.:</strong>
                Můžeme konstatovat, že mobilní telefon je v průběhu našich akcí velmi pozitivním
                pojítkem dětí s rodinou. Můžeme ovšem také konstatovat, že ne vždy si ví rodič rady
                s informacemi, které mu dítě pomocí mobilního telefonu předá.
              </p>

              <h3>4. Je možné navštěvovat děti během letního tábora? </h3>
              <span id="hlidky"> </span>

              <p>
                Rodiče dítěte mají právo kdykoli navštívit dítě v průběhu akce. Ovšem odpověď na
                otázku, zda dítě navštívit či ne, je problematická. Při zvažování návštěvy je nutné
                vzít do úvahy její vliv na psychiku dítěte. Každé dítě je individualitou, kterou
                znají nejlépe právě rodiče a tudíž je toto rozhodnutí plně na nich.
                <br />
                <br />
                <strong>doporučujeme:</strong>
                <br /> Pokud si ale nejste jistí svým rozhodnutím, doporučujeme nejprve telefonický
                (e-mail, SMS...) kontakt s hlavním vedoucím akce, který po poradě s oddílovým
                vedoucím navrhne vhodný postup. <br /> <br />
                <strong>Pozn.:</strong> V některých případech je vhodnější pravidelný kontakt s
                dítětem pomocí mobilního telefonu (viz Mobil). Při samotné návštěvě rodičů
                (zákonného zástupce) může dojít k dočasnému uvolnění dítěte z akce, a to za těchto
                podmínek:
                <br />
                a) písemně je zaznamenána doba odchodu <br />
                b) písemně je zaznamenána předpokládaná doba příchodu <br />
                c) vše je potvrzeno podpisem rodiče (zákonného zástupce) - dítě rodičům předává
                vedoucí oddílu, případně vedoucí dne, hlavní vedoucí nebo jeho zástupce <br />- dítě
                nesmí být předáno nikomu jinému bez souhlasu rodičů (zákonného zástupce)
              </p>

              <h3>5. Musí se děti účastnit nočních her a hlídek?</h3>
              <span id="nemoc"> </span>

              <p>
                Nemusí. Noční hry a hlídky jsou součástí programu letních táborů i dalších akcí.
                Zprostředkovávají dětem prožitky, které nemohou získat v průběhu svého běžného dne.
                Na noční aktivity jsou děti dopředu připravovány. Jsou motivovány, aby se těchto
                aktivit zúčastnily. Pokud účast z jakéhokoli důvodu odmítnou, je toto odmítnutí cele
                respektováno.
              </p>

              <h3>6. Jak postupujete v případě onemocnění či úrazu dítěte?</h3>
              <span id="poprve"> </span>

              <p>
                Po celou dobu pobytu dětí na táboře je přítomen zdravotník, který sleduje jejich
                zdravotní stav. Onemocnění či úraz konzultujeme s příslušným lékařem nebo
                zdravotnickým zařízením a dále postupujeme dle jejich pokynů. <br />
                <br />
                Příslušné poplatky za recept, návštěvu pohotovosti, klinické ošetření lékařem, léky
                apod. uhradíme. <br /> Poté zkontaktujeme rodiče a: <br />
                a) oznámíme jim změnu zdravotního stavu dítěte <br />
                b) dohodneme způsob úhrady zaplacených poplatků
                <br />
                <br />
                <strong>Pozn.:</strong> Souhlas s úhradou poplatků za ošetření ve zdravotnických
                zařízeních a za recepty dávají zákonní zástupci dítěte v listu účastníka.
              </p>

              <h3>7. Moje dítě je poprvé na táboře. Co mohu udělat, aby to zvládlo co nejlépe?</h3>
              <span id="oddily"> </span>

              <p>
                Část účastníků každé akce se skládá z dětí, které jsou nejenom poprvé s námi, ale
                vůbec poprvé sami, bez rodičů nebo příbuzných mimo domov. Ve většině případů se
                jedná o děti mladšího školního věku. Naši vedoucí jsou připraveni pro práci s dětmi.
                Umí také řešit různé situace vyplývající z prvních samostatných pobytů dětí na
                táboře. <br /> <br />
                <strong>doporučujeme:</strong>
                <br />- vysvětlit dětem, že jejich pobyt na táboře není motivován snahou se jich
                &quot;na nějakou dobu zbavit&quot; nebo tím, že je &quot;nemáte rádi&quot;, ale tím,
                že to k životu patří a že nejsou v podobné situaci sami
                <br />- vybírat tábor a téma tábora společně s dětmi <br />- pozitivně je motivovat
                budováním představ o tom, co zajímavého a pěkného je na táboře čeká. Pomoci s
                vytvořením představy tábora Vám může fotogalerie na těchto stránkách. <br />
                - dohodnout způsob vzájemné komunikace - efektivním a rychlým prostředkem je např.
                mobilní telefon (viz mobil) <br />- pokud to je možné, přihlásit dítě na některou z
                našich kratších akcí, kde si nás bude moci &quot;osahat&quot; <br />- vhodné je
                pravidelné posílání dopisů a pohledů, které si dítě může vystavit, dát pod polštář,
                chlubit se s nimi... <br /> <br />
                <strong>Pozn.:</strong> Některé introvertnější lítostivé děti, které mají mobilní
                telefon, řeší svoje odloučení voláním s rodiči prakticky hned po nástupu na tábor.
                Často způsobem: &quot;je to zde hrozné, přijeďte si pro mne&quot;. Protože se ale
                nesvěří nikomu z dětí ani vedoucích, nemůže být tento stav okamžitě, bez více
                náznaků, odhalen. Je proto vhodné informovat hlavního vedoucího akce, který dokáže
                ve spolupráci s vedoucím oddílu se sdělenými informacemi efektivně pracovat. Včasným
                informováním usnadňujete dětem první samostatný pobyt, který je pro ně zátěžovou
                situací a kterou se teprve učí zvládat.
              </p>

              <h3>8. Jak jsou děti rozděleny do oddílů?</h3>
              <span id="sikana"> </span>

              <p>
                Při rozdělování do oddílů (skupin) zohledňujeme věk účastníků. Bereme do úvahy
                psychický a somatický vývoj, rozdílné vědomosti, dovednosti a potřeby dětí a mládeže
                v jednotlivých věkových kategoriích. Oddíly jsou složené z děvčat a chlapců
                přibližně stejného věku.
                <br /> <br />
                rozdělení podle věku:
                <br /> &nbsp;&nbsp;6 - 7 let
                <br /> &nbsp;&nbsp;8 - 9 let <br />
                &nbsp;&nbsp;10 - 12 let <br />
                &nbsp;&nbsp;13 - 14 let <br />
                &nbsp;&nbsp;15 - 17 let
                <br />
                <br />V případě specifických akcí- kola, lyže..., přihlížíme k úrovni dovedností v
                dané oblasti.
                <br />
                <br />
                <strong>Pozn.:</strong> Důležitým prvkem při rozdělování do oddílů je také přání
                dětí. Tzn., že není vždy nejdůležitější věk, ale psychická pohoda dítěte, kterou
                podporujeme možností být v oddíle s kamarády, sourozenci ap.
              </p>

              <h3>9. Vyskytuje se na Vašich akcích šikana mezi dětmi?</h3>
              <span id="ubytovani"> </span>

              <p>
                Položím nejprve vlastní otázku: Vyskytuje se na Základních školách šikana? Odpověď
                zní, ano. A na naše akce jezdí děti a mládež, které navštěvují naše školy.
                <br />
                <br />
                Problematika šikany je ze strany Studia důsledně zpracována v oblastech:
                <br />
                a) prevence šikany
                <br />
                b) řešení šikany
                <br />
                <br />
                Prevence a řešení šikany v bodech:
                <br />- personál tábora je proškolen v problematice šikany a řeší již náznaky
                chování, které by mohly vyústit v šikanu
                <br />- způsob rozdělení do oddílů (skupin) a promyšlené ubytování účastníků
                preventivně ošetřuje šikanu
                <br />- členění programu akce nedává prostor rozvoji šikany
                <br />- veškerá činnost probíhá pod dohledem vedoucích
                <br />- účastníci akce jsou seznámeni s řádem akce, ve kterém je šikana jmenovitě
                zakázána
                <br />- účastníci akce jsou seznámeni s tím, co je šikana (nedochází tudíž k
                výmluvám na neznalost pojmu šikana)
                <br />- účastníci akce jsou seznámeni s tím, že šikana je hrubým porušením řádu
                akce, čehož následkem je okamžité ukončení účasti
                <br />- rodiče (zákonný zástupce) podepisují v listu účastníka souhlas s ukončením
                účasti na akci následkem hrubého porušení řádu akce
                <br />- řešení šikany je v kompetenci hlavního vedoucího akce
                <br />
                <br />
                <strong>Pozn.:</strong> Ne vždy se dá šikaně zcela vyhnout. Ale prevence šikany na
                našich akcích tento jev potlačuje. Můžeme konstatovat, že pokud již k šikaně došlo,
                zvolené postupy, ve kterých je účinně používáno psychologické působení na viníka,
                zatím vždy zamezily pokračování tohoto jevu.
              </p>

              <h3>10. Je možné ubytovat děti společně podle přání? </h3>
              <span id="vsi"> </span>

              <p>
                Ano, děti je možné ubytovat podle jejich přání. Při ubytovávání jsou nejprve děti
                rozděleny podle věku a pohlaví. Společně jsou tedy ubytováni účastníci ve stejné
                věkové kategorii, chlapci a děvčata zvlášť. Tento způsob ubytování vychází ze
                společných zájmů stejně starých dětí a zároveň částečně preventivně ošetřuje možný
                vznik šikany. Po prvotním rozdělení mají děti možnost požádat o změnu při ubytování
                tak, aby co nejlépe vyhovovalo jejich přání. Společné ubytování kamarádů, příbuzných
                atd. kladně působí na psychiku účastníků akce. <br />
                <br />
                <strong>doporučujeme:</strong> <br />
                Uvést přání společného ubytování s konkrétními dětmi do přihlášky, případně nám toto
                jakýmkoli způsobem oznámit. Znalost těchto informací urychluje a zefektivňuje
                samotný průběh ubytovávání.
              </p>

              <h3>11. Jak je řešena problematika vší na táboře? </h3>

              <p>
                Dítě postižené pedikulosou (zavšivením) je považováno za infekční a nebude přijato k
                pobytu na táboře.
                <br />
                <br />
                <strong>upřesnění:</strong> <br />
                Veš v jakémkoliv stádiu vývoje (hnida, nymfa, dospělý jedinec) je dle závazného
                stanoviska &quot;hygieny&quot; považováno za infekční onemocnění.
                <br />
                <br />
                <strong>z toho vyplývá:</strong>
                <br />- všechny děti při nástupu na letní tábor projdou zdravotním filtrem
                <br />- děti infikované vší jsou izolovány a rodiče jsou v souladu s listem
                účastníka a všeobecnými podmínkami Studia aktivní rekreace vyzváni k okamžitému
                odvozu dítěte z tábora
                <br />
                <br />
                <strong>doporučujeme:</strong> <br />- před nástupem na tábor prohlédněte dětem
                pozorně vlasy (věnujte zvýšenou pozornost místům za ušima, čelní a týlní oblasti) -
                i jediná hnida můž být zdrojem nákazy pro celý tábor
              </p>

              <br />
              <br />
              <br />
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </div>
  )
}
