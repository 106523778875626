import firebase from 'firebase/app'
import 'firebase/auth'
// import { GoogleAuthProvider } from 'firebase/auth'

// TODO hodnoty do config souboru (https://www.youtube.com/watch?v=PKwu15ldZ7k&ab_channel=WebDevSimplified )
const app = firebase.initializeApp({
  // apiKey: "AIzaSyCSLZWg3r7I-vItkiZBppQWvQUCLX8TuCM",
  // authDomain: "terra-58e23.firebaseapp.com",
  // projectId: "terra-58e23",
  // storageBucket: "terra-58e23.appspot.com",
  // messagingSenderId: "928523862503",
  // appId: "1:928523862503:web:93bd5e6d46eb2c9e307d42",
  // measurementId: "G-GBE9G651EV"
  apiKey: 'AIzaSyCY6UVre4CTHNeXzX2FDEGNXzcd8R_HqGI',
  authDomain: 'sar-login-1582926168787.firebaseapp.com',
  databaseURL: 'https://sar-login-1582926168787.firebaseio.com',
  projectId: 'sar-login-1582926168787',
  storageBucket: 'sar-login-1582926168787.appspot.com',
  messagingSenderId: '217069772914',
  appId: '1:217069772914:web:101cbb839f3147d3ec62eb',
  measurementId: 'G-S48NE8XC9D',
})

// export const provider = app.GoogleAuthProvider()
export const provider = new firebase.auth.GoogleAuthProvider()

export const auth = app.auth()
export default app
