// @flow
import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { ROUTES } from '../../../constants'
import styles from '../../../assets/jss/sar/completedStyle'

const useStyles = makeStyles(styles)

type Props = {
  url: string,
  id: number,
  title: string,
  increasePriority: func,
  decreasePriority: func,
  visibility: boolean,
}

export const Album = (props: Props) => {
  const classes = useStyles()

  const { url, id, title, increasePriority, decreasePriority, visibility } = props

  return (
    <Grid item md={4} xs={11} className={`${classes.imgBox}`}>
      <div className={`${classes.imgCrop} ${classes.imgRounded}`}>
        <Link to={ROUTES.ADMIN_PHOTOALBUM + id}>
          <img
            src={url} // zde pouze nahled
            alt="..."
            className={`${classes.imgRaised} ${classes.imgFluid} ${classes.imgZoom} `}
          />
        </Link>
      </div>
      <Grid container className={`${classes.side10Padding} `}>
        <Grid item xs={1}>
          <Button onClick={increasePriority} className={classes.albumButtons}>
            <i className="fas fa-arrow-left" />
          </Button>
        </Grid>
        <Grid item xs={10}>
          <h4
            style={{
              textAlign: 'center',
            }}>
            {title}
            {!visibility && <i className="fas fa-eye-slash" />}
          </h4>
        </Grid>

        <Grid
          item
          xs={1}
          style={{
            justifyContent: 'right',
          }}>
          <Button onClick={decreasePriority} className={classes.albumButtons}>
            <i className="fas fa-arrow-right" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
