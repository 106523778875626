// @flow
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import styles from '../../assets/jss/sar/completedStyle'

import Footer from '../../components/Footer/Footer'

// import { getBeautifyLoadingStatuses } from '../../utils'

import { SarHeaderImage } from '../../components/SarHeaderImage'
import { SarNewsArticle } from '../../components/SarNewsArticle'

// import { fetchDetailNews, getNewsDetail, getNewsDetailLoadingStatus } from '../../store/news'
import { fetchDetailNews, getNewsDetail } from '../../store/news'

const useStyles = makeStyles(styles)

type Props = {
  match: { params: { newsId: number } },
}

export const News = (props: Props) => {
  const { match } = props

  const classes = useStyles()

  const dispatch = useDispatch()

  const newsDetail = useSelector(getNewsDetail)
  // const newsLoadingStatus = useSelector(getNewsDetailLoadingStatus)

  // const newsLoading = getBeautifyLoadingStatuses(newsLoadingStatus)

  useEffect(() => {
    dispatch(fetchDetailNews(match.params.newsId))
  }, [dispatch, match.params.newsId])

  // if (!newsLoading.isSuccess || !newsLoading.isSuccess) {
  //   return null
  // }

  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <SarNewsArticle title={newsDetail.title} detail={newsDetail.detail} />
      </div>

      <Footer />
    </div>
  )
}
