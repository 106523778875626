// @flow
import moment from 'moment'
import type Moment from 'moment'
// import { GenderType } from '../../constants'

/**
 * Rodné číslo je desetimístné číslo
 * Je dělitelné jedenácti beze zbytku
 * První dvojčíslí vyjadřuje poslední dvě číslice roku narození
 * Druhé dvojčíslí vyjadřuje měsíc narození
 * U žen druhé dvojčíslí vyjadřuje měsíc narození zvýšené o 50
 * Třetí dvojčíslí vyjadřuje den narození
 * Čtyřmístná koncovka je rozlišujícím znakem fyzických osob narozených v tomtéž kalendářním dnu o rozsahu přidělovaných koncovek 0000 až 9999
 * V případě, že jsou pro daný kalendářní den v příslušném kalendářním roce vyčerpána veškerá určená rodná čísla,
 * určí ministerstvo pro tento den novou, dodatečnou sestavu rodných čísel, pro niž platí, že rodné číslo je desetimístné číslo, které je dělitelné jedenácti beze zbytku
 * První dvojčíslí vyjadřuje poslední dvě číslice roku narození
 * Druhé dvojčíslí vyjadřuje měsíc narození, u mužů zvýšené o 20, u žen zvýšené o 70
 * Třetí dvojčíslí vyjadřuje den narození
 * Rodná čísla přidělená fyzickým osobám narozeným před 1. lednem 1954 mají stejnou strukturu, jsou však devítimístná s třímístnou koncovkou a nesplňují podmínku dělitelnosti jedenácti
 * Rodné číslo s třímístnou nulovou koncovkou není rodným číslem, neboť pro každý kalendářní den v roce byla generována rodná čísla s třímístnou koncovkou pouze v rozsahu 001 až 999
 * @type {{validate: function(string, string, string): boolean}}
 */
export const BirthNumber = {
  validate: (birthNumber: string, birthdayString: ?string): boolean => {
    const item = clear(birthNumber)
    const birthday = birthdayString ? moment(birthdayString) : null
    return (
      ((tenDigits(item) && dividedByEleven(item)) || nineDigits(item, birthday)) &&
      !!BirthNumber.getBirthday(item) &&
      !!BirthNumber.getGender(item)
    )
  },

  validateBirthday: (birthNumber: string, birthdayString: ?string): boolean => {
    const item = clear(birthNumber)
    const birthday = birthdayString ? moment(birthdayString) : null
    const expectedBirthday = BirthNumber.getBirthday(item)
    return !birthday || !expectedBirthday || birthday.isSame(expectedBirthday)
  },

  validateGender: (birthNumber: string, gender: ?string): boolean => {
    const item = clear(birthNumber)
    const expectedGender = BirthNumber.getGender(item)
    return !gender || !expectedGender || +gender === +expectedGender
  },

  getBirthday: (birthNumber: string): ?Moment => {
    const clearedNumber = clear(birthNumber)
    const month = getMonth(birthNumber)
    const birthday = month
      ? moment(`${getYear(birthNumber)}-${month}-${getDay(birthNumber)}`, 'YY-MM-DD')
      : null
    let result = birthday && birthday.isValid() ? birthday : null
    result = result && moment().isBefore(result) ? result.subtract(100, 'years') : result
    return (tenDigits(clearedNumber) && dividedByEleven(clearedNumber)) ||
      nineDigits(clearedNumber, result)
      ? result
      : null
  },

  // getGender: (birthNumber: string): ?number => {
  //   const clearedNumber = clear(birthNumber)
  //   const item = +birthNumber.slice(2, 4)
  //   let result = null
  //   if ((tenDigits(clearedNumber) && dividedByEleven(clearedNumber)) || nineDigits(clearedNumber)) {
  //     if ((item >= 1 && item <= 12) || (item - 20 >= 1 && item - 20 <= 12)) {
  //       result = GenderType.MAN
  //     } else if ((item - 50 >= 1 && item - 50 <= 12) || (item - 70 >= 1 && item - 70 <= 12)) {
  //       result = GenderType.WOMAN
  //     }
  //   }
  //   return result
  // },
}

const OLD_BIRTH_NUMBERS_LAST_DATE = moment('1954-01-01')

/**
 * Odstraní všechny lomítka a mezery
 * @param birthNumber
 * @returns {string}
 */
const clear = (birthNumber: string): string => birthNumber.replace('/', '').replace(/\s/g, '')

/**
 * Rodné číslo je desetimístné číslo
 * @param birthNumber
 * @returns {boolean}
 */
const tenDigits = (birthNumber: string): boolean => birthNumber.length === 10 && !isNaN(birthNumber)

/**
 * Rodná čísla přidělená fyzickým osobám narozeným před 1. lednem 1954 mají devítimístná čísla
 * Rodná čísla s třímístnou koncovkou pouze v rozsahu 001 až 999
 * @param birthNumber
 * @param birthday
 * @returns {boolean}
 */
const nineDigits = (birthNumber: string, birthday?: ?Moment): boolean => {
  const lastThreeDigits = birthNumber.slice(-3)
  return (
    birthNumber.length === 9 &&
    !isNaN(birthNumber) &&
    +lastThreeDigits >= 1 &&
    (!birthday || birthday.isBefore(OLD_BIRTH_NUMBERS_LAST_DATE))
  )
}

/**
 * Rodné číslo je dělitelné jedenácti beze zbytku
 * @param birthNumber
 * @returns {boolean}
 */
const dividedByEleven = (birthNumber: string): boolean => +birthNumber % 11 === 0

const getDay = (birthNumber: string): number => +birthNumber.slice(4, 6)

const getMonth = (birthNumber: string): ?number => {
  const item = +birthNumber.slice(2, 4)
  let result = null
  if (item >= 1 && item <= 12) {
    result = item
  } else if (item - 20 >= 1 && item - 20 <= 12) {
    result = item - 20
  } else if (item - 50 >= 1 && item - 50 <= 12) {
    result = item - 50
  } else if (item - 70 >= 1 && item - 70 <= 12) {
    result = item - 70
  }
  return result
}

const getYear = (birthNumber: string): number => +birthNumber.slice(0, 2)
