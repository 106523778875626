// @flow

import { error } from 'react-toastify-redux'
import type { Saga } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { putAndWait } from '../../utils/sagas'
import { refreshToken, getAccessToken } from '../../store/authorization'

import { HttpStatusCode } from '../../constants'
// import { Endpoints } from '../../constants/endpoints'
import { toastsMessages } from '../../i18n'

// import { putAndWait } from '../../utils/sagas'
// import { refreshToken } from '../auth/actions'
import { apiSingleton as Api } from './api'

// const isDevEnvironment = process.env.NODE_ENV === 'development'

const errorNotifications = {
  [HttpStatusCode.UNAUTHORIZED]: error(toastsMessages.unauthorized),
  [HttpStatusCode.SERVICE_UNAVAILABLE]: error(toastsMessages.serviceUnavailable),
  other: (error: any) =>
    error(
      error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.reason
        ? `error: ${error.response.data.message}: ${error.response.data.reason}`
        : `${String(error)}`
    ),
}

function* displayErrorNotification(error: Object) {
  if (error.response && error.response.status === HttpStatusCode.UNAUTHORIZED) {
    yield put(errorNotifications[HttpStatusCode.UNAUTHORIZED])
  } else if (error.response && error.response.status === HttpStatusCode.SERVICE_UNAVAILABLE) {
    yield put(errorNotifications[HttpStatusCode.SERVICE_UNAVAILABLE])
  } else {
    yield put(errorNotifications.other(error))
  }
}

export function* restSaga(
  api: () => {},
  endpoint: string,
  params: ?Object,
  payload: ?Object
): Generator<Object, Object, any> {
  let response
  try {
    const accessToken = yield select(getAccessToken)
    const token = accessToken
    // const token = accessToken !== null ? accessToken.token : null
    // console.log('REST 0')

    // console.log(accessToken)

    response = yield call(api, endpoint, token, params, payload)

    // console.log('...')

    // console.log(response)

    // console.log('--..--')

    if (response.data.error === 'unauthorized') {
      const refreshTokenSuccess = yield call(putAndWait, refreshToken)
      if (!refreshTokenSuccess.status) {
        // yield call(putAndWait, networkError)
        // console.log('REST chyba')
      }

      const accessToken = yield select(getAccessToken)

      response = yield call(api, endpoint, accessToken, params, payload)
    }
  } catch (error) {
    // to prevent CORS issues in DEV environment any kind of error (e.g. network error)
    // automatically cause token to refresh

    // console.log(error)
    // console.log('XXXXX')

    // TODO: pro live kontrolovat err unauthorized
    // if (error.response && error.response.status === HttpStatusCode.UNAUTHORIZED) {
    if (true) {
      // yield* putAndWait(refreshToken)
      // response = yield call(restSagaWithoutRefresh, api, endpoint, params, payload)

      // console.log('REST 1')
      // console.log('xx')

      // console.log(error.response.status)

      // console.log('XXaaaXXX')

      const refreshTokenSuccess = yield call(putAndWait, refreshToken)
      if (!refreshTokenSuccess.status) {
        // yield call(putAndWait, networkError)
        // console.log('REST chyba')
      }
      // console.log('XXccccXXX')

      // console.log('REST 2')

      // response = yield call(restSagaWithoutRefresh, api, endpoint, params, payload)

      const accessToken = yield select(getAccessToken)
      // console.log(accessToken)

      // console.log('REST 3')
      // console.log(accessToken)

      response = yield call(api, endpoint, accessToken, params, payload)
      // console.log('XXddddXXX')

      // console.log('REST 4')
    } else {
      yield* displayErrorNotification(error)
      throw error
    }
  }

  return response
}

export function* restSagaWithoutRefresh(
  api: () => {},
  endpoint: string,
  params: ?Object,
  payload: ?Object
): Generator<Object, Object, any> {
  try {
    const accessToken = yield select(getAccessToken)
    const token = accessToken !== null ? accessToken.token : null
    return yield call(api, endpoint, token, params, payload)
  } catch (error) {
    yield* displayErrorNotification(error)
    throw error
  }
}

export function* unauthorizedRestSaga(
  api: () => {},
  endpoint: string,
  params: ?Object,
  payload: ?Object
): Generator<Object, Object, any> {
  try {
    return yield call(api, endpoint, params, payload)
  } catch (error) {
    yield* displayErrorNotification(error)
    throw error
  }
}

// ///////////////////////////////////////////

// // Fixes POST/PUT/PATCH url paths for DEV environment.
// // Endpoints (such as `/login` or `/refresh-token`) that do not require authorization
// // are not fixed due to CORS issue occurring in some browsers.
// const fixEndpoint = (endpoint: string): string =>
//   isDevEnvironment && endpoint !== Endpoints.refreshToken() ? endpoint : endpoint

// export function* unauthorizedPostSaga(endpoint: string, payload: ?Object): Saga<Object> {
//   return yield call(
//     unauthorizedRestSaga,
//     Api.api.unauthorizedPostEndpoint,
//     fixEndpoint(endpoint),
//     payload
//   )
// }

// ///////////////////////////////////////////

const fixEndpoint = (endpoint: string): string => endpoint

export function* unauthorizedPostSaga(endpoint: string, payload: ?Object): Saga<Object> {
  return yield call(restSaga, Api.api.postEndpoint, endpoint, payload)
}

export function* getSaga(endpoint: string, params: ?Object): Saga<Object> {
  return yield call(restSaga, Api.api.getEndpoint, endpoint, params)
}

export function* postSaga(endpoint: string, payload: ?Object): Saga<Object> {
  return yield call(restSaga, Api.api.postEndpoint, fixEndpoint(endpoint), payload)
}

export function* putSaga(endpoint: string, payload: ?Object): Saga<Object> {
  return yield call(restSaga, Api.api.putEndpoint, fixEndpoint(endpoint), payload)
}

export function* patchSaga(endpoint: string, payload: ?Object): Saga<Object> {
  return yield call(restSaga, Api.api.patchEndpoint, fixEndpoint(endpoint), payload)
}

export function* deleteSaga(endpoint: string, params: ?Object, payload: ?Object): Saga<Object> {
  return yield call(restSaga, Api.api.deleteEndpoint, fixEndpoint(endpoint), params, payload)
}

export function* uploadFileSaga(endpoint: string, params: ?Object): Saga<Object> {
  return yield call(restSaga, Api.api.uploadFile, fixEndpoint(endpoint), params)
}

export function* downloadFileSaga(endpoint: string, params: ?Object): Saga<Object> {
  return yield call(restSaga, Api.api.downloadFile, endpoint, params)
}
